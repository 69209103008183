.tag-item {
  height: 30px;
  width: fit-content;
  border-radius: 15px;
  padding: 6px 15px;
  cursor: pointer;
  border: 1px solid transparent;
  // border: 1px solid transparent;
  &.entity {
    margin: 0px 8px 8px 0px;
  }
  &.history {
    margin-bottom: 5px;
    margin-top: 5px;
    &-removed {
      margin-bottom: 5px;
      margin-top: 5px;
      background-color: #ffffff !important;
      border: 1px solid #f2f2f2;
    }
  }
  &.options-tags {
    background-color: #ffffff !important;
    border: 1px solid #1f2531;
    margin-top: 0 !important;
  }
  &.filters-section {
    border: 1px solid #1f2531;
  }
  &-title {
    &.options-tags {
      color: #1f2531;
    }
    &.removed {
      color: #888888;
      text-decoration-line: line-through;
    }
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #ffffff;
  }
}
