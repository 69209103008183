.transaction-info-container {
  @extend %info-container;
}
.transaction-card {
  width: 100%;
  padding: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* White */
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.transaction-info {
  @extend %info-block;
  padding: 20px;

  .no-information {
    display: flex;
    font-weight: 100;
    color: #d6d6d6fc;
  }

  header h2 {
    margin-bottom: 10px;
  }

  &__photo-container {
    max-width: 195px;
    max-height: 195px;
    margin-top: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > img {
      width: 100%;
      height: 100%;
      text-align: left;
    }
    &.icon {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__title {
    font-family: Adobe Clean;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height */
    margin-bottom: 10px;
    color: $text-color;
  }
  &__description {
    font-family: Adobe Clean;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
    color: $text-color;
  }
  &__content {
    .system-id {
      margin-bottom: 15px;
    }

    .supplement-item {
      margin-bottom: 10px;
    }
    .supplement-images {
      display: flex;
      flex-wrap: wrap;
      & > div {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        width: 23%;
        @media screen and (max-width: 1550px) {
          width: 31%;
        }
        @media screen and (max-width: 1375px) {
          width: 46%;
        }
        @media screen and (max-width: 1024px) {
          width: 23%;
        }
        @media screen and (max-width: 940px) {
          width: 30%;
        }
        @media screen and (max-width: 745px) {
          width: 47%;
        }
        @media screen and (max-width: 665px) {
          width: 46%;
        }
        @media screen and (max-width: 610px) {
          width: 46%;
        }
      }
    }

    .supplement-block {
      display: flex;
    }
    .supplement-image {
      min-width: 115px;
      min-height: 115px;
      border: 1px solid #ebeaea;
      box-sizing: border-box;
      flex-shrink: 0;

      //
      width: 100%;
      height: 0;
      overflow: hidden;
      padding-top: 100%;
      position: relative;

      img {
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .supplement-title {
      @include ellipsis-truncate;
      width: 80px;
      margin-top: 5px;
      span {
        @include ellipsis-truncate;
        font-family: Adobe Clean;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        color: $text-color;
      }
    }
    .content-block__notes {
      margin-top: 0;
    }
    .briefcase-icon{
      display: none;
    }
  }
}
.involved-person {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 15px;
  .general-data {
    display: flex;
  }
  &-picture {
    border: 1px solid #f6f6f6;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    width: 60px;
    height: 60px;
    &.person {
      margin-right: 16px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-info {
    display: flex;
    flex-direction: column;
    .person-info {
      &-type {
        font-family: Adobe Clean;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        margin-bottom: 4px;
        line-height: 15px;
        color: #8e8e8e;
      }
      &-name {
        font-family: Adobe Clean;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #1f3560;
      }
      &-enterprise {
        a {
          font-family: Adobe Clean;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: #1f3560;
        }
      }
    }
  }
}
