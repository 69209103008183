.datalist__header {
  padding: 0 20px;
  position: relative;
  z-index: 1;

  @include mq-tablet {
    padding: 0 15px;
  }

  .first-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__heading,
    h1 {
      font-family: $OswaldFont;
      font-size: 24px;
      font-weight: 500;
      text-transform: uppercase;
      color: $text-color;
    }

    .button-add-new {
      @extend .button-sm-light;
      box-shadow: 0px 4px $box-shadow-stretch rgba(0, 0, 0, 0.1);

      @include mq-tablet {
        min-width: 100px;
        padding-top: 7px !important;
        padding-bottom: 6px !important;
      }

      @include mq-phone {
        min-width: 70px;
        padding-top: 5px !important;
        padding-bottom: 4px !important;
      }
    }
  }

  .second-row {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;

    &__search {
      min-width: unset !important;
      max-width: unset !important;
      margin-bottom: 15px;
      flex-grow: 1;
    }

    .toggle-button {
      margin-top: 4px;
      position: relative;
    }

    .close-filters-button {
      @include times-icon;
    }

    @include mq-tablet {
      margin-top: 15px;

      &__search {
        margin-top: 5px;
        margin-right: 0;
      }

      .toggle-button {
        width: 35px;
        height: 35px;
        margin-left: 10px;

        &_map svg {
          width: 18px;
          height: 18px;
        }

        &_filters svg {
          width: 22px;
          height: 20px;
        }
      }
    }

    @include mq-phone {
      margin-top: 0px;

      .toggle-button {
        margin: 9px 0 0 5px;
        width: 30px;
        height: 30px;

        &_map svg {
          width: 16px;
          height: 16px;
        }

        &_filters svg {
          width: 18px;
          height: 16px;
        }
      }
    }
  }

  .search-filters {
    display: flex;
    margin-top: -5px;
    .applied-filters-list {
      display: flex;
      margin-bottom: 16px;
      &__item {
        @include flex-center;
        color: $main-color;
        background-color: $main-color-black;
        cursor: pointer;
        height: fit-content;
        width: fit-content;
        position: relative;
        line-height: 15px;
        border: 1px solid #1b2f34;
        border-radius: 6px;
        margin-right: 5px;
        padding: 6px 10px;
        &:hover {
          background: #1b2f34;
          .applied-filters-list__item-close {
            display: flex;
            opacity: 1;
          }
        }
        &-close {
          opacity: 0;
          display: none;
          transition: 0.3s;
          position: absolute;
          width: 100%;
          height: 100%;
          background: white;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
          border-radius: 5px;
        }
        span {
          font-family: Oswald;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.04em;
          text-transform: uppercase;
        }
        &-line {
          width: 1px;
          height: 16px;
          background-color: rgba(255, 255, 255, 0.22);
          margin: 0 5px;
        }

        &_figure {
          padding: 0 5px;
        }
      }
    }
  }
}
