@import '../../../../assets/scss/vars';

.radio {
  margin-right: 20px;
  margin-bottom: 10px;

  input {
    display: none;

    &:checked + label::before {
      border-color: $label-color;
    }

    &:checked + label::after {
      display: inline-block;
    }

    &:disabled + label::before {
      filter: grayscale(100%);
    }
  }

  label {
    position: relative;
    margin-left: 1px;
    display: flex;
    line-height: 18px;
    font-size: 12px;
    font-weight: 500;
    font-family: $OswaldFont;
    color: $label-color;
    text-transform: none !important;
    user-select: none;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      padding: 1px;
      border-radius: 50%;
      transition: $transition-in;
    }

    &::before {
      display: inline-block;
      width: 13px;
      height: 13px;
      /*margin-right: 5px;*/
      margin-right: 4px;
      border: 1px solid rgba(27, 47, 52, 0.1);
    }

    &::after {
      display: none;
      position: absolute;
      top: 2px;
      left: 2px;
      width: 11px;
      height: 11px;
      background-color: $label-color;
    }

    &:hover::before {
      border-color: $label-color;
    }
  }
}

.radio_dark {
  @extend .radio;

  input {
    &:checked + label::before {
      border-color: $main-color;
    }

    &:checked + label::after {
      background-color: $main-color;
    }
  }

  label {
    color: $main-color;
    margin-left: 1px;
    &::before {
      border-color: rgba(238, 240, 240, 0.1);
    }

    &:hover::before {
      border-color: $main-color;
    }
  }
}
