.checkbox-container {
   display: flex;
   align-items: top;
   margin-bottom: 20px;
}

.checkbox,
.rdw-link-modal-target-option input[type="checkbox"] {
   -webkit-appearance: none;
   appearance: none;

	display: block;
   padding: 7px;
   margin-left: 0;
   width: 15px;
   height: 15px;

   outline: none;
   border: 1px solid rgba(27, 47, 52, 0.1);
   border-radius: 1px;
   filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.07));
   cursor: pointer;

   &:hover {
      border-color: #768285;
      filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.07));
      transition: $transition-in;
   }

   &:active {
      border-color: rgba(209, 213, 214, 0.3);
   }

   &:checked + .checkbox-label-base::before,
   &:checked + .checkbox-label-base::after {
      display: block;
   }

   &:disabled {
      border-color: rgba(209, 213, 214, 0.3);
      filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.07));

      & + .checkbox-label {
         color: $disabled-color;
      }
   }
}

.checkbox-dark {
   @extend .checkbox;

   border-color: rgba(#FFF, 0.3);

   &:hover {
      border-color: #FFF;
   }

   &:active {
      border-color: rgba(#FFF, 0.3);
   }

   & + .checkbox-label-base::before,
   & + .checkbox-label-base::after {
      background-color: #FFF;
   }
}

.checkbox-label-base {
   position: relative;
   margin-left: 5px;
   font-size: 12px;
   font-weight: 500;
   font-family: $OswaldFont;
   color: $label-color;
   cursor: pointer;

   &::before,
   &::after {
      display: none;
      content: '';
      position: absolute;
      background-color: $label-color;
   }

   &::before {
      top: 9px;
      left: -19px;
      height: 2px;
      width: 6px;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      transform: rotate(45deg);
   }

   &::after {
      height: 2px;
      width: 10px;
      top: 7px;
      left: -16px;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      transform: rotate(-45deg);
   }
}

.checkbox-label {
   @extend .checkbox-label-base;

   line-height: 16px;
   display: block;
   text-transform: capitalize;
}

.checkbox-label-dark {
   @extend .checkbox-label;

   font-size: 14px;
   color: #FFF;
}