.tag-category {
  margin-bottom: 10px;
  &.title {
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #627d7a;
  }
}
