.google-map_lease-floors {
   .gm-style-iw {
      box-shadow: none;
      background-color: $lease-visualization-bg;
      border-radius: 1px;

      &-d {
         margin: 0 -18px -20px 0
      }

      &-t::after {
         box-shadow: none;
         background: none;
         background-color: $lease-visualization-bg;
      }

      &.gm-style-iw-c {
         padding: 0;
         height: 100%;
         height: max-content;
         min-height: 20px;
      }
   }
}

.leases-info-window {
   width: 210px;
   width: max-content;
   padding: 0 5px;
   font-family: $OswaldFont;
   font-size: 14px;
   line-height: 21px;
   text-transform: uppercase;

   .transaction-info {
      font-family: inherit;
      background-color: $lease-visualization-bg;
      padding: 5px 0 12px 0;
      border-bottom: 1px solid $main-color;
      border-radius: 0;

      &:last-child {
         border-bottom: none;
      }

      &:not(:last-child) {
         padding-bottom: 5px
      }

      &__data-field {
         font-family: inherit;

         span {
            font-family: inherit;
            &:first-child {
               color: rgba($main-color, 0.5)
            }
            &:last-child {
               color: $main-color
            }

            &.listing-status {
               &_active {
                  color: $listing-status_active
               }
               &_pending {
                  color: $listing-status_pending
               }
               &_leased {
                  color: $listing-status_leased
               }
               &_canceled, &_cancelled {
                  color: $listing-status_canceled
               }
               &_expired {
                  color: $listing-status_expired
               }
            }
         }
      }
   }
}