* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: $AdobeCleanFont;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

html {
  padding: constant(safe-area-inset-top) constant(safe-area-inset-right)
    constant(safe-area-inset-bottom) constant(safe-area-inset-left);
}

body > iframe {
  display: none!important;
}

html,
body {
  overflow: hidden;
  margin: 0;
}

body > iframe {
  display: none !important;
}

body {
  height: 100%;
}

ul {
  list-style: none;
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;

  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);

  clip: rect(0 0 0 0);
  overflow: hidden;
}

.vertical-center-phone {
  @include mq-phone {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.page-scrollbar {
  background: $scrollbar-color;
  border-radius: 1px;
  z-index: 1;
  cursor: pointer;
}

.page-scrollbar-x {
  @extend .page-scrollbar;
  height: 4px !important;
  display: none;
}

.page-scrollbar-y {
  @extend .page-scrollbar;
  width: 4px !important;
  bottom: 0;
  left: 4px;
}

/* disable input[type=number] arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.scroll-area {
  display: flex;
  flex-flow: column;
  text-align: center;
  // justify-content: center;
  padding: 20px;
  overflow-y: auto;
  height: inherit;
  position: relative;
  z-index: 2;

  @include mq-phone {
    height: 100%;
  }
}

p.description,
div.description {
  white-space: pre-wrap;
}

input {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgba($main-color-black, 0.1);
  font-size: 16px;
  line-height: 20px;
  color: $text-color;

  &:hover,
  &:focus {
    border-color: $main-color-black;
    transition: $transition-in;
  }
}

button {
  border-radius: 2px;
  border: 1px solid $main-color-black;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  &:hover,
  &:active {
    transition: $transition-in;
  }
}

textarea {
  @include scrollbar-y;
}

.options {
  display: flex;
  flex-direction: row;
  & > div {
    &:first-child {
      margin-top: -3px;
    }
  }
  .cross-icon {
    width: 24px;
    height: 24px;
    display: flex;
    cursor: pointer;
    transition: 0.3s;
    margin-left: 10px;
    border-radius: 2px;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    border: 1px solid #dcdedf;
    &:hover {
      border: 1px solid $label-color;
    }
  }
}

.loader-special {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-default {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(221, 221, 221);
  border-radius: 3px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(194, 194, 194);
}
.ReactVirtualized__Grid__innerScrollContainer {
  margin-right: 15px;
}

/* No items are specified */
.no {
  &-items-specified {
    margin: 16px;
    display: flex;
    font-family: Adobe Clean;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #8e8e8e;
  }
  &-cards-specified {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: Adobe Clean;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
    color: #8e8e8e;
  }
}
