.sales-tab {
   &__transactions,
   .transactions {
      display: flex;
      flex-direction: column;

      width: 70%;
      max-width: 365px;
      margin-right: 15px;

      @include mq-phone {
         min-width: 100%;
         min-height: 100%;
         padding-left: 15px;
         padding-right: 15px;
      }
   }

   .transactions__top-controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
   }

   @include mq-tablet {
      min-width: 285px;
      max-width: 285px;

      .transactions-items-container {
         & > div {
            padding-right: 15px !important;
         }
      }

      &__transactions,
      .transactions {
         width: 65%;
         margin-right: 15px;
      }
   }

   @include mq-phone {
      margin-right: 0 auto;
   }
}