.reminder {
  border-top: 1px solid rgba(31, 31, 31, 0.1);
  &__wrapper{

  }
  &.history {
    border-top: none;
    margin-top: 5px;
    .reminder-container {
      display: flex;
      justify-content: space-between;
      padding: 0;
      .check-button{
        opacity: 1;
        cursor: default;
      }
      .reminder-information {
        width: 320px;
        transition: 0.3s;
        &.completed {
          opacity: 0.5;
        }
      }
    }
  }
  &.selected {
    background: $main-color;
  }
  .view-more {
    width: 100%;
    position: absolute;
    display: flex;
    margin-top: -15px;
    align-items: center;
    justify-content: center;
    span {
      cursor: pointer;
      svg {
        height: 15px;
      }
    }
  }
  &-container {
    padding: 15px;
    display: flex;
    .check-button {
      margin-right: 15px;
      border-radius: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      border: 1px solid $main-color-black;
      box-sizing: border-box;
      background: $main-color;
      transition: 0.3s;
      flex-shrink: 0;
      &.done {
        background: #1f2531;
        border: 1px solid #1f2531;
      }
      &.not-done {
        background: $main-color;
      }
      &:hover {
        opacity: 0.5;
      }
    }

    .reminder-information {
      width: 358px;
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      transition: 0.3s;
      &.completed {
        opacity: 0.5;
      }
    }
  }
}
