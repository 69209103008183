.lease-transactions-map {
   min-height: 420px;
   background-color: $main-color;

   &__floors-filter {
      padding: 10px 10px 0px 10px;

      hr {
         border: none;
         border-top: 1px solid #F3F3F3;
         margin-top: 15px;
      }
   }

   &__legend {
      margin-top: 15px;
      padding: 0 10px;
   }
}