@import './category';
@import './window';
@import './tag';

.tags {
  width: 100%;
  display: flex;
  padding: 20px;
  border-radius: 3px;
  background: #ffffff;
  flex-direction: column;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  &-title {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    color: $text-color;
    margin-top: 4px;
    margin-bottom: 15px;
    &-icon {
      cursor: pointer;
      margin-left: 10px;
    }
  }
  &-container {
    display: flex;
    flex-wrap: wrap;
    .no-elements {
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #aeb4be;
    }
    &_item {
      &.two-row {
        flex: 50%;
      }
      &.one-row {
        flex: 100%;
      }
      margin-bottom: 12px;
    }
    &_tag {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
