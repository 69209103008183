%button-base {
  text-transform: uppercase;
  border-radius: 2px;
  cursor: pointer;
  outline: none;

  transition: 0.3s;

  &:active {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.button-primary {
  @extend %button-base;

  font-size: 14px;
  font-weight: 700;
  min-width: 71px;
  max-width: max-content;
  line-height: 18px;
  padding: 5px;
}

.button-light {
  @extend .button-primary;

  border: 1px solid $main-color-black;
  color: $main-color-black;
  background-color: $main-color;

  &:hover {
    background-color: $button-light-hover-color;
  }

  &:active {
    background-color: $button-light-active-color;
  }

  &:disabled, &.disabled {
    color: $button-dark-disabled-color;
    border-color: $button-dark-disabled-color;
    background-color: $button-light-disabled-color;
  }

  span {
    color: $main-color-black;
  }
}

.button-dark {
  @extend .button-primary;

  border: none;
  color: $main-color;
  background-color: $button-dark-default-color;

  &:hover {
    background-color: $button-dark-hover-color;
  }

  &:active {
    background-color: $button-dark-active-color;
  }

  &:disabled, &.disabled {
    color: rgba($main-color, 0.8);
    background-color: $button-dark-disabled-color;
  }

  span {
    color: $main-color;
  }
}

.button-toggle {
  @extend %button-base;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 2px;

  &:hover {
    border-color: $label-color;
  }

  &:active {
    background: rgba(98, 125, 122, 0.1);
  }

  &:disabled, &.disabled {
    background-color: $main-color;
    border-color: $disabled-color;
  }

  span {
    @include flex-center;
  }
}

.button-toggle-sm {
  @extend %button-base;
  width: 29px !important;
  height: 16px !important;
  margin-left: 5px;
  margin-top: -4px;
  border: none;

  span {
    @include flex-center;
  }
}

.button-toggle-light {
  @extend .button-toggle;

  background-color: $main-color;
  border: 1px solid $disabled-color;
}

.button-toggle-dark {
  @extend .button-toggle;

  background-color: $main-color-black;
  border: none;

  background-color: $button-dark-default-color;

  &:hover {
    background-color: $button-dark-hover-color;
  }

  &:active {
    background-color: $button-dark-active-color;
  }

  &:active {
    background-color: $main-color-black;
  }

  span {
    position: relative;
    bottom: 1px;
    right: 1px;
  }
}

.button-sm {
  @extend %button-base;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  padding: 4px 8px;
  margin-left: 15px;
  border: none;
}

.button-sm-light {
  @extend .button-sm;

  border: 1px solid transparent;
  color: $main-color-black;
  background-color: $main-color;

  &:hover {
    border-color: $button-light-active-color;
    background-color: $main-color;
  }

  &:active {
    background-color: $button-light-hover-color;
  }

  &:disabled, &.disabled {
    color: $button-dark-disabled-color;
    background-color: $button-light-disabled-color;
    border-color: transparent;
  }
}

.button-sm-dark {
  @extend .button-sm;

  border: none;
  color: $main-color;
  background-color: $button-dark-default-color;

  span {
    color: $main-color;
  }

  &:hover {
    background-color: $button-dark-hover-color;
  }

  &:active {
    background-color: $button-dark-active-color;
  }

  &:disabled, &.disabled {
    color: rgba($main-color, 0.8);
    background-color: $button-dark-disabled-color;
  }
}

.save-search-button {
  @extend .button-sm-light;

  margin: 0;
  border: 1px solid $disabled-color;

  svg {
    margin-left: 5px;
    position: relative;
    top: 1px;
  }

  &:hover,
  &:active {
    svg path {
      fill: rgba(27, 47, 52, 0.6);
    }
  }

  &:disabled, &.disabled {
    svg path {
      fill: rgba(209, 213, 214, 0.6);
    }
  }
}
