@import "./map-controls";
@import "./info-window/properties-map";
@import "./info-window/leases-map";

@include mq-desktop {
   .gm-ui-hover-effect {
      display: none !important;
      position: absolute !important;
      z-index: -1 !important;
   }
}

.property-location-map {
   margin-bottom: 20px;

   &__tip {
      padding-top: 2px;
      color: $main-color-black;
      font-size: 10px;
   
      span {
         color: $error-color;
      }

      &_error {
         color: $error-color;
      }
   }
}

.google-map_property {
   width: 280px;
   height: 280px;

   @include mq-phone {
      width: 100%;
   }
}

.google-map_lease {
   width: 100%;
   height: 300px;
   margin-top: -20px;
}

.google-map_lease-floors {
   width: 100%;
   height: 100%;
   margin-top: -20px;
}