@import '../shared/styles/control.module';

.listing_status {
  display: flex;
  height: 22px;
  margin-bottom: 5px;
  max-width: 280px;

  @media screen and (max-width: $phone-screen) {
    max-width: 100%;
  }

  & > div:first-child {
    width: 50%;
  }

  & > div:last-child {
    padding-left: 10px;
    width: 50%;
  }
}

.listing_error {
  font-family: Adobe Clean;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.03em;
  display: block;
  color: #f10707;
  width: 280px;
}

.listing_status_form {
  position: absolute;
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  top: 135px;
  img {
    width: 100%;
    height: 100%;
    display: flex;
  }
}

.status_type {
  display: flex;
  justify-content: space-between;
  color: $main-color;
  background-color: #1f2531;
}

.status_type_label {
  line-height: 20px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  color: $main-color;
}

.remove_selection_icon {
  @include times-icon(10px, 1px);
  padding-right: 12px;
}
