.gallery-previews {
  display: flex;
  width: max-content;
  margin-bottom: 15px;

  &__image-container {
    max-width: 202px;
    max-height: 130px;
    width: 100%;
    height: 100%;

    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      &:hover {
        cursor: pointer;
      }
      &:active {
        cursor: grabbing;
      }
    }
  }

  &__swipe-control {
    width: 35px;
    height: 130px;
    background: rgba(27, 47, 52, 0.6);
    position: absolute;
    z-index: 1;
    top: 0;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      width: 22px;
      height: 2px;
      background-color: $main-color;
      position: absolute;
      top: 50%;
      left: 7px;
      z-index: 2;
    }

    &::before {
      top: calc(50% - 8px);
    }
    &::after {
      top: calc(50% + 7px);
    }

    &_forwards {
      right: 0;
    }
    &_backwards::before,
    &_forwards::after {
      transform: rotate(-45deg);
    }
    &_backwards::after,
    &_forwards::before {
      transform: rotate(45deg);
    }
  }
}

.gallery-fullscreen {
  @include flex-center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 600px;
  max-height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;

  &__close {
    @include times-icon(20px);
    padding: 18px;
    position: absolute !important;
    z-index: 20;
    top: 10px;
    right: 10px;
  }
  &__image-full {
    display: flex;
    width: 100%;
    position: relative;
    z-index: 4;
  }

  .image-full__toggle,
  .swiper-button {
    &-next {
      right: 0;
      left: initial;
    }

    &-prev,
    &-next {
      z-index: 5;
      width: 24%;
      height: 100%;
      cursor: pointer;
      position: absolute;

      &::before,
      &::after {
        content: '';
        width: 27px;
        height: 4px;
        background-color: $main-color;
        position: absolute;
      }

      &::before {
        top: calc(50% - 8px);
        left: calc(50% - 12px);
      }

      &::after {
        top: calc(50% + 8px);
        left: calc(50% - 12px);
      }
    }

    &-prev::after,
    &-next::before {
      transform: rotate(45deg);
    }

    &-prev::before,
    &-next::after {
      transform: rotate(-45deg);
    }

    &-disabled {
      &::before,
      &::after {
        background-color: #434445;
      }
    }
  }

  &__thumbnail {
    position: relative;
    width: fit-content;
    height: 87px;
    margin: 30px 20px 30px 0;
    img {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__overlay {
    @include backdrop-overlay;
    z-index: 0;
  }
}

.swiper {
  &-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }

  &-slide {
    width: auto;
    height: auto;
  }

  &-wrapper {
    max-height: 500px;
  }
}

.image {
  &-full {
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__img {
      display: block;
      width: 65%;
      height: 100%;
      max-height: 480px;

      @include mq-desktop {
        width: unset;
      }
    }
  }
}
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
  pointer-events: all;
}