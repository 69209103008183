@import "./vars";
@import "./mixins";

.rdw {
   &-inline-wrapper,
   &-list-wrapper,
   &-link-wrapper,
   &-image-wrapper {
      margin-bottom: 0
   }

   &-link-modal-input{
      margin-top: 2px;
      margin-bottom: 15px;
   }
   &-link-modal-target-option{
      margin-bottom: -23px;
   }

   &-editor-main{
      background: #fff;
   }
   &-option-wrapper {
      @include editor-control-base;
      border: none;
      margin: 0 0 0 2px;
      background-repeat: no-repeat;
      background-position: center;

      img {
         display: none;
      }

      &[title="Bold"] {
         background-image: url(#{$icons}/bold.svg)
      }

      &[title="Italic"] {
         background-image: url(#{$icons}/italic.svg)
      }

      &[title="Underline"] {
         background-image: url(#{$icons}/underline.svg)
      }

      &[title="Strikethrough"] {
         background-image: url(#{$icons}/strikethrough.svg)
      }

      &[title="Unordered"] {
         background-image: url(#{$icons}/unordered-list.svg)
      }

      &[title="Ordered"] {
         background-image: url(#{$icons}/ordered-list.svg)
      }

      &[title="Link"] {
         background-image: url(#{$icons}/link.svg);
         background-position-x: 1px;
      }

      &[title="Image"] {
         background-image: url(#{$icons}/attachment.svg);
         background-position-y: 1px;
         background-position-x: 1px;
      }
   }

   &-option-active {
      @include flex-center;
      box-shadow: none;

      background-color: $note-link-color;

      &[title="Bold"] {
         background-image: url(#{$icons}/bold_active.svg)
      }

      &[title="Italic"] {
         background-image: url(#{$icons}/italic_active.svg)
      }

      &[title="Underline"] {
         background-image: url(#{$icons}/underline_active.svg)
      }

      &[title="Strikethrough"] {
         background-image: url(#{$icons}/strikethrough_active.svg)
      }

      &[title="Unordered"] {
         background-image: url(#{$icons}/unordered-list_active.svg)
      }

      &[title="Ordered"] {
         background-image: url(#{$icons}/ordered-list_active.svg)
      }
   }

   &-suggestion-dropdown {
      height: 300px;
   }

   &-link-modal,
   &-image-modal {
      width: 330px;
      height: unset;
      top: -252px;
      right: 0;
      transform: translateX(50%);
      left: initial;
      padding: 20px 25px;

      &-label {
         @include label-base;
      }

      &-input {
         @include input-base;
      }

      &-target-option {
         display: flex;

         input[type="checkbox"] {
            display: inline-block;
         }

         span {
            @extend .checkbox-label-base;
         }
      }

      &-btn {
         margin-left: 15px;
         margin-right: 0;
         border: 1px solid $main-color-black;
         border-radius: 2px;
         text-transform: uppercase;
         font-size: 14px;
         line-height: 18px;

         &:hover {
            box-shadow: none;
         }

         &:first-child {
            color: $main-color;
            background-color: $main-color-black;

            &:hover {
               background-color: $button-dark-hover-color;
            }

            &:active {
               background-color: $button-dark-active-color;
            }
         }

         &:last-child {
            &:hover {
               background-color: $button-light-hover-color;
            }

            &:active {
               background-color: $button-light-active-color;
            }
         }
      }

      &-buttonsection {
         margin-left: auto;
         margin-right: unset;
         display: flex;
         flex-direction: row-reverse;
      }
   }

   &-link-modal{
      bottom: 40px;
      left: 20px;
      right: 20px;
      top: initial;
      position: fixed;
      width: calc(100% - 40px);
      transform: none;
      padding: 10px 25px;
      .rdw-link-modal-input, .rdw-image-modal-input{
         margin-top: 3px;
         margin-bottom: 15px;
      }
   }

   &-image-modal {
      top: -170px;

      &-header {
         margin: 0;

         & > span {
            @include label-base;
            width: unset;

            span {
               display: none;
            }
         }
      }

      &-url-section,
      &-size {
         align-items: baseline;
         margin: 0;

         input {
            @include input-base;
            margin: 0 0 20px 0;
            width: 100%;
            height: unset;
         }
      }

      &-btn-section {
         margin: 0 0 auto;
         display: flex;
         flex-direction: row-reverse;
      }
   }

   &-mention-link,
   &-link-decorator-wrapper a span span {
      display: inline-block;
      padding: 0 4px;
      height: 20px;
      line-height: 22px;
      color: $note-link-color;
      background-color: rgba($note-link-color, 0.08);
      border-radius: 2px;
   }
   &-link-decorator-wrapper{
      .rdw-link-decorator-icon{
         left: 100%;
         z-index: 3;
      }
   }
}
