$filters-width: 410px;

.filters {
  position: absolute;
  z-index: 3;
  left: 35%;
  left: calc(#{$data-list-width} + 90px);
  width: $filters-width;
  height: 100%;
  background-color: $main-color-black;

  &_full-screen {
    position: static;
  }

  .filters-open-arrow {
    position: absolute;
    top: 57px;
    left: -9px;
  }

  &__button-close {
    @include times-icon;

    display: none !important;
    padding: 15px;
    margin-top: -10px;
    margin-right: -5px;

    cursor: pointer;
  }

  &__scrollbars {
    height: 90% !important;

    .page-scrollbar-y {
      left: 4px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .filters__tabs {
      display: flex;
      padding-bottom: 15px;
      text-transform: uppercase;
      color: $main-color;
    }

    .filters__tab {
      padding-right: 15px;
      font-family: $OswaldFont;
      font-size: 12px;
      font-weight: 500;
      color: $main-color;
      cursor: pointer;

      &:not(:first-child) {
        padding-left: 15px;
      }

      &:hover,
      &_active {
        color: rgba($main-color, 0.2);
        transition: $transition-in;
      }
    }

    .saved-searches-tab {
      border-left: 1px solid $main-color;
    }

    border-bottom: 1px solid $main-color;
  }

  &__content {
    padding: 10px 15px;
    width: inherit;
    height: 100%;
  }

  &__controls-container {
    position: relative;
    display: flex;
    align-items: flex-end;

    & > div:first-child {
      padding-right: 5px;
      width: 50%;
    }

    & > div:last-child {
      padding-left: 5px;
      width: 50%;
    }
  }

  &__controls-container_with-separator {
    @extend .filters__controls-container;
    &.dot {
      color: $main-color;
      padding-left: 1px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: 0;
    padding: 0 15px;
    width: $filters-width;
    height: 40px;

    background-color: $main-color;

    .filters__buttons {
      display: flex;
    }
  }

  @media screen and (max-width: 940px) {
    left: $data-list-min-width;
  }

  @media screen and (min-width: 1750px) {
    left: 655px;
  }

  @include mq-tablet {
    left: 0;
    top: 0;
    width: 100%;
    margin-top: 0;

    &__button-close {
      display: flex !important;
    }

    &__footer {
      width: 100%;
    }

    &_full-screen {
      width: $filters-width;

      .filters__footer {
        width: $filters-width;
      }
    }
  }

  @include mq-tablet-sm {
    &_full-screen {
      width: 100%;

      .filters__footer {
        width: 50%;
      }
    }
  }

  @include mq-phone {
    &_full-screen {
      height: 50%;

      .filters__footer {
        width: 100%;
      }
    }
  }
}
