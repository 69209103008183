.tag-creator__input-field{
  padding: 0 15px;
  font-size: 14px;
  .public-DraftStyleDefault-block{
    margin: 10px 0;
  }
}
.tag-editor{
  background-color: $main-color;
  &.error{
    background: $error-background;
  }
}
