@import './button';
@import './window';
@import './note-card';
@import './history-card';
@import './reminder-card';
@import './history-modal';
@import './preview-modal';

.windows {
  display: flex;
  position: absolute;
  z-index: 20;
  right: 0;
  bottom: 0;
  height: 100%;
  width: fit-content;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  // Two and more modals are open
  &.two-modals {
    @include mq-desktop-md {
      // Small display functionality
      width: 100%;
    }
  }
  &.three-modals {
    @include mq-desktop-lg {
      // Small display functionality
      width: 100%;
    }
  }
  .general-windows {
    height: 100%;
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
    flex-direction: row;
    align-items: flex-end;
    // One and more modals are open
    &.one-modal {
      @include mq-desktop-md {
        // Small display functionality
        overflow-x: auto;
        .window preview {
          position: relative;
        }
      }
    }
    &.two-modals {
      @include mq-desktop-md {
        // Small display functionality
        overflow-x: auto;
        .window preview {
          position: relative;
        }
      }
    }
    &.three-modals {
      @include mq-desktop-lg {
        // Small display functionality
        overflow-x: auto;
        .window preview {
          position: relative;
        }
      }
    }
  }
  &.fixed-height {
    @media screen and (max-width: 1025px) {
      bottom: 50px;
    }
    height: fit-content;
  }
}

.notes-buttons {
  display: flex;
  flex-direction: column;
  &.window-open {
    margin-right: -10px;
  }
  @media screen and (max-width: 1025px) {
    flex-direction: row;
  }
}

.public-DraftStyleDefault-ltr {
  margin: 0;
}
