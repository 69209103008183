@import "./lease-transactions-map";
@import "./stack-view";

.leases-tab {
   @extend .sales-tab;

   &__transactions-column {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      height: 100%;
      align-items: center;
      .page-scrollbar-y {
         left: 7px !important;
      }
   }

   .transactions__top-controls {
      position: relative;
      z-index: 2;

      & > div {
         @include flex-center;
      }
   }

   .toggle-button_sm {
      width: 25px;
      height: 25px;
      margin-left: 0;
      margin-right: 5px;
      border-color: transparent;

      &:hover,
      &:active {
         border-color: $label-color;
      }
   }

   .button-add-new {
      margin-left: 0;
   }
}