.person-info-container {
   @extend %info-container;
}

#person-info-scrollbars {
  margin: 0 -25px;

  & > div {
    padding: 0 $box-shadow-stretch;
  }

  .page-scrollbar-y {
    left: 7px;
  }

  @include mq-phone {
    width: unset !important;

    .page-scrollbar-y {
      left: -7px;
    }
  }
}

.person-info {
  @extend %content-block;
  min-width: 320px;
  width: 100%;
  padding: 20px;
  margin-bottom: 10px;

  header {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    display: flex;

    .photo {
      margin-right: 20px;

      img {
        max-width: 95px;
        max-height: 95px;
        object-fit: scale-down;
      }

      .briefcase-icon {
        width: 95px;
        height: 95px;
      }
    }

    .content__data-fields {
      width: 100%;
    }

    .no-photo-initials {
      @include flex-center;

      width: 95px;
      height: 95px;
      color: $main-color-black;
      border: 1px solid #eaeef3;
      font-size: 40px;
      font-weight: 700;
      text-align: center;
    }

    .data-field {
      &__key {
        @include mq-phone-sm {
          min-width: 95px;
        }
      }
    }
    .description {
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;

      color: $text-color;
      &.none {
        color: grey;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &__content {
      flex-direction: column;

      .photo {
        margin-bottom: 20px;
      }
    }
  }
}
.involvement-info {
  @extend %content-block;

  min-width: 320px;
  width: 100%;
  padding: 20px;
  margin-bottom: 25px;

  header {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    display: flex;

    .photo {
      margin-right: 20px;

      img {
        max-width: 95px;
        max-height: 95px;
        object-fit: scale-down;
      }

      .briefcase-icon {
        width: 95px;
        height: 95px;
      }
    }

    .content__data-fields {
      width: 100%;
    }

    .no-photo-initials {
      @include flex-center;

      width: 95px;
      height: 95px;
      color: $main-color-black;
      border: 1px solid #eaeef3;
      font-size: 40px;
      font-weight: 700;
      text-align: center;
    }

    .data-field {
      &__key {
        @include mq-phone-sm {
          min-width: 95px;
        }
      }
    }
    .description {
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;

      color: $text-color;
      &.none {
        color: grey;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &__content {
      flex-direction: column;

      .photo {
        margin-bottom: 20px;
      }
    }
  }
}

.row {
  &-data {

    &-container {
      display: flex;
      flex-flow: row;
      width: 100%;
      min-width: 320px;
      height: 40px;
      padding-bottom: 20px;
      padding-top: 10px;
      border-bottom: 1px solid $border-color;

      &__left {
        width: 100%;
        min-width: 130px;
        max-width: 130px;
        margin-right: 10px;
        text-transform: uppercase;
        color: $label-color;
        font-family: "Oswald", sans-serif;
        font-size: 14px;
        line-height: 21px;
        font-weight: normal;
      }

      &__right {
        width: 100%;
      }
    }
  }
}
