.tab{
  .loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 20;
    justify-content: center;
    align-items: center;
    background-color: $main-color-black;
    .loader {
      width: 24px;
      height: 24px;
    }
  }
}
