$block-width: 70px;
$block-height: 70px;
$block-vertical-space: 8px;
$block-horizontal-space: 10px;

.ownership {
  &-tab {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
  &-current-property {
    .ownership-item {
      position: absolute;
      background: #8aa9b0;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      cursor: default;
      .ownership-title {
        font-family: Oswald, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        color: $main-color-black;
        color: white;
      }
    }
    &:first-child {
      .ownership-item {
        &:before {
          content: none;
        }
      }
    }
    .ownership-item {
      cursor: default;
    }
  }

  &__ &-structure {
    overflow: auto;
    max-width: 100%;
    .dynamic-area {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &-item {
    width: $block-width;
    height: $block-height;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    margin: $block-vertical-space $block-horizontal-space;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid #fff;
    position: relative;
    transition: 0.3s;
    border-radius: 3px;
    font-size: 10px;
    cursor: pointer;
    &__image {
      width: 68px;
      height: 68px;
      flex-shrink: 0;
      background-color: $main-color;
      margin: 5px;
      position: relative;
      img {
        border-radius: 3px 0px 0px 3px;
        width: 100%;
        height: 100%;
        object-fit: scale-down;
        position: relative;
      }
      &.selected {
        &::after {
          width: 68px;
          height: 68px;
          content: '';
          left: 0;
          right: 0;
          position: absolute;
          background: rgba(138, 169, 176, 0.51);
        }
      }
    }
    &__title {
      font-family: Oswald, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      text-transform: uppercase;
      color: $main-color-black;
      color: white;
    }
    &__title-company {
      font-family: Oswald, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: left;
      text-transform: uppercase;
      color: $ownership-light-active-color;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 100%;
      width: 100%;
      padding: 3px;
      text-overflow: ellipsis;
      transition: 0.3s;
      overflow: hidden;
      &.selected {
        &::after {
          width: 68px;
          height: 68px;
          content: '';
          left: 0;
          right: 0;
          position: absolute;
          background: rgba(138, 169, 176, 0.51);
        }
      }
      &:after {
        transition: 0.3s;
        top: 0;
        right: 0;
        height: 100%;
        position: absolute;
        content: '';
        box-shadow: 20px 0 20px 35px #ffffff;
      }
    }
    &.this-company {
      background-color: #8aa9b0;
      &.hovered {
        background-color: #627d7a;
      }
      &.selected {
        background-color: #627d7a;
      }
    }
    &.company {
      background-color: rgb(255, 255, 255);
      &.hovered {
        border-color: $ownership-light-active-color;
      }
      &.selected {
        border-color: $button-light-active-color;
        -moz-box-shadow: inset 0 0 10px $ownership-light-active-color;
        -webkit-box-shadow: inset 0 0 10px $ownership-light-active-color;
      }
    }

    &:before {
      content: '';
      height: $block-vertical-space;
      width: 1px;
      background: #909eb7;
      position: absolute;
      bottom: 100%;
      left: $block-width / 2 - 1px;
    }
  }
  &-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    &:last-child {
      &:before {
        content: '';
        position: absolute;
        left: $block-width / 2 + $block-horizontal-space + 1px;
        top: 0;
        height: 6px;
        margin-top: -3px;
        background: #f1f4f9;
        z-index: 0;
        width: calc(
          100% - #{($block-width / 2 + $block-horizontal-space - 1525px)}
        );
      }
    }
  }
  &-children {
    display: flex;
    justify-content: flex-start;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: $block-width / 2 + $block-horizontal-space;
      width: calc(100% - #{($block-width + $block-horizontal-space * 2 - 1px)});
      height: 1px;
      background: #909eb7;
    }
    &:after {
      content: '';
      height: $block-vertical-space;
      width: 1px;
      background: #909eb7;
      position: absolute;
      bottom: 100%;
      left: $block-width / 2 + $block-horizontal-space;
    }
  }
  .children-tree {
    align-items: flex-start;
  }
  .parents-tree {
    align-items: flex-end;
  }
  &-top {
    display: flex;
    justify-content: flex-start;
    position: relative;
    .ownership-item {
      margin: $block-vertical-space $block-horizontal-space;
      &:before {
        top: 100%;
        bottom: initial;
      }
    }
    .ownership-block {
      justify-content: flex-end;
      &:last-child {
        &:before {
          top: initial;
          z-index: 0;
          bottom: 0;
          margin-bottom: -3px;
        }
      }
    }
    .ownership-children {
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        top: initial;
        left: $block-width / 2 + $block-horizontal-space;
        width: calc(
          100% - #{($block-width + $block-horizontal-space * 2 - 1px)}
        );
        height: 1px;
        background: #909eb7;
      }
      &:after {
        content: '';
        height: $block-vertical-space;
        width: 1px;
        background: #909eb7;
        position: absolute;
        top: 100%;
        bottom: initial;
        left: $block-width / 2 + $block-horizontal-space;
      }
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: $block-width / 2 + $block-horizontal-space;
      width: calc(100% - #{($block-width + $block-horizontal-space * 2 - 1px)});
      height: 1px;
      background: #909eb7;
    }
    &:after {
      content: '';
      height: $block-vertical-space;
      width: 1px;
      background: #909eb7;
      position: absolute;
      top: 100%;
      left: $block-width / 2 + $block-horizontal-space;
    }
  }
  &-parent-top {
    display: flex;
    justify-content: flex-start;
    position: relative;
    .ownership-item {
      margin: $block-vertical-space $block-horizontal-space;
      &:before {
        top: 100%;
        bottom: initial;
      }
    }
    .ownership-block {
      justify-content: flex-end;
      &:last-child {
        &:before {
          top: initial;
          z-index: 0;
          bottom: 0;
          margin-bottom: -3px;
        }
      }
    }
    .ownership-children {
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        top: initial;
        left: $block-width / 2 + $block-horizontal-space;
        width: calc(
          100% - #{($block-width + $block-horizontal-space * 2 - 1px)}
        );
        height: 1px;
        background: #909eb7;
      }
      &:after {
        content: '';
        height: $block-vertical-space;
        width: 1px;
        background: #909eb7;
        position: absolute;
        top: 100%;
        bottom: initial;
        left: $block-width / 2 + $block-horizontal-space;
      }
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: $block-width / 2 + $block-horizontal-space;
      width: 1px;
      // width: calc(100% - #{($block-width + $block-horizontal-space * 2 - 1px)});
      height: 1px;
      background: #909eb7;
    }
    &:after {
      content: '';
      height: $block-vertical-space;
      width: 1px;
      background: #909eb7;
      position: absolute;
      top: 100%;
      left: $block-width / 2 + $block-horizontal-space;
    }
  }
  &-bottom {
    display: flex;
    justify-content: flex-start;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: $block-width / 2 + $block-horizontal-space;
      width: calc(100% - #{($block-width + $block-horizontal-space * 2 - 1px)});
      height: 1px;
      background: #909eb7;
    }
    &:after {
      content: '';
      height: $block-vertical-space;
      width: 1px;
      background: #909eb7;
      position: absolute;
      bottom: 100%;
      left: $block-width / 2 + $block-horizontal-space;
    }
  }
  &-current {
    display: flex;
    justify-content: flex-start;
    .ownership-item {
      &:before {
        content: none;
      }
    }
  }
}
