@import "../shared/styles/control.module";
@import "../../../../assets/scss/components/UI/button";

.btn_upload {
   @extend .button-dark;

   display: block;
   width: max-content;
   height: 26px;
   line-height: 28px;
   padding: 0 10px;
   margin-top: 5px;
   font-size: 12px;
   font-weight: 700;
}

.upload_icon {
   margin-left: 7px;
   position: relative;
   top: 2px;
}

.attachment_wrapper {
   display: flex;
   margin-bottom: 5px;
   cursor: pointer;

   &:hover .attachment_name {
      color: $link-hover-color;
   }

   &:last-of-type {
      margin-bottom: 10px;
   }
}

.attachment_name {
   @include ellipsis-truncate;
   max-width: 280px;
   display: inline-block;
   padding-left: 3px;
   font-weight: 700;
   font-size: 16px;
   color: $link-color; 
}

.remove_icon {
   @include times-icon(10px, 1px, $main-color-black);
   padding: 0 8px 2px 5px;
}