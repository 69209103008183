.properties-in-the-same-group {
   @extend %content-block;
   width: 50%;
   padding: 20px;

   &__item {
      border-bottom: 1px solid rgba($text-color, 0.1);

      padding: 20px 0;

      &__link{
         justify-content: space-between;
      }

      &:first-of-type {
         padding-top: 0;
      }

      &:last-of-type {
         padding-bottom: 0;
         border: none;
      }
   }

   .item__address {
      font-size: 16px;
      line-height: 20px;
      padding-top: 5px;
   }
}
