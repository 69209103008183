@import './data-list';
@import './filters';
@import './notes-tab';
@import './ownership';
@import './properties';

.main-layout {
  display: flex;
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
}

#main {
  width: 100%;
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
}

#portal-root {
  width: 100%;
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  display: flex;
}

@include mq-tablet {
  .main-layout {
    flex-direction: column;
  }

  #main {
    height: 94.5%;
    height: calc(100% - #{$navigation-height-mobile});
  }

  .navigation {
    order: 4;
  }
}

.page-layout {
  display: flex;
  background-color: $page-bg-color;

  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @include mq-tablet {
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex: 1 1 100%;
    min-width: 320px;
  }

  #data-list-container {
    @extend .box-shadow-container;

    .data-list-scrollbar {
      left: -13px !important;

      @include mq-tablet {
        left: -8px !important;
      }
    }
  }

  .item-info {
    display: flex;
    flex-direction: column;
    position: relative;

    background-color: $page-bg-color;
    width: 100%;
    min-width: 525px;

    .button-add-new {
      flex-shrink: 0;
    }

    &__tab {
      display: flex;
      max-width: $tab-max-width;
      padding-top: 12px;
      padding-right: 20px;
      padding-bottom: 30px;

      @include mq-tablet {
        padding-right: 25px;
      }
      @include mq-tablet {
        padding: 12px 15px 0px 15px;
      }

      @include mq-phone {
        flex-direction: column;
        margin-bottom: 0;
        padding: 0;
      }
    }

    .items-container {
      display: flex;
      height: 100%;
      @include mq-phone {
        padding-bottom: 0;
      }
    }

    @include mq-phone {
      min-width: unset;
    }
  }
}

.involvement-history_hidden,
.enterprise-involvements_hidden,
.sales-tab__transactions_hidden,
.leases-tab__transactions_hidden,
.tabs__swiper_hidden {
  @include mq-phone {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    z-index: 2;
    white-space: nowrap;

    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);

    clip: rect(0 0 0 0);
    overflow: hidden;
  }
}

%content-block {
  height: 100%;
  height: max-content;
  background: $main-color;
  box-shadow: 0px 4px $box-shadow-stretch $box-shadow;
  border-radius: 3px;

  h2,
  h3,
  h4 {
    color: $text-color;
    font-family: $OswaldFont;
    font-weight: 500;
    text-transform: uppercase;
  }

  h2,
  .heading,
  .title {
    margin-bottom: 20px;
    font-size: 20px;
  }

  h3 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 15px;
  }

  h4 {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 15px;
  }

  header {
    display: flex;
    justify-content: space-between;

    .button-go-back {
      position: absolute;
      display: none;
      top: 15px;
    }

    @include mq-tablet {
      .button-go-back {
        display: block;
      }
    }
  }

  .description,
  .notes,
  .content-block__description,
  .content-block__notes {
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 18px;
  }

  @include mq-phone {
    width: 100vw;
  }
}

%info-block {
  @extend %content-block;

  width: 100%;
  height: 100%;
  height: max-content;
  margin-bottom: 10px;

  header {
    display: flex;
    justify-content: space-between;
  }

  h2,
  .heading,
  .title {
    line-height: 30px;

    & + div {
      margin-top: -3px;
    }
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 15px;
    font-family: $OswaldFont;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
  }

  .button-go-back {
    position: absolute;
    display: none;
    top: 15px;
  }

  @include mq-tablet {
    margin: 0;
    margin-bottom: 15px;

    .button-go-back {
      display: block;
    }
  }
}

.data-field {
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid #efeeee;
  padding-bottom: 7px;
  margin-bottom: 10px;
  .no-information {
    color: #dcdedf;
  }
  &__key {
    min-width: 130px;
    max-width: 130px;
    margin-right: 10px;
    font-family: $OswaldFont;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: $label-color;

    &_lg {
      &.no-information {
        color: #dcdedf;
      }
      @extend .data-field__key;
      max-width: 173px;

      @include mq-phone {
        min-width: 151px;
      }

      @include mq-phone-sm {
        min-width: 120px;
        max-width: 150px;
      }
    }
  }
  &__array-item {
    display: flex;
    flex-direction: column;
    span {
      margin-top: 5px;
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #434343;
    }
  }

  &__value {
    word-break: break-word;
    font-size: 16px;
    width: 100%;
    color: $text-color;
    .country-title {
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #8e8e8e;
    }
    &.active {
      color: #53a956;
    }
    &.inactive {
      color: #cf4628;
    }
  }

  &__additional {
    padding: 0px 5px 2px 13px;
    display: flex;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #dcdedf;
    box-sizing: border-box;
    border-radius: 2px;
    width: fit-content;
    margin-bottom: -20px;
    margin-left: 40%;
    align-items: center;
    .gen-title {
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      color: #1f2531;
      margin-right: 12px;
    }
  }

  &__involvement-date {
    color: $label-color;
    font-size: 12px;
    line-height: 15px;
    padding-top: 2px;
  }

  &__multiple {
    margin-top: 5px;
  }

  &_with-link {
    @extend .data-field;
    align-items: baseline;
  }
}

.button-add-new {
  @extend .button-sm-light;
  box-shadow: 0px 4px $box-shadow-stretch $box-shadow;
  flex-shrink: 0;

  line-height: 15px;
}

.button-toggle-stacking-plan {
  width: 25px;
  height: 25px;
  border: none;
  margin-left: 0;
  margin-right: 5px;

  &:hover,
  &:active {
    border: 1px solid $button-light-active-color;
  }
}

.system-id {
  color: $text-color-light;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 16px;

  &__key,
  &__value {
    color: $text-color-gray;
  }

  &__value {
    padding-left: 3px;
    font-weight: 700;
  }
}

.box-shadow-container {
  &__scrollbar,
  .scrollbar,
  .page-scrollbar-y {
    left: 12px;
  }
}

.not-found-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  &__status {
    font-family: Adobe Clean;
    font-style: normal;
    font-weight: bold;
    font-size: 144px;
    line-height: 180px;
    text-align: center;
    color: $text-color;
  }
  &__problem {
    font-family: Adobe Clean;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    /* identical to box height */
    text-align: center;
    color: $text-color;
  }
  &__comment {
    font-family: Adobe Clean;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    text-align: center;
    color: $text-color;
  }
  @include mq-tablet {
    &__status {
      font-size: 110px;
      line-height: 125px;
    }
    &__problem {
      font-size: 25px;
      line-height: 35px;
    }
    &__comment {
      font-size: 10px;
      line-height: 15px;
    }
  }
}

%info-container {
  width: 100%;
  height: 100%;

  .box-shadow-container > div {
    @include mq-phone {
      padding-right: 0 !important;
    }
  }

  .page-scrollbar-y {
    left: 7px !important;
  }

  @include mq-phone {
    .page-scrollbar-y {
      left: -14px !important;
    }
  }
}

@include mq-tablet {
  .button-toggle {
    width: 35px;
    height: 35px;
  }

  .button-toggle-stacking-plan {
    width: 30px;
    height: 30px;
  }
}

@include mq-phone {
  .button-add-new {
    min-width: 85px !important;

    span {
      padding: 3px 0;
    }
  }
}
