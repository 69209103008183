.owned-properties {
  overflow-x: auto;

  &-table {
    overflow-x: auto;
    width: max-content;
    border-collapse: collapse;
    th {
      text-align: start;
      font-family: Oswald;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
      color: #1f1f1f;
      padding-bottom: 15px;
      padding-right: 60px;
    }
    td {
      vertical-align: top;
      padding: 15px 60px 15px 0;
      border-top: 0.5px solid #dcdedf;
      .position {
        margin-bottom: 6px;
        padding: 1px 5px;
        font-family: Adobe Clean;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #ffffff;
        background: #434343;
        border-radius: 2px;
      }
      .first-row {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
      }
      .third-row {
        display: flex;
        align-items: center;
        margin-top: 5px;
        .transaction-date {
          font-family: Adobe Clean;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          color: #8e8e8e;
        }
        .status {
          padding: 1px 5px;
          background: #f10707;
          border-radius: 2px;
          font-family: Adobe Clean;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
          text-transform: uppercase;
          color: #ffffff;
          margin-right: 8px;
        }
      }
      .link {
        font-family: Adobe Clean;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #1f3560;
        margin-right: 5px;
      }

      .title {
        font-family: Adobe Clean;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #8e8e8e;
        margin-bottom: 8px;
      }
      .no-information {
        color: #dcdedf;
      }
    }
  }

  &-property {
    display: flex;
    flex-direction: row;
    .image {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      border-radius: 2px;
      border: 1px solid #f6f6f6;
      img {
        width: 100%;
        height: 100%;
        border-radius: 2px;
      }
    }
    .additional-information {
      display: flex;
      flex-direction: column;
      .title {
        margin-bottom: 5px;
        font-family: Adobe Clean;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #1f1f1f;
      }
      .address {
        font-family: Adobe Clean;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #1f3560;
      }
      .types {
        width: 350px;
        margin-top: 8px;
        font-family: Adobe Clean;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #8e8e8e;
      }
    }
  }
}
