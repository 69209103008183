#involvement-info-scrollbars {
   @extend .box-shadow-container;

   .page-scrollbar-y {
      left: 7px !important;
   }

   @include mq-phone {
      .page-scrollbar-y {
         left: -7px !important;
      }  
   }
}

.involvement-info {
   @extend %content-block;

   width: 100%;
   height: 100%;
   height: max-content;
   padding: 20px;

   header {
      display: flex;
      justify-content: space-between;
   }

   &__content {
      display: flex;

      .enterprise-logo {
         margin-right: 20px;

         img {
            max-width: 95px;
            max-height: 95px;
            object-fit: scale-down;
         }
      }

      .content__data-fields {
         width: 100%;
      }

      .description {
         margin-top: 20px;
         font-size: 14px;
         color: $text-color;
      }
   }

   @media screen and (max-width: 1160px) {
      &__content {
         flex-direction: column;

         .photo {
            margin-bottom: 15px;
         }
      }
   }
}