#owned-properties-scrollbars {
  @extend .box-shadow-container;
  position: relative;

  .page-scrollbar-y {
    left: -12px !important;
  }

  @include mq-tablet {
    .page-scrollbar-y {
      left: -8px !important;
    }
  }

  @include mq-phone {
    .page-scrollbar-y {
      left: -2px !important;
    }

    margin: 0px !important;

    & > div {
      padding: 0 !important;
    }
  }
}

.owned-properties-tab {
  height: unset !important;
}

.owned-properties {
  @extend %info-block;
  height: 100%;
  padding: 20px;

  .header h2 {
    line-height: 30px;
  }

  h3.enterprise-name {
    font-family: $AdobeCleanFont;
    margin-top: 0;
    text-transform: capitalize;
  }

  .content__left {
    width: 30%;
  }

  .content__right {
    width: 70%;

    .data-field {
      &__key_lg {
        &.no-information {
          color: #dcdedf;
        }
        min-width: unset;
        max-width: unset;
        width: 35%;
      }

      &__value {
        // @include ellipsis-truncate;
        width: 60%;
      }
    }
  }

  @include mq-phone {
    .content__left,
    .content__right {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    .no-information {
      display: flex;
      font-weight: 100;
      color: #d6d6d6fc;
    }

    .logo {
      margin-right: 20px;

      img {
        max-width: 195px;
        max-height: 195px;
        width: 100%;
        object-fit: scale-down;
      }

      .briefcase-icon {
        height: 195px;
        width: 100%;
      }

      @include mq-tablet {
        margin-bottom: 20px;

        img {
          max-height: 210px;
        }
      }

      @include mq-tablet {
        margin-bottom: 0;
      }

      @include mq-phone {
        margin-bottom: 20px;
      }
    }

    .enterprise-name {
      color: $text-color;
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .description {
      margin-top: 0;
      margin-bottom: 20px;
    }

    @include mq-tablet {
      flex-direction: column;
    }

    @include mq-tablet {
      flex-direction: row;
    }

    @include mq-phone {
      flex-direction: column;
    }
  }
}
