#property-info-container {
  @extend .box-shadow-container;

  .page-scrollbar-y {
    left: -13px !important;

    @include mq-tablet {
      left: -7px !important;
    }
  }
}

.property-info {
  @extend %info-block;
  padding: 20px;

  .no-information {
    display: flex;
    font-weight: 100;
    color: #d6d6d6fc;
  }

  h2 {
    margin-bottom: 10px !important;
  }

  &__content {
    display: flex;
    margin-top: 15px;
    &.column {
      flex-direction: column;
      .content__col {
        width: 100%;
      }
      .content{
        column-count: 1;
      }
    }
    .content{
      column-count: 2;
      column-gap: 40px;
    }
    .content__col {
      width: 50%;
    }

    .content__left-col {
      margin-right: 5.5%;
    }

    @include mq-phone {
      flex-direction: column;

      .content__col {
        width: 100%;
      }
    }
  }
}

.property-additional-info {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
  @include mq-phone {
    flex-direction: column;
  }
}
