.users-page {
   @extend .page-layout;

   &__content {
      width: 100%;
      margin-right: 20px;

      @include mq-tablet {
         padding: 15px;
         margin: 0;
      }

      @include mq-phone {
         padding: 0;
      }
   }
}

#user-info {
   @extend %info-block;
   margin: 15px 20px 15px 0;
   padding: 15px 20px 20px 20px;
   max-width: $tab-max-width;
   position: relative;

   .button-go-back {
      display: none;
   }

   .user-info__content {
      display: flex;

      .column {
         width: 50%;

         &:first-child {
            margin-right: 40px;
         }
      }
   }

   @media screen and (max-width: 1150px) {
      .user-info__content {
         flex-direction: column;

         .column {
            width: 100%;
         }
      }
   }

   @include mq-tablet {
      padding-top: 40px;

      .button-go-back {
         display: block;
         cursor: pointer;
      }

      .user-info__content {
         flex-direction: row;
      }
   }

   @include mq-phone {
      .button-go-back {
         margin-top: 0;
      }

      .user-info__content {
         flex-direction: column;

         .column {
            width: 100%;
         }
      }
   }
}