@import '../../vars';

.history-modal {
  .go-back {
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 10px 10px 10px;
    border-bottom: 1px solid rgba(29, 29, 29, 0.15);
    &::after {
      content: '';
      display: block;
      background: transparent;
      height: 5px;
      width: 100%;
      position: absolute;
      left: 0;
      margin-top: 36px;
      // bottom: 0;
      z-index: 1;
      box-shadow: 0 3px 3px rgba(23, 40, 68, 0.18);
    }
    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      // button decoration
      width: 83px;
      height: 23px;
      background: $button-background;
      border-radius: 2px;
      .arrow-icon {
        margin-right: 10px;
        margin-top: 0px;
      }
      .title {
        // text decoration
        font-family: Adobe Clean;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: $main-color;
      }
    }
    &-placeholder {
      margin-left: 10px;
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: $button-background;
    }
  }
  .datalist-items {
    &-history {
      &::before {
        content: '';
        display: block;
        background: $modal-background;
        height: 8px;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        left: 0;
      }
    }
  }
}
