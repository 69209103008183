#ownership-structure-container {
  width: unset !important;
  margin-left: -25px;
  margin-right: -5px;
  margin-bottom: 15px;

  & > div {
    width: unset;
    padding: 0 $box-shadow-stretch;
  }

  .page-scrollbar-y {
    left: 7px !important;
  }

  @include mq-tablet {
    & > div {
      padding-right: 20px !important;
    }
  }
}

.ownership-structure-tab {
  display: flex;
  padding-right: 0 !important;

  & > div:first-child,
  &__parent {
    margin-right: 20px;
  }

  &__parent,
  &__subsidiary {
    width: 100%;
  }
}

.ownership-structure__heading {
  color: green;
  font-family: $OswaldFont;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  margin-top: 6px;
  margin-bottom: 10px;

  @include mq-phone {
    margin-left: 15px;
  }
}

.company-info {
  @extend %content-block;
  margin-top: 8px;
  z-index: 2;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  min-width: 255px;
  min-height: 200px;
  position: relative;
  max-width: 450px;

  hr {
    border-top: 1px solid rgba($text-color, 0.1);
    border-bottom: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    & > div:last-child {
      margin-top: -2px;
    }
  }

  .company-name {
    margin-bottom: 5px;
  }

  .company-type {
    color: $text-color;
    font-size: 12px;
    margin-bottom: 10px;
  }

  &__stats {
    display: flex;
    margin: 10px 0;

    .stat {
      &:not(:last-child) {
        margin-right: 25px;
      }

      &__number {
        color: $text-color;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }

      &__description {
        color: $text-color;
        font-size: 10px;
      }
    }
  }

  &__people {
    margin-top: 15px;
  }

  .people {
    &__heading {
      margin-top: 15px;
      margin-bottom: 10px;
      color: $label-color;
      font-family: $OswaldFont;
      font-size: 14px;
      font-weight: normal;
      text-transform: uppercase;
    }

    &__owners,
    &__decision-makers {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .person {
    width: 100%;
    flex: 50%; /* or - flex: 0 50% - or - flex-basis: 50% - */
    margin-bottom: 5%;
    &__position {
      color: $text-color;
      font-size: 12px;
      margin-bottom: 2px;
    }
  }
}
