.section-login{
  width: 100vw;
  display: flex;
  min-height: calc(var(--vh, 1vh) * 100);

  align-items: stretch;
  justify-content: space-between;
  .left{
    position: relative;
    width: 46%;
    .bg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  .right {
    @include flex-center;
    flex-direction: column;
    width: 54%;

    @include mq-desktop {
      position: relative;
      left: -34px;
      margin-top: -190px;
    }

    .form{
      display: flex;
      flex-direction: column;
      &__wrapper{
        display: inline-block;
      }
      .field{
        & > div {
          max-width: unset;
        }
        margin-bottom: 5px;
        display: block;
        position: relative;
        .label-text{
          font-family: $OswaldFont;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;

          text-transform: uppercase;

          color: $label-color;

          margin-bottom: 8px;

          display: block;
        }
        input {
          font-family: $AdobeCleanFont;
          font-style: normal;
          margin-bottom: 0;
          font-weight: 500;
          line-height: 15px;
          width: 100%;
          outline: none;

          color: $text-color;
          &::placeholder {
            color: $input-default-color;
          }
        }
        &.error{
          .label-text{
            color: $error-color;
          }
          &:after{
            background: #F10707;
            opacity: 0.2;
          }
        }
        .error-message{
          font-family: $AdobeCleanFont;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 13px;
          /* identical to box height */

          text-align: right;
          letter-spacing: 0.03em;

          color: $error-color;
          position: absolute;
          right: 0;
          top: 100%;
          margin-top: -13px;
        }
      }
      .login-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-width: 100%;
        border-radius: 2px;
        padding: 10px;
        margin-top: 10px;
      }
    }
    h4{
      font-family: $OswaldFont;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 26px;
      text-transform: uppercase;

      color: rgba($text-color, .5);

      margin-top: 0;
      margin-bottom: 0;
    }
    h3{
      font-family: $OswaldFont;
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 53px;
      text-transform: uppercase;

      color: $text-color;

      margin-top: 0;
      margin-bottom: 58px;
    }
  }
}

@media screen and (max-width: 650px) {
  .section-login {
    flex-direction: column;

    .left,
    .right {
      width: 100%;
    }

    .left {
      width: 175px;
      height: 175px;
    }

    .right {
      justify-content: unset;
      align-items: unset;
      padding: 15px;
      flex-grow: 1;
    }
  }
}

@include mq-phone-sm {
  .section-login .right .app-title {
    margin-bottom: 30px;
  }
}