@import '../../../../assets/scss/_vars';

.link_container {
  display: flex;
  overflow-wrap: anywhere;
  svg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    padding: 0px 0px 2px 3px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background: rgba($other-user-color, 0.2);
      border-radius: 2px;
    }
  }
}

.link {
  text-decoration: none;
  font-family: $AdobeCleanFont;
  font-weight: 700;
  font-size: 16px;
  color: $link-color;
  transition: 0.3s;
  &:hover {
    background: rgba($other-user-color, 0.2);
    border-radius: 2px;
  }
  &_disabled {
    @extend .link;
    color: $input-disabled-color;
  }
}

.link_icon {
  cursor: pointer;

  &:disabled,
  &_disabled {
    svg path:first-child {
      fill: $input-disabled-color;
    }
    svg path:last-child {
      stroke: $input-disabled-color;
    }
  }
}
