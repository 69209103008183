$text-color: #1f1f1f;
$text-color-gray: rgba($text-color, 0.5);
$text-color-light: #ffffff;
$label-color: #8e8e8e;
$link-color: #1f3560;
$link-hover-color: #513c06;
$disabled-color: #d1d5d6;
$error-color: #ff0000;

$page-bg-color: #f1f4f9;
$loader-container-bg: #eaeef3;
$main-color: #ffffff;
$main-color-black: #1f2531;
$main-color-gray: #999ea0;
$input-default-color: #999ea0;
$input-hover-color: #1f1f1f;
$input-active-color: #1f1f1f;
$input-disabled-color: #c1c1c1;
$input-underline-default-color: rgba($main-color-black, 0.1);
$input-underline-disabled-color: rgba(193, 193, 193, 0.1);
$modal-title-color: rgba(19, 34, 38, 0.5);

$button-dark-default-color: #1f2531;
$button-dark-hover-color: #3b545a;
$button-dark-active-color: #1f1f1f;
$button-dark-disabled-color: #dcdedf;

$button-light-default-color: #ffffff;
$button-light-hover-color: #d2e1e4;
$button-light-active-color: #8aa9b0;
$button-light-disabled-color: #ffffff;

$scrollbar-color: #babdc4;
$selection-color: #3b545a;
$option-bg-color: #1f2531;
$backdrop-overlay-color: rgba(0, 0, 0, 0.8);
$box-shadow: rgba(0, 0, 0, 0.1);

$transition-in: all 0.2s ease-in;
$transition-out: all 0.2s ease-out;

$listing-status_active: #53a956;
$listing-status_pending: #d3c013;
$listing-status_sold: #cf4628;
$listing-status_leased: #cf4628;
$listing-status_canceled: #939495;
$listing-status_expired: #49627e;

$button-tooltip-color: #373737;

$error-title-color: rgba(188, 71, 57, 0.5);
$error-text-color: #bc4739;
$error-background: #fff9f9;

$border-color: #dcdedf;
$unerline-color: #1b2f34;

$border-bottom-rgba: gba(27, 47, 52, 0.1);


$blue-color: #4265AB;