.tag {
  &-options {
    margin-left: -15px;
    margin-top: 10px;
    width: 98px;
    border-top: none;
    border-radius: 2px;
  }
  &-container {
    display: flex;
    position: absolute;
    z-index: 2;
  }

  &-option {
    display: flex;
    line-height: 15px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #1f2531;
    background-color: #fff;
    border: 1px solid rgba(31, 31, 31, 0.1);
  }

  &-option:hover {
    color: white;
    background-color: #1f2531;
    cursor: pointer;
  }

  &-option:hover span {
    color: white;
  }

  &-option:hover svg path {
    fill: white;
  }

  &-option span {
    margin: auto 0;
  }

  &-option:not(:last-child) {
    border-bottom: none;
  }

  &-option:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  &-option:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &-option-icon {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
  }

  .close_icon {
    font-size: 24px;
    line-height: 20px;
    padding: 15px 13px;
  }

  @media screen and (max-width: 480px) {
    &-options-tags {
      position: fixed;
      width: 100%;
      bottom: 0;
      border: none;
    }
    &-option {
      height: 50px;
      font-size: 16px;
    }
    &-option span {
      line-height: 20px;
      padding-top: 16px;
      padding-bottom: 15px;
    }
    &-option-icon {
      width: 40px;
      height: 50px;
    }
  }
  &-creation {
    position: absolute;
    z-index: 4;
    background-color: white;
    margin-left: 45px;
    margin-top: 40px;
    border-radius: 5px;
    padding: 15px 10px 10px 10px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);

    div {
      margin-bottom: 0;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
