@import '../../vars';

.card {
  transition: 0.3s;
  position: relative;

  &:last-child {
    margin-bottom: 50px;
  }

  &.template {
    display: flex;
    flex-direction: column;
    width: 425px;
    transition: 0.3s;
    background: $main-color;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    margin-bottom: 20px;
    flex-shrink: 0;
    &:hover{
      .roller-icon-hide{
        opacity: 1;
        pointer-events: all;
      }
    }

    .roller-icon-hide{
      opacity: 0;
      pointer-events: none;
      z-index: 6;
      transition: .3s;

      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(#FFFFFF, 0.9);
      border-radius: 0 3px 0 0;

      cursor: pointer;
    }

    &:first-child {
      .control-panel .option-menu .option-list {
        right: 110%;
        bottom: 0;
      }
    }

    &:last-child {
      margin-bottom: 50px;

      .option-list {
        bottom: 30px;
      }
    }

    .reminder-roller {
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 11px;
      background: #f6f6f6;

      span {
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #e5e5e6;
      }
    }

    .addition {
      display: flex;
      flex-direction: row;
      padding: 10px 15px;
      border-bottom: 1px solid #f6f6f6;

      a {
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
      }

      .added-to {
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: rgba(31, 31, 31, 0.8);
      }
    }

    .control-panel {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      width: 100%;
      border-top: 1px solid #F6F6F6;
      position: relative;
      z-index: 6;

      &__wrapper {
        max-height: 60px;
        transition: max-height .5s;
      }

      .time {
        max-width: 50%;
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #434343;
        padding: 12px 10px;
        flex-shrink: 0;
      }

      .option-menu {
        background: #fff;
        height: 40px;
        width: 40px;

        .option-menu-button {
          height: 40px;
          width: 40px;
          background: #fff;
          transition: .3s;

          circle {
            transition: .3s;
          }

          &:hover {
            svg {
              circle {
                fill: #4265AB;
              }
            }
          }

          &.open {
            background: #F1F4F9;
          }
        }

        .option-menu-item {
          padding: 5px 15px 10px;
          margin-top: -5px;
          border: none;

          &:hover {
            span {
              color: #ffffff;
            }
          }

          &__icon {
            display: none;
          }

          span {
            padding: 0;
            font-family: $OswaldFont;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: #1F2531;
          }

          &:first-child {
            padding-top: 10px;
          }

          &:last-child {
            padding-bottom: 10px;
          }
        }

        .option-list {
          right: 0;
          width: initial;
          background: #FFFFFF;
          box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
          bottom: 45px;
        }
      }

      .tag-added {
        display: flex;
        position: relative;
        justify-content: flex-start;
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #434343;
        align-items: center;
        overflow: hidden;
        border-color: #F6F6F6;
        padding-left: 10px;
        border-style: solid;
        border-width: 0 1px 0 1px;

        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 70%, #FFFFFF 100%);
          width: 25px;
        }

        &-title {
          flex-shrink: 0;
          white-space: nowrap;
          margin-right: 4px;
        }

        .entity {
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: #1F3560;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .roller-icon {
        width: 40px;
        height: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        path {
          transition: .3s;
        }

        &:hover {
          path {
            fill: #4265AB;
          }
        }
      }

      &__right {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
      }
    }

    .information {
      padding: 15px;
      display: flex;

      &.reminder {
        border-top: none;
      }

      border-top: 1px solid #f6f6f6;

      .OptionsMenu_container__1AAza {
        position: absolute;
        z-index: 2;
        width: fit-content;
        right: 9%;
      }

      .person-image {
        @include flex-center;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        object-fit: cover;
        width: 50px;
        height: 50px;
        border: 1px solid #f7f7f7;
        box-sizing: border-box;
        border-radius: 2px;
      }

      .initials {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background: rgba(246, 246, 246, 0.4);
        border: 1px solid $border-color;
        border-radius: 2px 0 0 2px;
      }

      .creation {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-right: 30px;
        width: 252px;
        justify-content: center;
        text-transform: capitalize;

        .created-by {
          margin-bottom: 4px;
          font-family: $AdobeCleanFont;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          color: rgba(31, 31, 31, 0.8);
        }
      }

      .options {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .connectors {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          .roller-icon {
            cursor: pointer;
            margin-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          }
        }

        .time {
          font-family: $AdobeCleanFont;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          color: #434343;
        }
      }
    }

    .tags-container {
      display: flex;
      margin: 10px 15px;
      align-items: center;

      .category {
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        margin-left: 10px;
        color: #1f2531;
      }
    }

    .card--value {
      padding: 15px;
      font-family: $AdobeCleanFont;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $text-color;
    }
  }

  &.edited {
    transition: 0.3s;
    border-color: #1f2531;
    box-shadow: none;
  }

  &:not(.open) {
    .card--value__wrapper {
      max-height: 25px !important;
    }

    .reminder__wrapper:not(.main) {
      max-height: 0 !important;
    }

    .roller-line {
      opacity: 1;
      transform: translateX(0);
      transition: opacity .5s, transform 0s 0s;
    }

    .control-panel__wrapper {
      max-height: 0;
    }
  }

  .roller-line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(#F6F6F6, .7);
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 5px 10px;
    height: 100%;
    opacity: 0;
    z-index: 5;
    transition: opacity 0.5s, transform 0s .5s;
    transform: translateX(-100vw);
    cursor: pointer;

    .roller-icon {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0;
    }
  }

  .preview-link {
    display: inline-flex;
    align-items: center;
    font-family: $AdobeCleanFont;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    height: 20px;
    border-radius: 2px;
    padding: 2px 5px;
    background: rgba($other-user-color, 0.08);
    color: $other-user-color;
    transition: .3s;

    &:hover {
      background: rgba($other-user-color, 0.2);
    }

    &.selected {
      color: #7342C2;
      background: rgba(#7342C2, 0.15);
    }
  }

  &.open {
    .card--value__wrapper {
      min-height: 0;
    }
  }

  &--value {
    &__wrapper {
      transition: .5s;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      z-index: 1;
      min-height: 25px;
    }

    .time {
      font-weight: bold;
    }
  }

  .entity-link {
    transition: 0.3s;
    display: flex;
    flex-direction: row;
    padding: 10px 15px;
    font-family: $AdobeCleanFont;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #1f2531;
    align-items: center;
    border-top: 1px solid #f6f6f6;
  }
}
