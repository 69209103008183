$AdobeCleanFont: 'Adobe Clean', sans-serif;
$OswaldFont: 'Oswald', sans-serif;

$text-color: #1f1f1f;
$text-color-gray: rgba($text-color, 0.5);
$text-color-light: #ffffff;
$label-color: #627D7A;
$link-color: #1f3560;
$link-hover-color: #513c06;
$disabled-color: #DCDEDF;
$error-color: #ff0000;

$ownership-light-color: #8aa9b0;
$ownership-light-active-color: #8aa9b0;
$ownership-light-transparent-color: rgba(246, 246, 246, 0.4);

$page-bg-color: #f1f4f9;
$loader-container-bg: #eaeef3;
$main-color: #ffffff;
$main-color-black: #1f2531;
$main-color-gray: #999ea0;
$input-default-color: #999ea0;
$input-hover-color: #1f1f1f;
$input-active-color: #1f1f1f;
$input-disabled-color: #c1c1c1;
$input-underline-default-color: rgba($main-color-black, 0.1);
$input-underline-disabled-color: rgba(193, 193, 193, 0.1);
$modal-title-color: rgba(19, 34, 38, 0.5);

$button-dark-default-color: #1f2531;
$button-dark-hover-color: #3b545a;
$button-dark-active-color: #1f1f1f;
$button-dark-disabled-color: #dcdedf;

$button-light-default-color: #ffffff;
$button-light-hover-color: #d2e1e4;
$button-light-active-color: #8aa9b0;
$button-light-disabled-color: #ffffff;

$scrollbar-color: #babdc4;
$selection-color: #3b545a;
$option-bg-color: #1f2531;
$backdrop-overlay-color: rgba(0, 0, 0, 0.8);
$box-shadow: rgba(0, 0, 0, 0.1);

$transition-in: all 0.2s ease-in;
$transition-out: all 0.2s ease-out;

$listing-status_active: #53a956;
$listing-status_pending: #d3c013;
$listing-status_sold: #cf4628;
$listing-status_leased: #cf4628;
$listing-status_canceled: #939495;
$listing-status_expired: #49627e;

$lease-visualization-bg: #373737;

/* Notes */
$button-background: #1f2531;
$content-background: #373737;
$history-card-background: #fcfdff;
$tag-background: #50709b;
$modal-background: #f9f9f9;
$border-color: #f7f7f7;
$authorized-user-color: #7342c2;
$other-user-color: #4265ab;

/* layout */
$tab-max-width: 1024px;
$phone-screen: 415px;
$navigation-width-desktop: 140px;
$navigation-height-mobile: 55px;
$data-list-width: 32.7%;
$data-list-min-width: 280px;
$data-list-max-width: 520px;
$box-shadow-stretch: 25px;
