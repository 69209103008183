.list-row {
  &-title {
    font-family: Adobe Clean;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin: 0 14px;
    line-height: 18px;
    color: #1f1f1f;
    margin: 14px 14px 14px 0px;
  }
  &-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    &-edit {
      display: flex;
      padding: 5px 10px;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      background: #1f2531;
      border: 1px solid transparent;
      box-sizing: border-box;
      border-radius: 2px;
      cursor: pointer;
      width: 64px;
      height: 26px;
      -webkit-transition: all 0.2s ease-in;
      transition: all 0.2s ease-in;
      &:hover {
        background: #3b545a;
        border: 1px solid #1b2f34;
      }
      span {
        font-family: Adobe Clean;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #ffffff;
        margin-left: 8px;
      }
    }
    &-delete {
      width: 30px;
      height: 26px;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #d1d5d6;
      box-sizing: border-box;
      border-radius: 2px;
      padding: 7px 10px;
      display: flex;
      margin-left: 10px;
      &:hover {
        border: 1px solid #627d7a;
        box-sizing: border-box;
      }
      -webkit-transition: all 0.2s ease-in;
      transition: all 0.2s ease-in;
    }
  }
  &-item {
    &-content {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid rgba(31, 31, 31, 0.1);
    }
  }
}
