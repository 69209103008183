.transaction-items-container {
  @extend .box-shadow-container;

  .page-scrollbar-y {
    left: 4px !important;
  }
}

.transaction-item-container {
  @extend .data-item;
  position: relative;
  padding: 10px;
  flex-shrink: 0;
}

.transaction-current-status {
  font-family: Adobe Clean;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  border-radius: 2px;
  padding: 1px 5px;
  text-transform: uppercase;
  color: #ffffff;

  &_active {
    background-color: $listing-status_active;
  }

  &_pending {
    background-color: $listing-status_pending;
  }

  &_sold,
  &_leased {
    background-color: $listing-status_sold;
  }

  &_canceled,
  &_cancelled {
    background-color: $listing-status_canceled;
  }

  &_expired {
    background-color: $listing-status_expired;
  }

  // &_starred {} // : for future releases
}

.transaction-item {
  @include ellipsis-truncate;
  width: 100%;
  &s{
    .new-items {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      &-notification {
        cursor: pointer;
        position: absolute;
        left: 20px;
        width: calc(100% - 49px);
        height: 26px;
        background: #0ebc7e;
        display: flex;
        border-radius: 3px;
        z-index: 3;
        justify-content: center;
        align-items: center;
        span {
          font-family: $AdobeCleanFont;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
        }
      }
      &-dash {
        width: 100%;
        margin-top: -5px;
        margin-bottom: 10px;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        span {
          font-family: $AdobeCleanFont;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
          text-transform: uppercase;
          color: #ffffff;
          padding: 1px 6px;
          background: #0ebc7e;
          border-radius: 2px;
          z-index: 2;
        }
        &::after {
          z-index: 1;
          content: '';
          width: 100%;
          height: 2px;
          position: absolute;
          background: #0ebc7e;
          border-radius: 2px;
        }
      }
    }
  }

  &__information {
    display: flex;
    align-items: center;
    width: 75%;
  }
  &__image {
    width: 100%;
    height: 100%;
    img {
      width: 45px;
      height: 45px;
      object-fit: scale-down;
      border-radius: 3px 0px 0px 3px;
    }
    margin-right: 10px;
  }
  &__current-status {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &__enterprise-name {
    @include ellipsis-truncate;
    overflow: hidden !important;
    white-space: nowrap;
    color: $label-color;
    font-weight: 700;
    font-size: 14px;
    max-width: 290px;
    width: 100%;
    line-height: 18px;
    margin-bottom: 5px;

    @media screen and (max-width: 1430px){
      max-width: 280px;
    }
    @media screen and (max-width: 1410px){
      max-width: 270px;
    }
    @media screen and (max-width: 1390px){
      max-width: 260px;
    }
    @media screen and (max-width: 1370px){
      max-width: 250px;
    }
    @media screen and (max-width: 1340px){
      max-width: 240px;
    }
    @media screen and (max-width: 1305px){
      max-width: 235px;
    }
    @media screen and (max-width: 1280px){
      max-width: 230px;
    }
    @media screen and (max-width: 1245px){
      max-width: 220px;
    }
    @media screen and (max-width: 1215px){
      max-width: 210px;
    }
    @media screen and (max-width: 1185px){
      max-width: 200px;
    }
    @media screen and (max-width: 1160px){
      max-width: 190px;
    }
    @media screen and (max-width: 1105px){
      max-width: 180px;
    }
    @media screen and (max-width: 1065px){
      max-width: 170px;
    }
    @media screen and (max-width: 1050px){
      max-width: 160px;
    }
    // large mobile screens
    @media screen and (max-width: 1025px){
      max-width: 290px;
    }
    @media screen and (max-width: 915px){
      max-width: 280px;
    }
    @media screen and (max-width: 905px){
      max-width: 270px;
    }
    @media screen and (max-width: 885px){
      max-width: 260px;
    }
    @media screen and (max-width: 870px){
      max-width: 250px;
    }
    @media screen and (max-width: 850px){
      max-width: 240px;
    }
    @media screen and (max-width: 830px){
      max-width: 230px;
    }
    @media screen and (max-width: 785px){
      max-width: 220px;
    }
    @media screen and (max-width: 765px){
      max-width: 210px;
    }
    @media screen and (max-width: 750px){
      max-width: 200px;
    }
    @media screen and (max-width: 730px){
      max-width: 190px;
    }
    @media screen and (max-width: 690px){
      max-width: 180px;
    }
    @media screen and (max-width: 660px){
      max-width: 170px;
    }
    @media screen and (max-width: 650px){
      max-width: 160px;
    }
    @media screen and (max-width: 615px){
      max-width: 150px;
    }
    @media screen and (max-width: 590px){
      max-width: 140px;
    }
    @media screen and (max-width: 570px){
      max-width: 130px;
    }
    @media screen and (max-width: 555px){
      max-width: 120px;
    }
  }
  &__enterprise-name.with-image {
    @include ellipsis-truncate;
    color: $label-color;
    font-weight: 700;
    font-size: 14px;
    width: 225px;
    line-height: 18px;
    margin-bottom: 5px;
  }

  &__sqft-and-price {
    @include ellipsis-truncate;
    padding-right: 5px;

    &_active {
      @include ellipsis-truncate;
      color: black;
      @media screen and (max-width: 1400px) {
        width: 260px;
      }
      @media screen and (max-width: 1350px) {
        width: 245px;
      }
      @media screen and (max-width: 1300px) {
        width: 230px;
      }
      @media screen and (max-width: 1250px) {
        width: 215px;
      }
      @media screen and (max-width: 1200px) {
        width: 200px;
      }
      @media screen and (max-width: 1150px) {
        width: 185px;
      }
      @media screen and (max-width: 1100px) {
        width: 180px;
      }
      @media screen and (max-width: 1000px) {
        width: 275px;
      }
    }

    &_pending {
      @include ellipsis-truncate;
      color: black;
      @media screen and (max-width: 1400px) {
        width: 250px;
      }
      @media screen and (max-width: 1350px) {
        width: 235px;
      }
      @media screen and (max-width: 1300px) {
        width: 205px;
      }
      @media screen and (max-width: 1250px) {
        width: 195px;
      }
      @media screen and (max-width: 1200px) {
        width: 180px;
      }
      @media screen and (max-width: 1150px) {
        width: 165px;
      }
      @media screen and (max-width: 1100px) {
        width: 160px;
      }
      @media screen and (max-width: 1000px) {
        width: 275px;
      }
    }

    &_sold,
    &_leased {
      @include ellipsis-truncate;
      color: black;
      @media screen and (max-width: 1400px) {
        width: 280px;
      }
      @media screen and (max-width: 1350px) {
        width: 270px;
      }
      @media screen and (max-width: 1300px) {
        width: 235px;
      }
      @media screen and (max-width: 1250px) {
        width: 220px;
      }
      @media screen and (max-width: 1200px) {
        width: 210px;
      }
      @media screen and (max-width: 1150px) {
        width: 190px;
      }
      @media screen and (max-width: 1100px) {
        width: 180px;
      }
      @media screen and (max-width: 1000px) {
        width: 275px;
      }
    }

    &_canceled,
    &_cancelled {
      @include ellipsis-truncate;
      color: black;
      @media screen and (min-width: 1400px) {
        width: 235px;
      }
      @media screen and (max-width: 1400px) {
        width: 235px;
      }
      @media screen and (max-width: 1350px) {
        width: 220px;
      }
      @media screen and (max-width: 1300px) {
        width: 205px;
      }
      @media screen and (max-width: 1250px) {
        width: 190px;
      }
      @media screen and (max-width: 1200px) {
        width: 180px;
      }
      @media screen and (max-width: 1150px) {
        width: 155px;
      }
      @media screen and (max-width: 1100px) {
        width: 130px;
      }
      @media screen and (max-width: 1000px) {
        width: 265px;
      }
    }

    &_expired {
      @include ellipsis-truncate;
      color: black;
      @media screen and (max-width: 1400px) {
        width: 235px;
      }
      @media screen and (max-width: 1350px) {
        width: 230px;
      }
      @media screen and (max-width: 1300px) {
        width: 215px;
      }
      @media screen and (max-width: 1250px) {
        width: 205px;
      }
      @media screen and (max-width: 1200px) {
        width: 190px;
      }
      @media screen and (max-width: 1150px) {
        width: 175px;
      }
      @media screen and (max-width: 1100px) {
        width: 160px;
      }
      @media screen and (max-width: 1000px) {
        width: 275px;
      }
    }

    .lot-sqft,
    .lot-price {
      font-size: 16px;
      line-height: 20px;
      color: $text-color;
    }

    .lot-sqft {
      position: relative;
      margin-right: 15px;

      &::after {
        content: '';
        width: 5px;
        height: 5px;
        background-color: #d2e1e4;
        border-radius: 50%;
        position: absolute;
        margin-left: 5px;
        top: 4px;
      }
    }
  }

  &__status-and-date {
    @include ellipsis-truncate;
    margin-top: 5px;

    color: $label-color;
    font-size: 12px;
    line-height: 15px;
  }

  &_active {
    .transaction-item {
      &__image {
        // design
        //background: rgba(246, 246, 246, 0.4);
        font-size: 0;

      }
      & > *,
      .lot-sqft,
      .lot-price {
        color: $main-color;
      }
    }
    background-color: $main-color-black !important;
  }
}
