#map-with-search {
   .gm-style-iw {
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
      border-radius: 3px;

      &.gm-style-iw-c {
         padding: 0;
         height: 100%;
         height: max-content;
         min-height: 57px;
      }

      &-d {
         margin: 0 -15px -12px 0;
      }
   }
}

.properties-info-window {
   display: flex;
   min-width: 110px;
   max-width: 210px;
   background-color: $main-color;
   box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
   border-radius: 3px;
   overflow: hidden;

   &__image {
      display: block;
      min-width: 57px;
      max-width: 57px;
      height: 57px;
      padding: 1px;
      border-radius: 2px 0px 0px 2px;

      img {
         width: 100%;
         height: 100%;
         object-fit: contain;
         border-radius: 2px 0px 0px 2px;
      }
   }

   &__description {
      padding: 5px 0 0 5px;
      min-width: 100px;
      max-width: 150px;

      .description__property-type {
         font-weight: 500;
         font-size: 12px;
         line-height: 15px;
         color: rgba($text-color, 0.5);
      }

      .description__property-address {
         margin-top: 3px;
         width: inherit;
         font-weight: bold;
         font-size: 12px;
         line-height: 15px;
         color: $main-color-black;
      }
   }
}