@import '../shared/styles/control.module.scss';

.plus_icon {
  position: absolute;
  right: -2px;
  top: 0;
  padding: 0 5px;
  cursor: pointer;
}

.error {
  font-size: 10px;
  padding-top: 2px;
  text-align: right;
  color: $error-color;
}

.building {
  margin-top: 10px;
}

.floor_container {
  display: flex;
}

.floor_index {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  padding: 0 5px;
  margin: 0 5px 5px 0;
  height: 22px;
  line-height: 25px;
  color: $main-color;
  background-color: $selection-color;
}

.floor_drag_icon {
  margin-right: 5px;
}

.floor_index__label {
  display: flex;
  align-items: center;
  color: $main-color;
}

.floor_title {
  margin-top: 2px;
}

.remove_floor {
  padding: 0 3px;
  position: relative;
  cursor: pointer;
}

.floor_square {
  @extend .input-base;

  width: 50%;
  margin-top: 6px;
  position: relative;
  top: -5px;
}
