@import "../../../../assets/scss/vars";

.tab{
  width: 100%;
  position: relative;
  margin-top: -5px;
  height: 100%;
  .empty-message{
    margin-top: 15px;
    margin-bottom: 30px;

    font-family: $AdobeCleanFont;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #8E8E8E;
    text-align: center;
  }
  .list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    list-style: none;
    &_title{
      margin-top: 15px;
      margin-bottom: 2px;
      padding-left: 5px;
      color: #fff;
    }
    &__item{
      padding: 5px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid rgba(#FFFFFF, .15);
      cursor: pointer;
      span{
        margin-left: 5px;
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #fff;
      }
    }
  }
}
