.notification {
  width: 255px;
  height: 45px;
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  align-items: center;
  transition: 0.55s opacity, 0.55s visibility;
  margin: 10px 0;
  &-cross-icon {
    padding: 15px;
    cursor: pointer;
  }
  &-title {
    padding: 10px;
    font-family: Adobe Clean;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 100%;
    line-height: 15px;
    color: #ffffff;
  }
  &-button {
    cursor: pointer;
  }
}

.indicator-progress {
  width: 0px;
  background-color: white;
  margin-top: -13px;
  width: 100%;
  height: 3px;
  border-radius: 0 0 3px 3px;
  animation: fill 5.5s linear 1;
}

@keyframes fill {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
