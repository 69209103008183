@import "./personal-contacts";
@import "./current-involvement";

#person-info-container {
   @extend .box-shadow-container;

   margin-bottom: 15px !important;

   .page-scrollbar-y {
      left: -12px !important;

      @include mq-tablet {
         left: -7px !important;
      }
   }
}

@media screen and (max-width: 650px) {
   .item-info__tab.info-tab.person-info-tab {
      flex-direction: column;

      .personal-contacts {
         margin-right: 0;
      }
   }
}