@import '../../../../assets/scss/vars';
@import '../../../../assets/scss/mixins';
@import '../../../../assets/scss/components/UI/button';

.container {
  position: relative;
}

.container_card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & > div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.container_small {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.toggle_options_menu {
  @extend .button-toggle-light;
  position: relative;
  z-index: 0;

  width: 41px !important;
  height: 24px !important;

  background: rgba($main-color, 0);

  &:disabled {
    .button__dot {
      background: $disabled-color;
    }
    cursor: not-allowed;
  }

  &_active {
    background: rgba($label-color, 0.1);
    border-color: $label-color;
  }
}

.button__dots {
  position: absolute;
  z-index: 0;
  display: flex;
  top: 13px;
  left: 15px;
  cursor: pointer;
  &:hover{
    .button__dot{
      background: #4265AB;
    }
  }
}

.button__dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: rgba($main-color-black, 0.6);
  transition: .3s;
  &:not(:last-child) {
    margin-right: 3px;
  }
}

.options_container {
  display: block;
  width: 100%;


  @include mq-phone {
    display: none;
  }
}

.options_container_phone {
  display: none;

  @include mq-phone {
    display: block;
  }
}

.options {
  position: absolute;
  z-index: 3;
  margin-top: 2px;
  right: 0;
  width: 125px;

  border-top: none;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.option,
.option_close {
  display: flex;
  line-height: 15px;

  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: $main-color-black;
  background-color: $main-color;
  border: 1px solid rgba($text-color, 0.1);

  &:hover {
    color: $main-color;
    background-color: $main-color-black;
    cursor: pointer;

    span {
      color: $main-color;
    }

    svg path {
      fill: $main-color;
    }
  }

  span {
    line-height: 15px;
    padding-top: 9px;
    padding-bottom: 6px;
  }

  &:not(:last-child) {
    border-bottom: none;
  }

  &:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  &:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}

.option_close {
  display: none;

  @include mq-phone {
    display: flex;
  }
}

.option_icon {
  @include flex-center;
  height: 30px;
  width: 30px;
}

.close_icon {
  font-size: 24px;
  line-height: 20px;
  padding: 15px 13px;
}

@include mq-phone {
  .options {
    position: fixed;
    width: 100%;
    bottom: 0;
    border: none;
  }

  .option,
  .option_close {
    height: 50px;
    font-size: 16px;

    span {
      line-height: 20px;
      padding-top: 16px;
      padding-bottom: 15px;
    }
  }

  .option_icon {
    width: 40px;
    height: 50px;
  }
}

.backdrop_overlay {
  @include mq-phone {
    @include backdrop-overlay;
  }
}
