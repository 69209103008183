.modal-gallery {
   margin-top: -30px;

   &-label {
      display: block;
      color: $label-color;
      font-family: $OswaldFont;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 6px;
      text-transform: uppercase;
   }

   &-images-container {
      @include clearfix;

      display: flex;
      flex-wrap: wrap;

      @include mq-phone {
         display: block;
      }
   }

   &-upload, .upload-label, .upload-icon {
      @include flex-center;
   }

   .upload-label {
      width: 108px;
      height: 108px;
      flex-direction: column;
      cursor: pointer;
   }

   .upload-icon {
      width: 30px;
      height: 30px;
      background-color: $main-color-black;
      border-radius: 50%;

      svg {
         position: relative;
         bottom: 1px;
      }
   }

   .upload-hint {
      margin-top: 5px;
      font-family: $OswaldFont;
      font-size: 10px;
      text-transform: uppercase;
      color: $main-color-gray;
   }

   &__image {
      position: relative;
      margin-bottom: 15px;

      &:first-child {
         border: 1px solid #EAEEF3;
      }

      @include mq-phone {
         float: left;
         margin-right: 10px;
      }

      @media screen and (min-width: $phone-screen) {
         &:not(:nth-child(5n)) {
            margin-right: 15px;
         }
      }

      width: 108px;
      height: 108px;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }

      .image-mask {
         @include flex-center;

         position: absolute;
         left: 0;
         top: 0;
         right: 0;
         bottom: 0;  

         &:hover {
            background: rgba(0, 0, 0, 0.25);
            .image-controls {
               display: flex;
            }
         }
      }

      .main-image-label {
         width: 54px;
         height: 13px;
         position: absolute;
         bottom: 0;
         left: 0;
         color: $main-color;
         font-size: 10px;
         line-height: 15px;
         padding-right: 4px;
         background: rgba($main-color-black, 0.75);
      }

      .image-controls {
         display: none;
         flex-direction: column;
         align-items: center;
         justify-content: center;

         &__make-as-main-btn {
            @include flex-center;
            width: 80px;
            height: 21px;
            margin-top: 8px;
            color: $main-color;
            font-weight: bold;
            font-size: 10px;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
            background: rgba($main-color-black, 0.75);
            border: 0.5px solid $main-color;
            border-radius: 2px;
            cursor: pointer;
         }
      }

      .icon {
         display: inline-block;
         width: 21px;
         height: 21px;
         background-color: rgba($main-color-black, 0.75);
         border: 1px solid $main-color;
         border-radius: 50%;
         text-align: center;
         cursor: pointer;

         svg {
            vertical-align: middle;
         }
      }

      .trash-icon {
         margin-left: 5px;
      }
   }
}
