.note-creator__actions-list {
  position: absolute;
  background: $main-color;
  box-shadow: 0px -7px 25px rgba(0, 0, 0, 0.15);
  border-radius: 3px 3px 0 0;
  bottom: 100%;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.actions-list {
  order: -1;
  &__alert{
    display: flex;
    align-items: flex-start;
    background: #EAEEF3;
    border-radius: 3px;
    padding: 15px;
    span{
      font-family: $AdobeCleanFont;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: #1F2531;
      opacity: 0.5;
      display: inline-block;
      margin-right: 10px;
    }
    ul{
      margin: 0;
      padding-left: 24px;
      li{
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: #1F2531;
        opacity: 0.5;
        list-style: disc;
      }
    }
  }
  &__header {
    border-radius: 3px;
    padding: 15px 15px;
    &.with-shadow{
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.03);
    }
    &.with-arrow{
      padding: 5px;
    }
    &.with-tabs{
      padding: 15px 15px 8px;
    }
    .tabs{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .tab{
        font-family: $OswaldFont;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        color: #AEB4BE;
        transition: .3s;
        cursor: pointer;
        &:not(:last-child){
          margin-right: 15px;

        }
        &.selected{
          color: #1F1F1F;
        }
      }
    }

    &--row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &--back-arrow {
      margin-right: 5px;
      cursor: pointer;
    }

    h5.heading{
      font-family: $OswaldFont;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      text-transform: uppercase;
      color: #8e8e8e;
    }
    h4.heading {
      font-family: $OswaldFont;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: $text-color;
      text-transform: uppercase;
    }

    p.tip {
      margin-top: 5px;
      font-size: 12px;
      line-height: 15px;
      color: $main-color-gray;
    }
    .tag-creation-button {
      top: 13px;
      right: 15px;
      border: none;
      width: 100px;
      height: 30px;
      cursor: pointer;
      position: absolute;
      background: #ffffff;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
      border-radius: 2px;
      font-family: $AdobeCleanFont;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
      color: #1F2531;
    }
  }

  &__scrollbars {
    & > div{
      margin-bottom: -15px!important;
    }
    .page-scrollbar-y {
      left: -1px;
    }
  }

  &__content {
    padding: 10px;
    background: #F6F6F6;
    min-height: 100%;
    .placeholder{
      text-align: center;
      color: #999;
      margin-top: 35px;
      display: flex;
      align-items: center;
      flex-direction: column;
      h3{
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #AEB4BE;
        margin-bottom: 10px;
        margin-top: 0;
      }
      p{
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
        text-align: center;
        color: #AEB4BE;
        margin: 0;
        max-width: 375px;
      }
    }
    .info{
      font-family: $AdobeCleanFont;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 19px;
      text-align: center;
      color: #AEB4BE;
      margin-top: 20px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;
    overflow: hidden;

    min-height: 48px;
    border-radius: 3px;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: $text-color;
    text-transform: capitalize;
    border: 1px solid $main-color;
    outline: none;

    cursor: pointer;

    &:not(:last-child){
      margin-bottom: 10px;
    }

    .transaction-item-container{
      margin: 0;
      border: none;
    }
    .involvement-history__item{
      margin: 0;
    }

    &-header{
      display: flex;
      margin-bottom: 10px;
    }
    &-start{
      background: #FFFFFF;
      padding: 10px;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      & ~ .actions-list__item-wrapper {
        background: #FFFFFF;
        padding-left: 10px;
        padding-right: 10px;
      }
      & ~ .actions-list__item-end {
        margin-bottom: 10px;
      }
      .actions-list__item{
        margin-bottom: 0;
      }
      &.actions-list__item-disabled{
        .actions-list__item{
          background: transparent;
          border: none;
          box-shadow: none;
          cursor: default;
        }
      }
    }
    &-wrapper {
      padding-bottom: 10px;
      .actions-list__item {
        margin-bottom: 0;
        background: #fff;
      }
    }
    &-end{
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }

    &:hover {
      border: 1px solid #1F2531;
    }

    &.category {
      max-height: 70px;
    }


    &-image {
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
        border-right: 1px solid transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        border-radius: 3px 0px 0px 3px;
      }
    }
    &-information {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 12px;
      flex: 1 auto;
      overflow: hidden;
      .category {
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: rgba(31, 31, 31, 0.5);

        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .title {
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: $text-color;

        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .subtitle{
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;

        color: $text-color;

        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &_active {
      border: 1px solid $main-color-black;
    }
    &.disabled{
      background: #fafafa;
      cursor: default;
      .title{
        color: #999;
      }
    }
    &--already-added-mark{
      position: absolute;
      top: 3px;
      right: 3px;
      font-size: 10px;
      color: #999;
    }
  }
  .entity{
    & > .title{
      margin-top: 15px;
      margin-bottom: 10px;
      font-family: $AdobeCleanFont;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #8E8E8E;
    }
  }
}
