#enterprise-involvements-scrollbars {
  @extend .box-shadow-container;

  .page-scrollbar-y {
    left: 7px !important;
  }

  @include mq-phone {
    & > div {
      padding-right: 10px !important;
    }
  }
}

.enterprise-involvements {
  display: flex;
  flex-direction: column;

  width: 45%;
  max-width: 365px;
  margin-right: 15px;

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .involvements-filters {
    height: 18px;
    font-weight: 500;
    font-size: 12px;
    color: rgba($text-color, 0.2);
    text-transform: uppercase;

    &__toggle {
      font-family: $OswaldFont;
      padding-right: 10px;
      line-height: 18px;
      cursor: pointer;

      &:hover,
      &_active {
        color: $text-color;
        transition: $transition-in;
      }
    }
  }

  @include mq-tablet {
    min-width: 285px;
    max-width: 285px;
    margin-right: 30px;

    .button-add-new {
      margin-right: -15px;
    }

    #enterprise-involvements-scrollbars {
      margin-right: -30px;

      & > div {
        padding-right: 15px !important;
      }
    }
  }

  @include mq-phone {
    min-width: 100%;
    min-height: 100%;
    padding-left: 15px;
    padding-right: 30px;
  }
}

.enteprise-involvements-list__item {
  @extend .data-item;
  height: unset !important;

  .item-summary {
    padding: 8px 10px;
    font-weight: 500;
    &__post {
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      color: $label-color;
      margin-bottom: 5px;
    }

    &__employee {
      font-size: 16px;
      color: $text-color;
      line-height: 20px;
      padding-bottom: 2px;

      .employee-position {
        position: relative;
        line-height: 20px;
      }

      .employee-name {
        line-height: 20px;
      }
    }

    &__date {
      padding-top: 5px;
      line-height: 15px;
      font-size: 12px;
      color: $label-color;
    }
  }

  &_active {
    .item-summary {
      &__employee,
      &__date,
      .employee-position,
      .employee-name {
        color: $main-color;
      }
    }
    background-color: $main-color-black !important;
  }
}
