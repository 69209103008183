.note-creator__control-list {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #EAEEF3;
  box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  &_items{
    .actions-list__item{
      background: #fff;
      &-wrapper{
        &:last-child{
          padding-bottom: 0;
        }
      }
    }
  }
}