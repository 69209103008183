.transaction-stack {
  background: $main-color;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 10px;

  .information {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .floor-title {
    margin-right: 10px;
    width: 35px;
    font-family: $AdobeCleanFont;
    font-style: normal;
    overflow: hidden;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: $text-color;
    vertical-align: top;
    &::after {
      transition: 0.3s;
      top: 0;
      right: 0;
      height: 100%;
      content: '';
      box-shadow: 25px 0 20px 35px #ffffff;
    }
  }

  .suites {
    width: 100%;
    &.background {
      width: 100%;
      height: 30px;
      background: rgba(223, 223, 223, 0.2);
      border-radius: 2px;
    }
    .suites-row {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .suite {
      width: 100%;
      display: inline-block;
      background: rgba($main-color-gray, 0.2);
      border-color: $main-color-gray;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      white-space: nowrap;
      box-sizing: border-box;

      &:hover {
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 14px;
        background: linear-gradient(
          270deg,
          #ebecec 27.08%,
          rgba(235, 236, 236, 0) 100%
        );
      }

      &-area {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;

        &:not(:first-child) {
          margin-left: 4px;
        }
      }

      &-inner {
        padding: 5px;
        display: inline-block;
        border-color: inherit;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid;
          border-color: inherit;
          box-sizing: border-box;
          z-index: 3;
        }
      }

      &-counts {
        opacity: 0.3;
      }

      &.will-be-leased {
        background: $button-light-hover-color;
        border-color: $button-light-active-color;

        &::after {
          background: linear-gradient(
            270deg,
            $button-light-hover-color 27.08%,
            rgba($button-light-hover-color, 0) 100%
          );
        }
      }

      &.leased {
        background: rgba($link-color, 0.2);
        border-color: rgba($link-color, 0.5);

        &::after {
          background: linear-gradient(
            270deg,
            #e8e1ce 27.08%,
            rgba(#e8e1ce, 0) 100%
          );
        }
      }

      &.overlap {
        &::before {
          content: '';
          transform-origin: top right;
          transform: rotate(45deg);
          position: absolute;
          top: 0;
          right: -5px;
          width: 50px;
          height: 3px;
          border-top: 1px solid;
          border-bottom: 1px solid;
          border-color: inherit;
          margin-top: 12px;
          z-index: 2;
        }
      }

      &.hovered {
        border-color: $main-color-black;
      }
      &.selected {
        background: $main-color-black;
        border-color: $main-color;

        .suite-inner {
          color: $main-color;
        }

        &::after {
          background: linear-gradient(
            270deg,
            $main-color-black 27.08%,
            rgba($main-color-black, 0) 100%
          );
        }
      }

      &.disabled {
        opacity: 0.15;
      }

      &-wrap {
        position: relative;
        // transition: z-index .3s .3s;
        z-index: 1;
        padding-right: 5px;
        min-width: 37px;

        &:hover {
          z-index: 10;
          // transition: z-index 0s 0s;

          .tooltip-content {
            opacity: 1;
          }
        }
        .tooltip {
          &-content {
            position: absolute;
            top: 100%;
            width: 180px;
            background: $lease-visualization-bg;
            border-radius: 1px;
            padding: 5px;
            pointer-events: none;

            font-family: $OswaldFont;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: $main-color;

            opacity: 0;
            // transition: .3s;

            &__block {
              font-family: inherit;

              &:not(:last-child) {
                margin-bottom: 8px;
                padding-bottom: 10px;
                border-bottom: 1px solid $main-color;
              }
            }

            &__row {
              display: flex;
              justify-content: flex-start;
              font-family: inherit;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
              text-transform: uppercase;

              &:not(:last-child) {
                margin-bottom: 2px;
              }

              &-title {
                color: rgba($main-color, 0.5);
                font-family: inherit;
              }

              &-content {
                color: $main-color;
                font-family: inherit;
                margin-left: 2px;

                &.active {
                  color: $listing-status_active;
                }

                &.pending {
                  color: $listing-status_pending;
                }

                &.sold,
                &.leased {
                  color: $listing-status_sold;
                }

                &.canceled,
                &.cancelled {
                  color: $listing-status_canceled;
                }

                &.expired {
                  color: $listing-status_expired;
                }
              }

              &_date {
                .tooltip-content__row-content {
                  color: $listing-status_leased;

                  time {
                    font-family: inherit;
                  }
                }
              }
            }

            &::before {
              content: '';
              position: absolute;
              bottom: 100%;
              left: 15px;
              transform: translateX(-50%);
              border-style: solid;
              border-color: transparent transparent $lease-visualization-bg
                transparent;
              border-width: 5px 8px;
            }
          }

          &-icon {
            height: 12px;
            display: inline-block;
          }

          &:hover {
            .tooltip-content {
              opacity: 1;
              margin-top: 9px;
            }
          }
        }
      }
    }
  }

  &-table {
    .floor {
      &.without-floor {
        .line {
          width: 100%;
          border-top: 1px solid #f3f3f3;
          margin: 15px 0;
        }
      }
    }
    display: flex;
    flex-direction: column;
    width: 100%;
    table-layout: fixed;
  }
}

.legend {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 3px;
  margin-bottom: 15px;

  &-row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    min-width: fit-content;
    margin-bottom: 11px;
    position: relative;

    &:hover {
      z-index: 20;

      .tooltip {
        z-index: 3;
      }
    }

    .tooltip {
      position: relative;
      margin-left: 5px;
      display: inline-block;
      height: 16px;

      &-content {
        position: absolute;
        top: 100%;
        left: 50%;
        width: 180px;
        transform: translateX(-50%);
        background: $lease-visualization-bg;
        border-radius: 1px;
        padding: 5px;
        pointer-events: none;

        color: $main-color;
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;

        opacity: 0;
        transition: 0.3s;

        &::before {
          content: '';
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-style: solid;
          border-color: transparent transparent $lease-visualization-bg
            transparent;
          border-width: 5px 8px;
        }
      }

      &-icon {
        height: 12px;
        display: inline-block;
      }

      &:hover {
        .tooltip-content {
          opacity: 1;
          margin-top: 9px;
        }
      }
    }

    .icon {
      margin-right: 4px;
      width: 15px;
      height: 15px;
      border-radius: 2px;
      position: relative;
      overflow: hidden;
      margin-bottom: 3px;
    }

    &.leased {
      .icon {
        background: rgba($link-color, 0.2);
        border: 1px solid rgba($link-color, 0.5);
      }
    }

    &.available {
      .icon {
        background: rgba($main-color-gray, 0.2);
        border: 1px solid $main-color-gray;
      }
    }

    &.will-be-leased {
      min-width: fit-content;
      .icon {
        background: $button-light-hover-color;
        border: 1px solid $button-light-active-color;
      }
    }

    &.overlap {
      .icon {
        border: 1px solid $main-color-gray;

        &::before {
          content: '';
          transform: translate(-50%, -50%) rotate(45deg);
          position: absolute;
          top: 50%;
          left: 50%;
          width: 200%;
          border-top: 1px solid;
          border-color: inherit;
        }

        &::after {
          content: '';
          transform: translate(-50%, -50%) rotate(45deg);
          position: absolute;
          top: 50%;
          left: 50%;
          width: 200%;
          margin-top: -5px;
          border-top: 1px solid;
          border-color: inherit;
        }
      }
    }

    font-family: $AdobeCleanFont;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $text-color;
  }

  p {
    font-family: $AdobeCleanFont;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    color: $text-color;
  }
}
