@import '../../vars';

.history-card {
  &.optional {
    @include ellipsis-truncate;
  }
  width: 425px;
  border-radius: 3px;
  margin-bottom: 10px;
  background: $history-card-background;
  &.activity {
    margin-left: 0;
    margin-bottom: 0;
    background: none;
    padding: 0 10px;
    .information {
      padding: 10px 0 0 0;
      margin: 0 10px;
      border-bottom: none;
      justify-content: normal;
      border-top: 1px solid rgba(31, 31, 31, 0.1);
      align-items: center;
      .person-image,
      .initials {
        min-width: 32px;
        max-width: 100%;
        height: 32px;
        display: flex;
        border-radius: 77px;
        align-items: center;
        box-sizing: border-box;
        justify-content: center;
        border: 1px solid #f7f7f7;
        object-fit: cover;
      }
      .details {
        @include ellipsis-truncate;
        width: 100%;
        padding-left: 10px;
        padding-right: 5px;
        font-family: Adobe Clean;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 19px;
        color: #1f1f1f;
        .action {
          font-weight: normal;
        }
        .entity {
          text-transform: capitalize;
        }
      }
      .date-added {
        white-space: nowrap;
        font-family: Adobe Clean;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        text-align: right;
        color: rgba(31, 31, 31, 0.4);
      }
    }
    .action-details {
      margin-left: 52px;
      margin-bottom: 10px;
      .title {
        font-family: Adobe Clean;
        font-style: normal;
        display: flex;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        text-transform: capitalize;
        color: rgba(31, 31, 31, 0.3);
      }

      .replaced {
        .title-address {
          display: flex;
        }
        &.tag {
          .action-tag {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            flex-direction: column;
            margin-bottom: 4px;
            width: fit-content;
            .tooltip {
              position: absolute;
              display: inline-block;
              z-index: 3;
              &-content {
                display: none;
                position: absolute;
                width: max-content;
                padding: 5px 2px;
                height: 20px;
                background: #373737;
                border-radius: 1px;
                font-family: Adobe Clean;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                left: -17px;
                top: 20px;
                line-height: 15px;
                color: #ffffff;
                align-items: center;
                justify-content: center;
                opacity: 0;
                transition: 0.3s;
                &::before {
                  content: '';
                  position: absolute;
                  bottom: 100%;
                  border-style: solid;
                  border-color: transparent transparent $lease-visualization-bg
                    transparent;
                  border-width: 5px 8px;
                }
              }
            }
            &:hover {
              .tooltip-content {
                opacity: 1;
                margin-top: 3px;
                display: flex;
              }
            }
          }
        }

        &.field {
          .actions {
            display: flex;
            align-items: center;
            margin-top: 3px;
            &.column {
              flex-direction: column;
              align-items: flex-start;
            }
            &.column .action {
              flex-direction: column;
              .field {
                height: 100%;
                width: fit-content;
              }
            }
            &-address-field {
              @include ellipsis-truncate;
              display: flex;
              justify-content: flex-start;
              margin-bottom: 5px;
              &.old {
                .address {
                  @include ellipsis-truncate;
                  text-decoration-line: line-through;
                }
                .address-additional {
                  @include ellipsis-truncate;
                  text-decoration-line: line-through;
                }
              }
              &.new {
                background: #fcf7ca;
              }
              .address {
                @include ellipsis-truncate;
                width: 200px;
              }
              .address-additional {
                @include ellipsis-truncate;
                margin-left: 20px;
                width: 55px;
              }
            }
            .action {
              display: flex;
              align-items: flex-start;
              font-family: Adobe Clean;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              color: #1f1f1f;
              margin-bottom: 4px;
              &:last-child {
                margin-bottom: 0;
              }
              &-item {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                &.old {
                  .field {
                    text-decoration-line: line-through;
                    margin-right: 5px;
                  }
                }
                &.new {
                  .field {
                    background: #fcf7ca;
                  }
                }
                .field {
                  cursor: pointer;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-wrap: nowrap;
                  flex-direction: column;
                  font-size: 14px;
                  padding: 2px;
                  margin-bottom: 7px;
                  .tooltip {
                    position: absolute;
                    display: inline-block;
                    z-index: 3;
                    &-content {
                      display: none;
                      position: absolute;
                      width: max-content;
                      padding: 5px 2px;
                      height: 20px;
                      background: #373737;
                      border-radius: 1px;
                      font-family: Adobe Clean;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 12px;
                      left: -17px;
                      top: 15px;
                      line-height: 15px;
                      color: #ffffff;
                      align-items: center;
                      justify-content: center;
                      opacity: 0;
                      transition: 0.3s;
                      &::before {
                        content: '';
                        position: absolute;
                        bottom: 100%;
                        border-style: solid;
                        border-color: transparent transparent
                          $lease-visualization-bg transparent;
                        border-width: 5px 8px;
                      }
                    }
                  }
                  &:hover {
                    .tooltip-content {
                      opacity: 1;
                      margin-top: 3px;
                      display: flex;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .information {
    display: flex;
    line-height: 18px;
    padding: 10px 15px;
    font-style: normal;
    flex-direction: row;
    font-family: Adobe Clean;
    font-size: 14px !important;
    justify-content: space-between;
    border-bottom: 1px solid rgba(31, 31, 31, 0.1);
    .data {
      padding: 0;
      width: 335px;
      display: flex;
      &-link {
        font-size: 14px !important;
        text-transform: capitalize;
      }
      overflow-x: hidden;
    }
    .status {
      font-weight: bold;
      color: rgba(31, 31, 31, 0.7);
    }
    .hours {
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: right;
      color: rgba(31, 31, 31, 0.5);
    }
  }
  .data {
    .creation {
      text-transform: capitalize;
    }
    .check {
      margin-right: 6px;
    }
    &:hover {
      .history {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    @include ellipsis-truncate;
    padding: 10px 15px;
    cursor: pointer;
    font-family: Adobe Clean;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(31, 31, 31, 0.5);
    line-height: 22px;
    .history {
      position: absolute;
      right: 9%;
      margin-top: -26px;
      width: 30px;
      height: 30px;
      display: none;
      background: $main-color;
      border: 1px solid $button-dark-disabled-color;
      box-sizing: border-box;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      &:hover {
        background: $main-color;
        border: 1px solid $label-color;
        box-sizing: border-box;
        border-radius: 2px;
      }
    }
    .time {
      font-weight: bold;
    }
  }
  .reminder-list{
    &.old{
      .reminder-information{
        text-decoration: line-through;
      }
    }
    &.new{
      .reminder-information{
        background: #FCF7CA;
        opacity: 1;
      }
    }
  }
}
