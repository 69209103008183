.phones-dropdown {
    width: 90px;
    height: 24px;
    background-color: 'red';
    border-bottom: 1px solid rgb(240, 233, 233);
    cursor: pointer;
    display: flex;
    flex-flow: row;

    &:hover{

        border-bottom: 1px solid $unerline-color;
    }

    &-list {
        position: absolute;
        top: 32px;
        flex-flow: column;
        width: 70px;
        min-height: 20px;
        z-index: 9;
        box-shadow: 2px 5px 16px 0px $scrollbar-color;
        background-color: $text-color-light;

        &-item {
            padding: 6px;
            cursor: pointer;

            &:hover {
                background-color: $button-dark-active-color;
                color: $text-color-light;
            }
        }
    }
}

.icon {

    &-dr {

        &-container {
            margin-left: 8px;
            justify-content: center;
            align-items: center;
            display: flex;
        }
    }
}

.dr-active {
    border-bottom: 1px solid $unerline-color;
}