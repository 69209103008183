@import '../mixins';
@import '../vars';

$size: 30px;

%loader-container-base {
  background: $loader-container-bg;
  border-radius: 3px;
}

%loader-container-full {
  @extend %loader-container-base;
  @include flex-center;
  .loader-wrapper {
    margin: auto;
  }
}

.items-container__loader {
  @extend %loader-container-full;
  height: 100%;
}

.items-container__loader {
  @extend %loader-container-base;
  margin: 0 20px;
  height: 100%;
}

.involvement-items-container__loader {
  @extend %loader-container-full;
  height: 100%;
}

.enterprise-involvements-container__loader,
.sale-transactions-container__loader,
.lease-transactions-container__loader {
  @extend %loader-container-full;
  margin: 0;
  height: 100%;
}

.items-list__loader {
  @include flex-center;
  height: 60px;
}

.item-info__loader_user-info {
  @extend %loader-container-full;
  height: 100%;
  margin: 15px 0;
}
.item-info__loader_tag-info {
  @extend %loader-container-full;
  height: 100%;
  margin: 15px 0;
}

.item-info__loader_property-info {
  @extend %loader-container-full;
  margin: 0 20px 15px 0;
  height: 100%;
}

.item-info__loader_user,
.item-info__loader_tag,
.item-info__loader {
  @extend %loader-container-base;
  @include flex-center;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.item-info__loader {
  background-color: #fff;
  border-radius: 3px;
}

.item-info__loader_info-tab {
  @extend %loader-container-base;
  @include flex-center;
  height: 500px;
}

.item-info__loader_properties {
  @include flex-center;
}

.item-info__loader_person {
  @extend .item-info__loader_user;

  border-right: 20px solid $page-bg-color;
  border-bottom: 15px solid $page-bg-color;
  border-radius: 3px;
}

.item-info__loader_enterprise,
.item-info__loader_property {
  @extend .item-info__loader_person;

  margin-left: 25px;
  width: 100%;
  width: calc(100% - 50px);
}

.loader-info {
  @extend %loader-container-base;
  @include flex-center;
  height: 100%;
}

.empty-container-wrapper {
  width: 100%;
  height: 500px;
  margin-top: 17px;
}

.empty-container-wrapper_user {
  @extend .empty-container-wrapper;
  padding-right: 0;
}

.empty-container {
  @extend %loader-container-base;
  width: 98%;
  height: 500px;

  @include mq-phone {
    display: none;
  }
}

/* LOADER itself */

.loader-wrapper {
  width: $size;
  height: $size;
  display: inline-block;
  overflow: hidden;
  &-map{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255,255,255, .85);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-100vw);
    opacity: 0;
    transition: opacity .3s, transform 0s .3s;
    &.show{
      transition: opacity .3s, transform 0s 0s;
      opacity: 1;
      transform: translateX(0);
    }
  }
}


.loader div {
  position: absolute;
  animation: loader-animation 0.89s linear infinite;
  width: $size * 0.8;
  height: $size * 0.8;
  top: $size * 0.1;
  left: $size * 0.1;
  border-radius: 50%;
  box-shadow: 0 $size * 0.052 0 0 $label-color;
  transform-origin: $size * 0.4 $size * 0.426;
  box-sizing: content-box;
}

.loader {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  &-container {
    height: 500px;
    display: flex;
    margin-top: 12px;
    align-items: center;
    justify-content: center;
    width: calc(100% - 20px);
    background-color: #eaeef3;
  }
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
