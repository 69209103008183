@import '../_vars';

.modal {
  @include flex-center;

  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);

  &__close {
    @include times-icon(16px, 2px, $text-color);

    padding: 12.5px;
    position: absolute !important;
    z-index: 1;
    top: 10px;
    right: 10px;
  }

  &-title {
    color: $modal-title-color;
    font-family: $OswaldFont;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 25px;
    &-delete {
      font-family: Oswald;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      color: #232323;
    }
  }

  &-subtitle-delete {
    margin-top: 10px;
    margin-bottom: 15px;
    font-family: Adobe Clean;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    width: 280px;
    color: #8e8e8e;
    p {
      margin-bottom: 10px;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    position: relative;
    display: inline-block;
    text-align: left;
    background-color: $main-color;
    padding: 20px 25px 0 25px;
    border-radius: 3px;
    margin: auto;
    &__loader {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.7);
      z-index: 9999;
      .loader-wrapper {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &-close {
    position: absolute !important;
    right: 10px;
    top: 10px;
    cursor: pointer;

    &:active {
      outline: 1px solid $text-color;
    }
  }

  &-section-heading {
    display: block;
    color: $modal-title-color;
    font-family: $OswaldFont;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    margin-bottom: 25px;
    &.delete {
      justify-content: center;
    }
    button:first-child {
      margin-right: 15px;
    }
  }

  &-overlay {
    @include backdrop-overlay;
    z-index: 1;
    left: -($navigation-width-desktop);
  }

  @include mq-tablet {
    left: 0;

    &-overlay {
      left: 0;
    }
  }

  @include mq-phone {
    &-overlay {
      background-color: $main-color;
    }
  }
}

.modal-person{
  .googleBloc{
    margin-bottom: 10px;
  }
}
.flex-container {
  margin-left: 2px;
  margin-top: 2px;
  display: flex;
  align-items: top;

  & > div:first-child {
    margin-right: 40px;
  }

  .row{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.tag-field {
  margin-bottom: 30px;
  & > div {
    max-width: 100%;
  }
}

.taxes-field {
  display: flex;
  align-items: flex-end;
  .field {
    &:first-child {
      margin-right: 10px;
    }
    width: 49%;
    & > div {
      min-width: initial;
    }
  }
  &_label {
    margin-bottom: 7px;
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: #627d7a;
  }
}

.checkboxes-container {
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
}

.popup {
  .modal-content {
    min-width: 330px;
    margin: auto;
  }
  @include mq-phone {
    top: 0;
  }
}

.popup-password-change {
  footer {
    margin-top: 25px;
  }
}

.modal-team {
  footer {
    margin-top: 170px;
  }
}

.popup-password-change,
.modal-building,
.popup-delete,
.popup-edit-transaction,
.popup-full-image,
.popup-create-property-type,
.popup-create-taxonomy {
  z-index: 20;

  .modal-content {
    width: unset !important;
  }
}

.modal-property .modal-content {
  /* setting explicit width because of gallery in bottom */
  width: 650px;

  .flex-container {
    margin-top: 2px;
    margin-left: 2px;
    margin-bottom: 30px;
  }

  @include mq-phone {
    width: 100%;
  }
}

.modal-sale-transaction,
.modal-lease-transaction {
  .modal-content .flex-container {
    margin-top: 2px;
    margin-left: 2px;
    margin-bottom: 30px;
  }
}

.popup-delete {
  .modal-content {
    width: 350px !important;
  }

  &__title {
    text-align: center;
    text-transform: uppercase;
    font-family: $OswaldFont;
    font-weight: 500;
    font-size: 14px;
  }

  &__content {
    text-align: center;
    font-size: 14px;
    margin: 25px 0;
  }

  &__footer {
    justify-content: center;
  }
}

.popup-edit-transaction {
  &__close {
    @include times-icon(13px, 1px, $text-color);

    padding: 12px;
    position: absolute !important;
    z-index: 1;
    top: 0;
    right: 0;
  }

  &__text {
    font-family: $OswaldFont;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;

    margin: 25px 0;
  }

  .modal-content {
    width: 330px !important;
    padding-top: 0;
    border-radius: 3px;
  }

  .modal-footer {
    justify-content: center;
  }
}

.popup-full-image {
  &__close {
    @include times-icon(20px);

    padding: 18px;
    position: absolute !important;
    z-index: 5;
    top: 25px;
    right: 25px;
  }

  .modal-content {
    background-color: transparent;
    padding: 0;

    .modal-close {
      position: fixed;
      top: 30px;
      right: 30px;
    }

    svg path {
      fill: $main-color;
    }
  }

  .preview-container {
    img {
      max-width: 80vw;
      max-height: 100vh;
    }
  }
}

@include mq-phone {
  .modal .scroll-area {
    padding: 0;
    background-color: $main-color;
  }

  .flex-container {
    margin-top: 2px;
    margin-left: 2px;
    flex-direction: column;

    & > div:first-child {
      margin-right: 0;
    }
  }

  .modal-content {
    width: 100%;
    min-height: 100vh;
    padding: 20px 15px 40px 15px;
  }

  .modal-footer {
    position: fixed;
    z-index: 4;
    right: 15px;
    bottom: 0;
    margin-bottom: 0;
    width: 100%;
    padding: 15px 0;
    background: rgba($main-color, 0.9);
  }

  .modal-team {
    .modal-footer {
      margin-top: 5px;
    }
  }
}

.export-modal.modal-content{
  width: 390px;
  padding: 17px 25px 25px;
  .error-message{
    text-align: left;
    color: #f00;
    //margin-top: 15px;
    margin-bottom: 15px;
  }
  .loader-full-modal{
    background: rgba(255,255,255, .7);
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button-dark{
    padding: 5px 10px;
    font-family: $AdobeCleanFont;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  .modal-footer{
    margin-bottom: 0;
  }
}

.export-table{
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 25px;
  a{
    text-decoration: none;
    color: #1F1F1F;
    transition: .3s;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0);
    &:hover{
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
    }
  }
  .row{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    &.error{
      .column{
        &:nth-child(3){
          opacity: 0;
        }
        &.name{

          color: #F10707;
        }
      }
    }
  }
  .table-head{
    margin-bottom: 10px;
    .column{
      font-family: $OswaldFont;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
      color: #1F1F1F;
      border-bottom: 1px solid #DCDEDF;
      padding: 5px 5px 10px;
    }
  }
  .column{
    display: flex;
    align-items: center;
    width: 100%;
    &:nth-child(3){
      width: 115px;
      flex-shrink: 0;
    }
    &:nth-child(1){
      width: 25px;
      padding: 0;
      flex-shrink: 0;
    }

  }
  .table-body{
    .row{
      align-items: stretch;
      .column{
        &:nth-child(1) {
          justify-content: center;
          padding-top: 2px;
          align-items: flex-start;
          .small-loader{
            margin-top: 8px;
          }
        }
        &:nth-child(3) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding-top: 7px;
        }
      }
    }
    .row:nth-child(odd){
      .column{
        background: rgba(#F5F5F5, .5);
      }
    }
  }
  .column{
    font-family: $AdobeCleanFont;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #1F1F1F;
    padding-left: 5px;
    padding-right: 5px;
    vertical-align: center;
    min-height: 30px;
    &:nth-child(1) {
      width: 25px;
      flex-shrink: 0;
    }
    &.name{
      display: flex;
      flex-direction: column;
      padding-top: 7px;
      align-items: flex-start;
      text-align: left;
      padding-bottom: 7px;
      .subtext{
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
        color: #AEB4BE;
      }
    }
  }
}


