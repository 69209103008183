@import '../../vars';

.preview-modal {
  z-index: 4;
  background-color: #ffffff;
  width: 425px;
  margin-bottom: auto;
  margin-top: 10px;

  @keyframes fill {
    0% {
      width: 100%;
    }
    100% {
      width: 0%;
    }
  }
  &-loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
  }
  &-container {
    z-index: 1;
    width: 97%;
    height: 100%;
    display: flex;
    position: absolute;
    border-radius: 3px;
    align-items: center;
    flex-direction: column;
    .indicator-preview {
      position: absolute;
      z-index: 3;
      height: 4px;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 3px 3px 0 0;
      background-color: #aeb4be;
      animation: fill 5.3s linear 1;
    }
  }
  .header {
    &-heading {
      font-family: Oswald;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      text-transform: uppercase;
      color: $text-color;
    }
  }
  .user {
    &-contacts {
      display: flex;
      flex-direction: column;
      .image {
        display: flex;
        font-size: 48px;
        line-height: 60px;
        font-weight: 700;
        color: $button-background;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;
        margin-bottom: 10px;
        img {
          border: 1px solid $border-color;
          box-sizing: border-box;
          border-radius: 2px;
          justify-content: center;
          position: relative;
          overflow: hidden;
          flex-shrink: 0;
          object-fit: cover;
          width: 115px;
          height: 115px;
          border: 1px solid #f7f7f7;
          box-sizing: border-box;
          border-radius: 2px;
        }
      }
      .full-name {
        font-family: Adobe Clean;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        color: $text-color;
        margin-bottom: 15px;
      }
      .contacts-heading {
        font-family: Oswald;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-transform: uppercase;
        color: $text-color;
        margin-top: 20px;
        margin-bottom: 15px;
      }
    }
    &-description {
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $text-color;
      margin-top: 15px;
    }
  }
}
