@import '../../../../assets/scss/vars';
@import '../../../../assets/scss/mixins';

.container {
  position: relative;
  margin-right: 15px;
}

.control_wrapper {
  display: flex;
  align-items: center;

  &:hover {
    .value {
      color: #c1c1c1;
      transition: $transition-in;
    }
    .dropdown_indicator::before,
    .dropdown_indicator::after {
      background-color: #c1c1c1;
      transition: $transition-in;
    }
  }

  &:active {
    .dropdown_indicator::before,
    .dropdown_indicator::after {
      background-color: #c1c1c1;
    }
  }
}

.control_wrapper_disabled {
  display: flex;
  align-items: center;

  .value {
    color: $disabled-color;
  }

  .dropdown_indicator::before,
  .dropdown_indicator::after {
    background-color: $disabled-color;
  }
}

.value {
  font-family: $OswaldFont;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  outline: none;
  background-color: transparent;
  color: #1F1F1F;
  padding-right: 2px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  position: relative;

  &_light {
    color: #ffffff;
  }
  &:active,
  &_active {
    color: #8e8e8e;
  }
  &_overlap{
    &:after{
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 30px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 100%);
    }
  }
}

.dropdown_indicator {
  @include dropdown_indicator;

  width: 15px;
  height: 15px;
  flex-shrink: 0;

  &_light {
    &::before,
    &::after {
      background-color: #ffffff;
    }
  }
  &_active {
    &::before,
    &::after {
      background-color: #c1c1c1;
    }
  }
}

.menu {
  position: absolute;
  z-index: 7;
  top: 19px;
  min-width: 106px;
  border-radius: 2px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  background-color: $main-color;
  overflow: hidden;
}

.option {
  &:not(:last-child) {
    margin-bottom: -5px;
  }

  padding: 10px;
  color: $text-color;
  font-family: $OswaldFont;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  cursor: pointer;
  transition: .3s;

  &_disabled{
    color: rgba($input-disabled-color, .5)!important;
    cursor: default;
  }

  &:hover {
    color: $input-disabled-color;
  }

  &_active {
    color: $input-disabled-color;
  }
  &:first-child{
    padding-top: 10px;
  }
  &:last-child{
    padding-bottom: 10px;
  }
}
