/* This stylesheet generated by Transfonter (https://transfonter.org) on April 14, 2018 12:39 PM */

@font-face {
  font-family: 'Adobe Clean';
  src: url('AdobeClean-Regular.ttf');
  src: local('Adobe Clean'), local('AdobeClean-Regular'),
    url('AdobeClean-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Adobe Clean';
  src: url('AdobeClean-Bold.ttf');
  src: local('Adobe Clean Bold'), local('AdobeClean-Bold'),
    url('AdobeClean-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
