@import "./tabs/info";
@import "./tabs/sales";
@import "./tabs/leases";
@import "./tabs/supplements";
@import "./transaction-info";
@import "./transaction-item";
@import "./google-maps";
@import "./property-images";

.properties{
   &_wrapper{
      height: 100%;
      position: relative;
      &.properties_wrapper_fullscreen{
         z-index: 22;
      }
   }
}

.properties-page {
   @extend .page-layout;
   overflow-y: hidden !important;

   &_full-screen-filters {
      .page-layout__content {
         flex-direction: row-reverse;
      }
   }

   @include mq-phone {
      &_full-screen-filters {
         .page-layout__content {
            flex-direction: column;
         }
      }
   }

   &__property-tab {
      display: flex;
      flex-direction: column;
   }
}

.properties-list-container {
   width: 48.45%;
   min-width: $data-list-min-width;
   max-width: $data-list-max-width;
   padding: 15px 0;
   display: flex;
   flex-direction: column;
   padding-bottom: 0;
   .ReactVirtualized__Grid__innerScrollContainer{
      padding-bottom: 15px;
   }

   .data-list {
      width: 100%;
      //height: 91%;
      padding: 0;

      //@include mq-tablet {
      //   height: 90%;
      //}
      //
      //@include mq-phone {
      //   height: 92.1%;
      //}
   }

   &_full-screen {
      width: 100%;
      height: 100vh;
      max-width: unset;
      padding: 0;

      .properties-list__map-container {
         height: 100%;
         margin: 0;
      }

      @include mq-tablet {
         width: 50%;
      }
   }

   @include mq-tablet {
      min-width: 100%;

      &_full-screen {
         min-width: 50%;
      }
   }

   @include mq-phone {
      &_full-screen {
         min-width: 100%;
         height: 50%;
      }
   }
}

.properties-list {
   padding-top: 0;

   &__map {
      height: inherit;

      &-container {
         height: 91%;
         margin: 0 20px;
         display: flex;
         flex-direction: column;
         position: absolute;
         z-index: 20;
         left: 0;
         top: 0;
         right: 0;
         bottom: 0;

         @include mq-tablet {
            padding: 0 15px;
            margin: 0;
            height: 88.4%;
         }

         @include mq-phone {
            height: 90.5%;
         }
         &.map_hide{
            opacity: 0;
            pointer-events: none;
            user-select: none;
         }
         &_filters-applied {
            height: 87.3%;

            @include mq-tablet {
               height: 84.5%;
            }

            @include mq-phone {
               height: 86.5%;
            }
         }
      }

      height: 100%;

      &_expanded {
         width: 100%;
         z-index: 21;
      }
   }
}
