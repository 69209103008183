@import '../../vars';

.notes-button {
  position: relative;
  display: flex;
  flex-direction: column;
  right: 15px;
  bottom: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  cursor: pointer;
  width: 35px;
  height: 35px;

  background: $button-background;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 100%;

  transition: 0.3s;

  @media screen and (min-width: 1025px) {
    &:nth-child(2n) {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 1025px) {
    &:first-child {
      margin-right: 10px;
    }
  }

  &-row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &-item {
    display: flex;
    justify-content: center;
    align-items: center;

    .tooltip {
      position: relative;
      display: inline-block;

      &-content {
        min-width: 30px;
        white-space: nowrap;
        max-width: 160px;
        &-symbol {
          font-family: Adobe Clean;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          text-align: right;
          color: rgba(255, 255, 255, 0.5);
          margin-left: 8px;
        }
        display: flex;
        align-items: center;
        position: absolute;
        top: -15%;
        height: 31px;
        transform: translateX(-110%);
        background: $content-background;
        border-radius: 1px;
        padding: 8px;
        pointer-events: none;
        color: $main-color;
        font-family: 'Adobe Clean', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        opacity: 0;
        transition: 0.3s;

        &::before {
          content: '';
          position: absolute;
          bottom: 35%;
          left: 97%;
          transform: rotate(90deg);
          border-style: solid;
          border-color: transparent transparent $lease-visualization-bg
            transparent;
          border-width: 5px 8px;
        }
      }

      &-icon {
        width: 15px;
        height: 15px;
        margin: 10px;
      }
    }
    &:hover {
      .tooltip-content {
        opacity: 1;
        margin-top: 9px;
      }
    }
  }
  &:hover {
    background: $button-dark-hover-color;
    border: 1px solid $button-dark-default-color;
    border-radius: 100%;
    box-sizing: border-box;
    &__tooltip {
      opacity: 1;
    }
  }
}
