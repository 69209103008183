.current-involvement {
   @extend .personal-contacts;
   margin-right: 0;
   margin-bottom: 15px;
   padding: 20px;

   &__content {
      .logo-container {
         margin-bottom: 10px;

         img {
            max-width: 95px;
            max-height: 215px;
            object-fit: scale-down;
         }
      }
   }

   @include mq-phone {
      margin-bottom: 10px !important;
   }
}