.notes-tab {
   width: 100%;

   &__notes-section {
      width: 50%;
   }

   &__info-section {
      width: 50%;
   }
}

.notes-section {
   height: 100%;
   display: flex;
   flex-direction: column;

   &__notes-list {
      flex-grow: 1;
   }
}