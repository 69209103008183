@function rem($pixels, $context: $main-font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }
  @return $pixels / $context * 1rem;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::placeholder {
    @content;
  }
}

@mixin scrollbar-x {
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    margin: 0 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background-color: $disabled-color;
  }
}

@mixin scrollbar-y {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    margin: 2px 0;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background-color: $disabled-color;
  }
}

@mixin no-scrollbar {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

@mixin visually-hidden {
  @extend .visually-hidden;
}

.flex {
  display: flex;
  align-items: center;
}
@mixin flex {
  display: flex;
  align-items: center;
}

@mixin flex-center {
  @include flex;
  justify-content: center;
}

@mixin space-between {
  @include flex;
  justify-content: space-between;
}

@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin ellipsis-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden !important;
}

.ellipsis-truncate {
  @include ellipsis-truncate;
}

@mixin times-icon($size: 17px, $thickness: 2px, $color: $main-color) {
  @include flex-center;
  position: relative;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &::before,
  &::after {
    width: $size;
    height: $thickness;
    border-radius: $thickness;
    background-color: $color;
  }
}

@mixin dropdown-indicator($color: $text-color) {
  @include flex-center;
  position: relative;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &::before,
  &::after {
    width: 7px;
    height: 1px;
    border-radius: 1px;
    background-color: $color;
  }

  &::before {
    left: -1px;
  }
}

@mixin backdrop-overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $backdrop-overlay-color;
}

@mixin mq-desktop-lg {
  @media screen and (max-width: 1800px) {
    @content;
  }
}

@mixin mq-desktop-md {
  @media screen and (max-width: 1300px) {
    @content;
  }
}

@mixin mq-desktop {
  @media screen and (min-width: 1025px) {
    @content;
  }
}

@mixin mq-tablet {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin mq-tablet-sm {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin mq-phone {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin mq-phone-sm {
  @media screen and (max-width: 320px) {
    @content;
  }
}
