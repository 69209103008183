@import "../../../../assets/scss/vars";
@import "../shared/styles/control.module.scss";

.control {
   min-width: 280px;
   margin-top: 8px;
   display: flex;
   -webkit-tap-highlight-color: transparent;
}

//.clear{
//   position: absolute;
//   right: 0;
//   top: 5px;
//}

.input {
   margin-top: 0;
   cursor: pointer;

   /* remove rounded borders on iOS */
   border-radius: 0;
   -webkit-appearance: none;

   & + .dropdown_indicator {
      border-bottom: 1px solid $input-underline-default-color;
   }

   &:hover,
   &:focus {
      & + .dropdown_indicator {
         border-color: $input-active-color;
         transition: $transition-in;
      }
   }

   &:disabled {
      & + .dropdown_indicator {
         border-color: $input-underline-disabled-color;
         svg path {
            fill: $input-disabled-color;
         }
      }
   }
}

.dropdown_indicator {
   width: 20px;
   cursor: pointer;

   svg {
      &:first-child {
         width: 12px;
      }

      path {
         fill: $input-active-color;
      }
   }
}

.menu_wrapper {
   margin-top: 5px;
   width: 100%;
   position: absolute;
   z-index: 1;
}

.menu {
   width: 100%;
   position: absolute;
}

.options {
   width: 100%;
   position: absolute;
   z-index: 2;
   background-color: $main-color;
   box-shadow: 0 4px 10px rgba(33, 33, 33, 0.13);
   overflow-y: scroll;

   &::-webkit-scrollbar {
      width: 2px;
   }
   &::-webkit-scrollbar-track {
      margin: 2px 0;
   }
   &::-webkit-scrollbar-thumb {
      border-radius: 1px;
      background-color: $disabled-color;
   }
}

.option {
   @include ellipsis-truncate;
   font-size: 16px;
   height: 26px;
   line-height: 26px;
   padding: 0 10px;
   -webkit-tap-highlight-color: $selection-color;
   color: $input-active-color;
   background-color: $main-color;
   cursor: pointer;

   &:hover {
      color: $main-color;
      background-color: $option-bg-color;
   }
   &:active {
      background-color: $selection-color;
   }
}

.options_loader {
   width: 100%;
   margin: 0 10px;
}
.no_options_message {
   color: $main-color;
   font-size: 14px;
   padding-left: 10px;
   border-radius: 1px;

   color: $input-default-color;
   box-shadow: 0 4px 10px rgba(33, 33, 33, 0.13);

   width: 100%;
   line-height: 26px;
}

.selection {
   top: 26px;
}

.scrollthumby {
   width: 2px !important;
   bottom: 0;
   left: 4px;
   background: $disabled-color;
   border-radius: 1px;
   z-index: 1;
}
