.mapaddress {
  &-linker {
    margin-bottom: 10px;
    display: flex;
    flex-flow: row;

    &-linked {
      display: flex;

      &-imgcontainer {
        min-width: 26px;
        max-width: 26px;
        height: 26px;
        display: flex;
        margin-right: 10px;
        justify-content: center;
        align-items: center;
        border: 1px solid $disabled-color;
        border-radius: 2px;
        cursor: pointer;
      }
    }

    &-unlinked {
      display: flex;

      &-imgcontainer {
        @extend .mapaddress-linker-linked-imgcontainer;
        background-color: $main-color-black;
      }
    }

    &-text {
      font-size: 10px;
    }
  }
}
.autocomplete-input {
  position: relative;
}
.googleBloc {
  display: flex;
  flex-flow: column;
  width: 100%;
  min-width: 280px;

  &-address {
    display: flex;
    flex-flow: row;

    &-word {
      font-family: Oswald;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
      color: $label-color;
    }

    &-star {
      color: $error-color;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
    }
  }

  &-searchbox {
    display: flex;
    align-items: center;
    &-input {
      margin-top: 16px;
      margin-bottom: 10px;
      padding-bottom: 5px;
      width: 100%;
      height: 20px;
      min-width: 100%;
      outline: none;
      font-size: 12px;
      &.error{
        color: $error-color;
      }
    }
    &.focus + .list-options {
      left: initial;
    }
  }
}

.list {
  &-options {
    display: flex;
    flex-flow: column;
    position: absolute;
    left: -100vw;
    top: 45px;
    width: 100%;
    max-width: 280px;
    height: fit-content;
    background-color: $main-color;
    box-shadow: 0px 4px 10px rgba(33, 33, 33, 0.13);
    border-radius: 1px;
    z-index: 2;

    &-loader {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
  }

  &-gItem {
    display: flex;
    max-width: 280px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 7px;
    padding-bottom: 10px;
    cursor: pointer;

    &:hover {
      background-color: $button-dark-hover-color !important;
      color: $main-color;
    }

    &-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.selectedg {
  &-address {
    background-color: $button-dark-hover-color;
    justify-content: center;
    margin-top: 10px;
    height: 22px;
    min-height: 22px;
    padding-bottom: 3px;
    display: flex;
    flex-flow: row;
    width: 100%;

    &-item {
      margin-top: auto;
      margin-bottom: auto;
      display: flex;
      font-size: 16px;
      color: $main-color;
      margin-left: 5px;

      &-text {
        max-width: 260px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-close {
      margin-top: auto;

      margin-bottom: auto;
      margin-right: 5px;
      margin-left: auto;
      display: flex;
      cursor: pointer;
    }

    &-wrapper {
      padding-bottom: 3px;
      margin-bottom: 10px;
      border-bottom: 1px solid $disabled-color;
      width: 100%;
    }
  }
}

.g {
  &-street {
    &-view {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      width: 202px;
      height: 130px;
      border: 1px solid $page-bg-color;
      cursor: pointer;

      &-btn {
        margin-top: 15px;
        width: 120px;
        height: 26px;
        border-radius: 2px;
        font-size: 12px;
        line-height: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $main-color-black;
        color: $text-color-light;
        font-family: Adobe Clean;
        font-style: normal;
        font-weight: normal;
        text-transform: uppercase;

        &:hover {
          background-color: $button-dark-hover-color;
        }
      }
    }
  }
}

.list-options{
  .creation{
    position: static;
  }
}

.dropdown-indicator{
  width: 20px;
  cursor: pointer;

  svg {
    &:first-child {
      width: 12px;
    }

    path {
      fill: $input-active-color;
    }
  }
}
