#involvement-history-container {
  margin-right: -10px !important;

  .page-scrollbar-y {
    left: 7px !important;
  }

  @include mq-phone {
    & > div {
      padding-right: 20px !important;
    }
  }
}

.involvement-history {
  display: flex;
  flex-direction: column;

  margin-right: 15px;
  width: 50%;
  height: 100%;
  min-width: 205px;
  max-width: 365px;

  .button-add-new {
    display: flex;
    justify-content: center;
    align-self: flex-end;
    margin: 0 0px 10px 1px;
  }

  @include mq-phone {
    min-width: 100%;
    padding-left: 15px;
    padding-right: 5px;
  }
}

.involvement-history__item {
  @extend .data-item;
  @include ellipsis-truncate;

  .item-summary {
    @include ellipsis-truncate;
    padding: 12px;
    font-weight: 500;

    &__position {
      @include ellipsis-truncate;
      color: $text-color;
      font-size: 16px;
      line-height: 20px;
    }
    &__post {
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      color: $label-color;
      margin-bottom: 5px;
    }
    &__date {
      color: $label-color;
      padding-top: 5px;
      font-size: 12px;
      line-height: 15px;
    }
  }

  &_active {
    .item-summary {
      @include ellipsis-truncate;
      &__position {
        @include ellipsis-truncate;
        color: $main-color;
      }
      &__post {
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        color: $main-color;
        margin-bottom: 5px;
      }
      &__date {
        color: $main-color;
      }
    }
    background-color: $main-color-black !important;
  }

  &_current {
    border-left: 3px solid $listing-status_active !important;
    &:hover {
      border-left: 3px solid $listing-status_active;
    }
  }
}

#involvement-info-container {
  width: 100%;
  height: 100%;
}
