@import './tag';

.tag-page {
  @extend .page-layout;
  overflow-y: hidden !important;

  &__content {
    width: 100%;
    margin-right: 20px;

    @include mq-tablet {
      padding: 15px;
      margin: 0;
    }

    @include mq-phone {
      padding: 0;
    }
  }
}

.taxonomy-page, .setting-page {
  @extend .page-layout;
  overflow-y: hidden !important;

  &__content {
    width: 100%;
    margin-right: 20px;

    @include mq-tablet {
      padding: 15px;
      margin: 0;
    }

    @include mq-phone {
      padding: 0;
    }
  }
}

#tag-info {
  @extend %info-block;
  margin: 15px 20px 15px 0;
  padding: 15px 20px 20px 20px;
  max-width: $tab-max-width;
  position: relative;

  .add-new {
    border: 1px solid #1f2531;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: fit-content;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      border: 1px solid #8aa9b0;
      span {
        color: #8aa9b0;
      }
    }
    span {
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
      color: #1f2531;
      transition: 0.3s;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(31, 31, 31, 0.1);
    .title {
      font-family: Oswald;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
      color: $text-color;
      margin-right: 4px;
    }
  }
  .group-header {
    display: flex;
    justify-content: space-between;
    .group-title {
      font-family: Oswald;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      text-transform: uppercase;
      color: $text-color;
    }
  }

  .button-go-back {
    display: none;
  }

  .tag-info__content {
    display: flex;
    flex-direction: column;
    .tag-item {
      margin: 8px 0px;
    }
  }

  @include mq-tablet {
    padding-top: 40px;
    .button-go-back {
      display: block;
      cursor: pointer;
    }
  }

  @include mq-phone {
    .button-go-back {
      margin-top: 0;
    }

    .tag-info__content {
      flex-direction: column;

      .column {
        width: 100%;
      }
    }
  }
}
