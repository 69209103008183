.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: $navigation-width-desktop;
  height: 100%;
  padding: 0 15px;
  background-color: $main-color-black;

  .logo {
    padding: 30px 0 20px;
  }

  &__additional-links {
    .nav-menu {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &__main-links .nav-menu__link svg {
    display: none;
  }

  .nav-menu {
    list-style: none;

    &__item {
      display: block;

      // main
      &_taxonomy {
        margin-top: auto;
        padding-top: 20px;
        border-top: 1px solid rgba(233, 233, 233, 0.1);
      }

      &_people {
        position: absolute;
        top: 0;
        margin-top: 164px;
      }
    }

    &__menu {
      display: none;
    }

    &__link {
      display: block;
      font-size: 14px;
      color: rgba($main-color, 0.6);
      padding: 10px 0;
      text-decoration: none;
      text-transform: capitalize;
      cursor: pointer;

      &:hover {
        color: $main-color;
        transition: $transition-in;
      }
      &_active {
        @media only screen and (min-width: 1025px) {
          &::before {
            /* White */
            content: '';
            width: 8px;
            height: 33px;
            background: #ffffff;
            border-radius: 13px;
            display: flex;
            position: absolute;
            align-items: center;
            margin-top: -7px;
            margin-left: -19px;
            justify-content: center;
          }
        }
        color: $main-color;
        transition: $transition-in;
      }
    }
  }
}

@include mq-tablet {
  .navigation {
    width: 100%;
    height: $navigation-height-mobile;
    // position: fixed;
    // bottom: 0;
    // z-index: 2;

    .logo {
      display: none;
    }

    &__main-links {
      padding-top: 0;

      .nav-menu {
        width: inherit;
        display: flex;
        justify-content: space-around;

        &__item {
          width: 25%;
          text-align: center;
          &_taxonomy {
            margin-top: 0;
            padding-top: 0;
            border-top: none;
          }
        }

        &__menu {
          display: block;
        }

        &__link {
          display: flex;
          flex-direction: column;
          align-items: center;

          color: $main-color;
          font-size: 12px;
          line-height: 15px;

          svg {
            display: block;
          }

          span {
            padding-top: 5px;
          }

          &:hover {
            transition: none;
          }

          &:hover,
          &_active {
            color: $link-color;

            svg path {
              fill: $link-color;
            }
          }
        }
      }
    }

    &__additional-links {
      border: none;
      margin: 0;

      .nav-menu {
        border: none;

        &__item_users {
          margin-top: 0;
          padding-top: 0;
          border-top: none;
        }
      }
    }

    .mobile-drawer {
      position: fixed;
      z-index: 21;
      right: 0;
      top: 0;
      bottom: 0;
      width: 45%;
      background-color: $main-color-black;
      padding: 85px 15px;

      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      transform: translateX(100%);
      transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1);

      &_is-open {
        transform: translateX(0px);
      }

      @include mq-phone {
        width: 100%;
      }

      .nav-menu {
        display: flex;
        flex-direction: column;
        height: 100%;
        display: contents;
        &__item {
          padding-bottom: 15px;
          &_taxonomy {
            margin-top: 0;
            padding-top: 0;
            border-top: none;
          }
          &_log-out {
            margin-top: 5px;
            padding-top: 5px;
            border-top: 1px solid rgba(#efeeee, 0.25);
          }
        }

        &__link {
          font-weight: 500;
          font-size: 18px;
          line-height: 23px;
          color: $main-color;
          text-decoration: none;
          text-transform: capitalize;
          cursor: pointer;

          &:hover,
          &_active {
            color: $main-color;
            transition: $transition-in;
          }
        }
      }

      &__backdrop {
        @include backdrop-overlay;
        z-index: 2;
      }

      &__close {
        @include times-icon(20px);
        position: absolute !important;

        padding: 25px 30px 30px 25px;

        right: 0;
        bottom: 0;
      }
    }
  }
}
