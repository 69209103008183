@import '../../../../assets/scss/vars';
@import '../shared/styles/control.module';

.control {
  margin-top: 12px;
  display: flex;
  -webkit-tap-highlight-color: transparent;
}

.input {
  margin-top: 0;
  cursor: pointer;

  /* remove rounded borders on iOS */
  border-radius: 0;
  -webkit-appearance: none;

  & + .dropdown_indicator {
    border-bottom: 1px solid $input-underline-default-color;
  }

  &:hover,
  &:focus {
    & + .dropdown_indicator {
      border-color: $input-active-color;
      transition: $transition-in;
    }
  }

  &:disabled {
    & + .dropdown_indicator {
      border-color: $input-underline-disabled-color;
      svg path {
        fill: $input-disabled-color;
      }
    }
  }
  &_havent_results{
    color: $error-color;
  }
}

.control_dark {
  @extend .control;

  border-color: rgba($main-color, 0.1);

  &:hover {
    border-color: $main-color;
  }

  .input {
    color: $main-color;
    border-color: rgba($main-color, 0.1);

    & + .dropdown_indicator {
      border-color: rgba($main-color, 0.1);

      svg path {
        fill: $main-color;
      }
    }

    @include placeholder {
      color: rgba($input-default-color, 0.5);
    }

    &:hover,
    &:focus {
      color: $main-color;
      border-color: $main-color;

      & + .dropdown_indicator {
        border-color: $main-color;
        transition: $transition-in;
      }

      @include placeholder {
        color: $input-default-color;
        transition: $transition-in;
      }
    }

    &:disabled {
      & + .dropdown_indicator {
        border-color: $input-underline-disabled-color;
        svg path {
          fill: $input-disabled-color;
        }
      }
    }
  }
}

.selections {
  width: 100%;
}

.dropdown_indicator {
  cursor: pointer;
  width: 20px;

  svg {
    &:first-child {
      width: 12px;
    }

    path {
      fill: $input-active-color;
    }
  }
}

.menu_wrapper {
  margin-top: 5px;
  width: 100%;
  position: absolute;
  z-index: 1;
}

.menu {
  width: 100%;
  position: absolute;
}
@media screen and (max-width: 675px) {
  .menu {
    width: 90%;
  }
}
@media screen and (max-width: 615px) {
  .menu {
    width: 70%;
  }
}
@media screen and (max-width: 585px) {
  .menu {
    width: 60%;
  }
}
@media screen and (max-width: 555px) {
  .menu {
    width: 50%;
  }
}
@media screen and (max-width: 530px) {
  .menu {
    width: 40%;
  }
}
@media screen and (max-width: 500px) {
  .menu {
    width: 30%;
  }
}
@media screen and (max-width: 480px) {
  .menu {
    width: 100%;
  }
}

.options {
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: $main-color;
  box-shadow: 0 4px 10px rgba($text-color, 0.13);
  // overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    margin: 2px 0;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background-color: $disabled-color;
  }
}

.option {
  @include ellipsis-truncate;
  font-size: 16px;
  height: 26px;
  line-height: 26px;
  padding: 0 10px;
  -webkit-tap-highlight-color: $selection-color;
  color: $input-active-color;
  background-color: $main-color;
  cursor: pointer;

  &:hover {
    color: $main-color;
    background-color: $option-bg-color;
  }
  &:active {
    background-color: $selection-color;
  }
}

.no_options_message {
  color: $text-color;
  font-size: 14px;
  padding-left: 10px;
  border-radius: 1px;

  color: $input-default-color;
  box-shadow: 0px 4px 10px rgba($text-color, 0.13);

  width: 100%;
  line-height: 26px;
}

.selected_close {
  margin-left: 6px;
  padding: 3px;
  cursor: pointer;
}
