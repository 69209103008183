@import "../shared/styles/control.module.scss";

.input_password_wrapper {
   display: flex;
   
   input {
      border: none;
   }
}

.password_change_icon {
   margin-top: 1px;
   border-bottom: 1px solid $input-default-color;
   cursor: pointer;
}

.button_edit {
   line-height: 15px !important;
   min-width: 48px !important;
   height: 23px;
   position: absolute;
   right: 0;
   top: 25px;
}