.reminder-creator__input-field{
  padding: 0 15px;
  font-size: 14px;
  .public-DraftStyleDefault-block{
    margin: 10px 0;
  }
}

.reminder-editor{
  background-color: $main-color;


  &.error{
    background: $error-background;
  }
}
.reminder__wrapper{
  position: relative;
  z-index: 6;
  transition: .5s;
  overflow: hidden;
}
