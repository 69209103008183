#supplements-tab-container {
  @extend .box-shadow-container;

  & > div {
    padding: 12px 20px 0px 0px !important;
  }

  @include mq-desktop {
    margin-right: -5px !important;
  }

  @include mq-tablet {
    margin: 0 -5px !important;

    & > div {
      padding: 12px 10px 0px 20px !important;
    }
  }

  @include mq-phone {
    margin: 0px !important;

    & > div {
      padding: 0 !important;
    }
  }
}

.supplements-tab {
  @extend %info-block;
  display: block !important;
  height: unset !important;

  padding: 20px;

  h3.supplements-tab__title {
    margin-top: 0;
    margin-bottom: 15px;
  }

  &__documents-list {
    .document-supplement {
      margin-bottom: 20px;
      max-width: 194px;
      background: #fff;
      &:hover{
        background: #DAE1EF;
        .document-supplement__image{
          border-color: #DAE1EF;
        }
        .document-supplement__title:after{
          opacity: 1;
        }
      }
      &__image{
        width: 194px;
        height: 194px;
        border: 1px solid #F6F6F6;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s;
        svg{
          width: 40px;
          height: 40px;
          background: transparent;
        }
      }
      &__title{
        margin-top: 5px;
        max-width: 194px;
        overflow: hidden;
        position: relative;
        white-space: nowrap;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #1F1F1F;
        padding-bottom: 5px;
        &:after, &:before{
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 15px;
          transition: .3s;
          background: linear-gradient(90deg, rgba(#fff, 0) 0%, rgba(#fff, 1) 80%);
        }
        &:after{
          opacity: 0;
          background: linear-gradient(90deg, rgba(#DAE1EF, 0) 0%, rgba(#DAE1EF, 1) 80%);
        }
      }
    }
  }

  &__images-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    .supplements-image {
      margin: 0 10px 15px 0;
      cursor: pointer;

      &__preview,
      &__preview img {
        width: 200px;
        height: 200px;
      }

      &__preview {
        border: 1px solid #ebeaea;

        img {
          object-fit: contain;
        }
      }

      &__name {
        @include ellipsis-truncate;
        width: 200px;
        margin-top: 5px;
        line-height: 15px;
        font-size: 12px;
        font-weight: bold;
        color: $link-color;
      }
    }
  }
}
