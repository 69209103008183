.last-sale-transaction {
  @extend %content-block;
  width: 50%;
  margin-right: 10px;
  margin-bottom: 0 !important;
  padding: 20px;

  &__status-field {
    .data-field__value {
      display: flex;
      align-items: center;
      word-break: break-word;
      flex-wrap: wrap;
    }
  }

  &__current-status {
    height: 17px;
    margin-right: 15px;
    margin-bottom: 3px;
  }

  .property-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 15px;
  }

  .data-field:last-child {
    margin-bottom: 0;
  }

  .owners-and-decision-makers-item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:last-child {
      margin-bottom: 3px;
    }

    &__position {
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 3px;
    }
    &__post {
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      color: $label-color;
      margin-bottom: 5px;
    }
  }
}
