@import './controls';
@import './item';
@import './tabs';

.data-list {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;
  justify-content: flex-start;
  height: 100%;
  width: $data-list-width;
  min-width: $data-list-min-width;
  max-width: $data-list-max-width;

  padding: 15px 0px 0px 0px;
  background-color: $page-bg-color;

  &_hidden {
    @include mq-tablet {
      position: absolute;

      width: 1px;
      height: 1px;
      margin: -1px;
      border: 0;
      padding: 0;

      white-space: nowrap;

      -webkit-clip-path: inset(100%);
      clip-path: inset(100%);

      clip: rect(0 0 0 0);
      overflow: hidden;
    }
  }

  &__sort {
    position: relative;
    padding: 0px 20px 10px 20px;
    display: flex;
    justify-content: space-between;

    &.dash {
      &::after {
        content: '';
        display: block;
        background: transparent;
        height: 5px;
        width: 98%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        box-shadow: 0 3px 3px rgba(23, 40, 68, 0.18);
      }
    }

    .items-count {
      min-width: fit-content;
      font-size: 12px;
      font-weight: 500;
      color: $main-color-gray;
      line-height: 15px;

      span {
        color: $main-color-gray;
      }
    }
  }

  &__items {
    position: relative;
    padding-left: 20px;
    padding-right: 15px;

    &.items-list {
      .new-items {
        display: flex;
        margin-right: 15px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        &-notification {
          cursor: pointer;
          position: absolute;
          left: 20px;
          width: calc(100% - 49px);
          height: 26px;
          background: #0ebc7e;
          display: flex;
          border-radius: 3px;
          z-index: 3;
          justify-content: center;
          align-items: center;
          span {
            font-family: Adobe Clean;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            text-transform: uppercase;
            color: #ffffff;
          }
        }
        &-dash {
          width: 100%;
          margin-top: -5px;
          margin-bottom: 10px;
          position: relative;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          span {
            font-family: Adobe Clean;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            text-transform: uppercase;
            color: #ffffff;
            padding: 1px 6px;
            background: #0ebc7e;
            border-radius: 2px;
            z-index: 2;
          }
          &::after {
            z-index: 1;
            content: '';
            width: 100%;
            height: 2px;
            position: absolute;
            background: #0ebc7e;
            border-radius: 2px;
          }
        }
      }
      &::before {
        content: '';
        display: block;
        background: #f1f4f9;
        height: 8px;
        width: 103%;
        position: absolute;
        top: -10px;
        z-index: 1;
        // border-top: 1px solid silver;
        left: 0;
      }
      margin-right: -10px;
      margin-top: 10px;
      height: 100%;
    }
  }

  &__nothing-found {
    color: rgba($main-color-gray, 0.5);
    font-size: 16px;
    line-height: 20px;
    margin-top: 30px;
    text-transform: uppercase;
    text-align: center;
  }

  &__map {
    position: relative;
    height: 100%;
    margin: 0 20px;

    &_full-screen {
      width: 100%;
      margin: 0;
      z-index: 5;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__map-fullscreen-button {
    position: absolute;
    right: 20px;
    bottom: 10px;
  }

  &-scrollbar {
    left: -12px;
  }

  @include mq-tablet {
    min-width: 100%;

    &__items {
      padding-right: 15px;
      padding-left: 15px;
    }

    &__map {
      width: 100%;
      margin: 0 15px;
    }

    &-scrollbar {
      left: -2px;
    }
  }
}
