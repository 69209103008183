.multi {
  &-item {
    &.last {
      margin-bottom: 5px;
      padding-bottom: 0px;
      border-bottom: none;
    }
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dcdedf;
    .data {
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #434343;
      .slug {
        display: inline-block;
        text-transform: uppercase;
      }
      .label {
        display: inline-block;
        text-transform: uppercase;
      }
    }
    .general {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      .options {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .star {
          cursor: pointer;
        }
        .dots {
          cursor: pointer;
        }
        &-field {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 5px;
          background: antiquewhite;
        }
      }
    }
  }
  &-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    &-button {
      padding: 4px 26px;
      background: #1f2531;
      border-radius: 2px;
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      margin-top: 10px;
      width: fit-content;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background: $button-dark-hover-color;
      }
    }
    &-label {
      margin-bottom: 8px;
      font-family: Oswald;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
      color: #627d7a;
    }
    &-items {
      display: flex;
      flex-direction: column;
    }
  }
}
