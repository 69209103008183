@import '../shared/styles/control.module.scss';

.input {
  padding-right: 26px;

  /* remove rounded borders on iOS */
  border-radius: 0;
  -webkit-appearance: none;

  &:focus {
    .plus_icon svg path {
      fill: $text-color-light;
    }
  }
  &:focus + .plus_icon{
    svg{
      background:#D1D5D6;
      path {
        fill: $text-color-light;
      }
    }
  }
  &.has-value{
    & + .plus_icon{
      svg{
        background: #1B2F34;
        path {
          fill: $text-color-light;
        }
      }
    }
  }
}

.input_phone {
  align-items: flex-start;
}

.plus_icon {
  position: absolute;
  right: -2px;
  top: 0;
  padding: 0 5px;
  cursor: pointer;
  svg{
    border-radius: 50%;
    background: rgba(#D1D5D6, 0);
    transition: .3s;
    path{
      transition: .3s;
    }
  }
}

.plus_icon_phone {
  margin: auto;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 25px;
  height: 60px;
  border-width: 1px;
  border-radius: 2px;
  border-color: #dcdedf;
  border-style: solid;
  margin-top: -2px;
}

.error {
  font-size: 10px;
  padding-top: 2px;
  // text-align: right;
  color: $error-color;
}

.selections {
  margin-top: 8px;
  margin-bottom: 3px;
}

.selected_close {
  padding: 3px;
  cursor: pointer;
}

.input_container {
  display: flex;
  flex-flow: column;
}

.input_left_trimmer {
  display: flex;
  flex-flow: row;
}

.input_right_trimmer {
  display: flex;
  flex-flow: row;
}

.input_phone_container {
  display: flex;
  flex-flow: column;
  padding: 0;
  width: 100%;
}

.selection_multi span{
  text-transform: none!important;
}
