.MuiFormControl-root {
  width: 100%;
  margin-top: -7px !important;

  .MuiTypography-root {
    color: $text-color-light !important;
  }

  &.listing-status-date {
    bottom: -6px;

    .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
      margin-top: -3px;
      line-height: 20px;
      padding: 6px 0 0;
    }
  }

  &.filters__date-picker {
    overflow: hidden;

    input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
      color: $text-color-light;

      &::placeholder {
        opacity: 0.5;
      }
      &:hover::placeholder {
        opacity: 1;
      }
    }

    .MuiInput-underline {
      &::before {
        border-bottom: 1px solid rgba($main-color, 0.1) !important;
      }

      &:hover:not(.Mui-disabled)::before {
        border-color: $main-color !important;
      }
    }

    .MuiFormHelperText-root.Mui-error {
      // position: absolute;
      // right: 0;
      // bottom: -10px;
    }
  }

  input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
    &::placeholder {
      color: $input-default-color;
      opacity: 1;
    }
    &:hover::placeholder {
      color: $text-color;
    }
  }
}

.MuiInputBase-root {
  font-family: $AdobeCleanFont !important;
  color: $input-active-color !important;
}

.MuiInput-underline {
  border-bottom: 1px solid $input-underline-default-color;

  &:hover:not(.Mui-disabled),
  &:focus:not(.Mui-disabled) {
    border-color: $input-hover-color;
    transition: $transition-in;
  }

  &::before {
    border: none !important;
  }

  &::after {
    border: none !important;
  }

  &.Mui-disabled::before,
  &.Mui-disabled {
    border-bottom: 1px solid $input-underline-disabled-color !important;
  }
}

.MuiFormHelperText-root.Mui-error {
  font-family: $AdobeCleanFont;
  font-size: 10px;
  color: $error-color !important;
  line-height: 13px;
  letter-spacing: 0.03em;
  text-align: right;
  display: flex;
  position: absolute;
  bottom: -25px;
  right: 0;
}

.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
  padding: 6px 0 3px;

  &::placeholder {
    font-family: $AdobeCleanFont;
    font-size: 12px;
    color: $input-default-color !important;
  }
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: #3b545a !important;
}

.MuiTypography-colorPrimary {
  color: #3b545a !important;
}

.MuiPaper-root {
  font-family: $AdobeCleanFont !important;
}

.MuiTypography-root,
.MuiTypography-h4 {
  font-family: inherit !important;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
  &:first-child {
    @extend .button-light;
    padding-top: 8px !important;
  }

  &:last-child {
    @extend .button-dark;
    padding-top: 8px !important;
    border: 1px solid $main-color-black !important;
  }
}

.MuiPickersYearSelection-container {
  height: 300px;

  @include scrollbar-y;
}

.filters__date-picker-container {
  width: 50%;
  position: relative;
  margin-bottom: 20px;
}
