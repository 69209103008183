@import "../shared/styles/control.module";

.image_container {
   @include flex-center;

   position: relative;
   width: 125px;
   height: 125px;
   border: 1px solid #EAEEF3;
   border-radius: 3px;   
   margin-bottom: 25px;
}

.upload {
   &_label {
      @include flex-center;

      width: 100%;
      height: 100%;

      flex-direction: column;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
   }

   &_icon {
      @include flex-center;

      width: 30px;
      height: 30px;
      background-color: $main-color-black;
      border-radius: 50%;
   
      svg {
         position: relative;
         bottom: 1px;
      }
   }

   &_hint {
      margin-top: 5px;
      font-family: $OswaldFont;
      font-size: 10px;
      line-height: 15px;
      text-transform: uppercase;
      color: $main-color-gray;
   }
}

.preview {
   max-width: 124px;
   max-height: 124px;
   border-radius: 3px;
   object-fit: scale-down;

   &__mask {
      @include flex-center;

      position: absolute;
      left: 0;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 3px;
   
      &:hover {
         background: rgba(0, 0, 0, 0.25);

         .preview__controls {
            display: flex;
         }
      }
   }

   &__controls {
      display: none;
   }
}

.controls__icon {
   @include flex-center;

   width: 35px;
   height: 35px;
   background-color: rgba($main-color-black, 0.75);
   border: 1px solid $main-color;
   border-radius: 50%;
   cursor: pointer;

   &:first-child {
      margin-right: 15px;
   }
}

.upload_error {
   color: $error-color;
   letter-spacing: 0.03em;
   font-size: 10px;
   position: absolute;
   right: 0;
   bottom: -19px;
}

.initials {
   font-size: 48px;
   font-weight: 700;
   color: $main-color-black;
}