@import '../../../../assets/scss/vars';
@import '../../../../assets/scss/mixins';

.container {
  position: relative;
  display: flex;
}

.control_wrapper {
  display: flex;
  align-items: center;
  border-radius: 2px;
}

.status {
  &_active {
    background-color: $listing-status_active;
  }
  &_pending {
    background-color: $listing-status_pending;
  }
  &_sold {
    background-color: $listing-status_sold;
  }
  &_canceled {
    background-color: $listing-status_canceled;
  }
  &_expired {
    background-color: $listing-status_expired;
  }
}

.value {
  font-size: 14px;
  line-height: 18px;
  padding: 1px 5px 0px 5px;
  margin-right: 4px;

  font-family: Adobe Clean;
  font-style: normal;
  font-weight: bold;

  text-transform: uppercase;
  outline: none;
  border: none;
  border-right: 1px solid rgba($main-color, 0.3);
  background-color: transparent;
  color: $main-color;
  cursor: pointer;
}

.dropdown_indicator {
  width: 15px;
  height: 15px;

  @include flex-center;
  position: relative;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 1px;
    border-radius: 1px;
    background-color: $main-color;
  }

  &::before {
    transform: rotate(40deg);
    left: 0px;
  }

  &::after {
    transform: rotate(-40deg);
  }
}

%menu-base {
  position: absolute;
  z-index: 1;
  border-radius: 2px;
  border: 1px solid rgba($text-color, 0.1);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  background-color: $main-color;
}

.menu {
  @extend %menu-base;

  top: 22px;
  width: 84px;
}

.option {
  &:not(:last-child) {
    border-bottom: 1px solid rgba($text-color, 0.1);
  }

  padding: 8px 7px 6px 7px;
  color: $text-color;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: $main-color;
    background-color: $text-color;
  }

  &_active {
    color: $input-disabled-color;

    &:hover {
      color: $input-disabled-color;
      background-color: $main-color;
      cursor: default;
    }
  }
}

.toggle_status_history {
  width: 20px !important;
  height: 20px !important;
  margin-top: 0 !important;

  &_active {
    background: rgba(98, 125, 122, 0.1) !important;
    border-color: $label-color !important;
  }
}

.status_history {
  @extend %menu-base;

  top: 22px;
  right: 0;
  width: 170px;
  padding: 10px;

  &_container {
    position: relative;
  }

  &_row {
    display: flex;
    justify-content: space-between;
  }

  &_item {
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
}

.item_status,
.item_date {
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}

.item_status {
  font-weight: 700;
}

.item_date {
  color: $label-color;
}
