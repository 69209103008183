@import "../../../mixins";

$icons: "../../../../../assets/icons/map";
$tooltip-color: #373737;

@mixin icons-change($event-type) {
   background-image: url(#{$icons}/#{$event-type}.svg);

   &:hover {
      background-image: url(#{$icons}/#{$event-type}_hover.svg);
   }
   &:active,
   &:disabled:hover {
      background-image: url(#{$icons}/#{$event-type}.svg);
   }
   &:active {
      background-color: $button-dark-active-color;
   }
}

.google-map__control {
   display: block;
   width: 30px;
   height: 30px;
   border-radius: 2px;
   box-shadow: 0px 0px 15px rgba(31, 31, 31, 0.36);
   color: #C6CBCC;
   background-color: $main-color-black;
   background-position: center;
   background-repeat: no-repeat;
   border: none;
   outline: none;
   text-align: center;
   cursor: pointer;

   &:hover {
      transition: $transition-in;

      &::before,
      &::after {
         background-color: $link-color;
         transition: $transition-in;
      }
   }
   &:active {
      background-color: $text-color;
      border: none;
      outline: none;

      &::before,
      &::after {
         background-color: $main-color;
         transition: $transition-in;
      }
   }
   &:disabled {
      background-color: $disabled-color;
      cursor: not-allowed;
   }
}

.zoom-controls-wrapper {
   margin: 10px;
   height: 60px;
   position: relative;

   &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 1px;
      top: 30px;
      left: 5px;
      background-color: #3B6B77;
      border-radius: 1px;
   }

   &_full-screen {
      @extend .zoom-controls-wrapper;
   }
}

.zoom-in-control {
   @extend .google-map__control;

   border-bottom-left-radius: 0;
   border-bottom-right-radius: 0;

   &::before,
   &::after {
      content: '';
      height: 2px;
      width: 12px;
      background-color: $text-color-light;
      border-radius: 6px;
      position: absolute;
      left: 49%;
      left: calc(50% - 6px);
   }
   &::before {
      transform: rotate(90deg);
   }
}

.zoom-out-control {
   @extend .google-map__control;
   
   border-top-left-radius: 0;
   border-top-right-radius: 0;

   &::before {
      content: '';
      height: 2px;
      width: 12px;
      background-color: $text-color-light;
      border-radius: 6px;
      position: absolute;
      left: 49%;
      left: calc(50% - 6px);
   }
}

.full-screen-control_enter,
.full-screen-control_exit {
   @extend .google-map__control;
}

.full-screen-control_enter {
   @include icons-change('full-screen-enter');
   margin: 0 10px 10px 10px;
}

.full-screen-control_exit {
   @include icons-change('full-screen-exit');
   margin: 0 10px 10px 10px;
}

.close-full-screen-control {
   @extend .google-map__control;
   @include times-icon;
   display: flex;
   margin: 20px;
}

.street-view-control {
   @extend .google-map__control;
   background-image: url(#{$icons}/street-view.svg);
   margin: 10px 10px 0 10px;

   &_full-screen {
      @extend .street-view-control;
      margin-left: 20px;
   }
}

.drawing-control {
   &-wrapper {
      display: flex;
      flex-direction: row-reverse;
      margin: 10px;
      z-index: 2 !important;

      &_full-screen {
         display: flex;
         margin: 20px 20px 10px 20px;
      }

      &_polygon {
         @extend .drawing-control-wrapper;
         margin-top: 0;
         z-index: 1 !important;
      
         &_full-screen {
            display: flex;
            margin: 0 20px 10px 20px;
         }
      }
   }

   &_circle,
   &_polygon {
      @extend .google-map__control;
      position: relative;

      &:hover .drawing-control__tooltip {
         opacity: 1;
      }
   }

   &_circle {
      @include icons-change('circle-drawing');      
   }

   &_polygon {
      @include icons-change('polygon-drawing');
   }

   &__button-clear_circle,
   &__button-clear_polygon {
      @extend .button-light;
      margin: 0 5px;
   }

   &__tooltip {
      position: absolute;
      z-index: 11;
      top: 100%;
      right: -7px;
      margin-top: 7px;
      padding: 4px 8px;
      width: 45px;
      min-height: 20px;
      border-radius: 1px;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: $main-color;
      background-color: $tooltip-color;
      pointer-events: none;

      opacity: 0;
      transition: 0.5s;

      &::before {
         content: '';
         position: absolute;
         z-index: 11;
         bottom: 100%;
         left: 50%;
         transform: translateX(-50%);
         border-width: 5px 8px;
         border-style: solid;
         border-color: transparent transparent $tooltip-color transparent;
      }
   }
}

.drag-map-control-wrapper {
   display: flex;
   justify-content: space-around;
   align-items: center;

   left: 50% !important;
   transform: translateX(-50%);
   margin-top: 25px;
   padding: 5px;
   height: 28px;
   line-height: 18px;
   background: $main-color;
   box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
   border: 1px solid rgba($text-color, 0.1);
   border-radius: 2px;

   label {
      white-space: nowrap;

      &::before {
         top: 10px;
      }
      &::after {
         top: 8px;
      }
   }
}