@import '../../vars';

.window {
  position: relative;
  height: 800px;
  right: 0;
  height: 100%;
  bottom: 0;
  margin-left: 10px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.08);

  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transform: translateX(-100vw);
    z-index: 9999999999;
  }
  &.running{
    &:before{
      transform: translateX(0);
    }
  }

  &.used {
    z-index: 4;
  }
  &.history {
    background: $main-color;
  }
  &.preview {
    top: 0;
    left: 0;
    margin: 0;
    position: fixed;
    box-shadow: none;
    width: fit-content;
    // One and more modals are open
    &.one-modal {
      @include mq-tablet {
        position: relative;
      }
    }
    &.two-modals {
      @include mq-desktop-md {
        position: relative;
      }
    }
    &.three-modals {
      @include mq-desktop-lg {
        position: relative;
      }
    }
    .enterprise-info-tab {
      height: 100%;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 485px;
    height: 100vh;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -20px;
    margin-right: -20px;
    & > div {
      &:last-child {
        z-index: 1200;
      }
    }
    &-top {
      box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.1);
      flex: 1 1 auto;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 3;
      position: relative;
      border-radius: 0 0 3px 3px;
      overflow: hidden;
    }
    .go-down {
      position: absolute;
      display: flex;
      width: 100%;
      bottom: 295px;
      justify-content: center;
      .icon {
        cursor: pointer;
      }
    }
    .header {
      padding: 12px 20px;
      top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background: #fff;
      &-close {
        cursor: pointer;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: -10px;
        transition: 0.3s;
        &:hover {
          opacity: 0.5;
        }
      }
      &.history {
        padding: 20px;
      }
      &-search {
        cursor: pointer;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: -10px;
        transition: 0.3s;
        &:hover {
          opacity: 0.5;
        }
      }
      &-title {
        cursor: default;
        font-family: $OswaldFont;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-transform: uppercase;
        color: #1F1F1F;
        transition: 0.3s;
      }
    }
    .datalist {
      //padding-bottom: 390px;
      flex: 1 1 auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      border-radius: 0 0 3px 3px;
      background: #fff;
      &-search {
        display: flex;
        align-items: center;
        justify-content: center;
        &.input {
          height: 40px;
        }
        &-area {
          width: 91%;
          min-width: auto;
          max-width: initial;
        }
      }
      &-arrow {
        transform: rotate(90deg);
        position: absolute;
        right: 26px;
        top: -13px;
      }
      &-sort {
        &-options {
          width: fit-content;
        }
        display: flex;
        padding: 0 15px 15px 15px;
        margin-top: -5px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .items-count {
          font-family: $AdobeCleanFont;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          text-align: right;
          color: #8e8e8e;
        }
      }
      &-filters {
        width: 100%;
        padding: 0 20px 12px 20px;
        display: flex;
        height: 40px;
        align-items: center;
        justify-content: center;
        .sort-option {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .activity {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 48%;
          &-value {
            font-family: $OswaldFont;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
          }
          &-icon {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            width: 15px;
            height: 15px;
            border: 1px solid rgba(27, 47, 52, 0.1);
            box-sizing: border-box;
            filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.07));
            border-radius: 1px;
          }
        }
      }
      &-items {
        width: 100%;
        height: 100%;
        flex: 1 auto;
        position: relative;
        display: flex;
        justify-content: center;
        background: #f6f6f6;
        align-items: center;
        .date-added {
          &:first-child {
            margin-top: 10px;
          }
          .date {
            font-family: $AdobeCleanFont;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #434343;
          }
          .today {
            font-weight: bold;
            color: $text-color;
          }

          margin-bottom: 15px;
          text-align: center;
        }
        .items-not-found {
          display: flex;
          justify-content: center;
          padding-top: 50px;
          text-transform: uppercase;

          font-family: Adobe Clean;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: $main-color-gray;
          opacity: 0.5;
        }

        &.items-list {
          margin-right: 5px;
          margin-top: 10px;
        }
      }
      .scroll-to-top-button{
        position: absolute;
        width: 35px;
        height: 35px;
        right: 10px;
        bottom: 10px;
        background: #FFFFFF;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: .3s;
        pointer-events: none;
        cursor: pointer;
        z-index: 9999;
        &.show{
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }
}
