@mixin editor-control-base {
   @include flex-center;
   width: 30px;
   height: 30px;
   margin-left: 2px;
   color: $main-color-black;
   border-radius: 6px;
   cursor: pointer;

   &:hover {
      box-shadow: 1px 1px 0px #bfbdbd;
   }

   &_active {
      background-color: $note-link-color;
   }
}

@mixin input-base {
   margin-top: 10px;
   margin-bottom: 20px;
   padding: 0;
   border-radius: 0;
   border: none;
   border-bottom: 1px solid rgba($main-color-black, 0.1);
   font-size: 16px;
   line-height: 20px;

   &:hover,
   &:focus {
      border-color: $main-color-black;
      transition: $transition-in;
   }
}

@mixin label-base {
   font-family: $OswaldFont;
   font-weight: 500;
   font-size: 12px;
   line-height: 18px;
   text-transform: uppercase;
   color: $label-color;
}