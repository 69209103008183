.data-item {
  // design
  width: 100%;
  background: $main-color;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  margin-bottom: 15px;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid rgba(31, 31, 31, 0.5);
    box-sizing: border-box;
    border: 1px solid rgba(31, 31, 31, 0.5);

    .data-item__image {
      // design
      &.borders {
        // design
        border-right: 1px solid rgba(31, 31, 31, 0.5);
      }
      &.no-borders {
        // design
        border-right: 1px solid rgba(31, 31, 31, 0.5);
      }
    }
  }
  &_active {
    background: $main-color-black;
    border-radius: 3px;
    &:hover {
      background: $main-color-black;
      border: 1px solid $main-color-black;
      box-sizing: border-box;
      border-radius: 3px;
      .data-item__image {
        &.borders {
          // design
          border-right: 1px solid $main-color-black;
        }
        &.no-borders {
          // design
          border-right: 1px solid $main-color-black;
        }
      }
    }
    .data-item__item-summary {
      .type {
        color: rgba($main-color, 0.5);
      }
      .tag {
        color: $main-color;
      }
      .description {
        color: $main-color;
      }
      .title {
        color: $main-color;
      }
      .name {
        color: $main-color;
      }
    }
    .data-item__image {
      &.borders {
        background: rgba(246, 246, 246, 0.4);
        border: 1px solid $main-color-black;
      }
      &.name-initials {
        // design
        background: rgba(246, 246, 246, 0.4);
        border: 1px solid $main-color-black;
      }
    }
  }
  &__image {
    @include flex-center;
    position: relative;
    overflow: hidden;
    width: 68px;
    height: 68px;
    flex-shrink: 0;
    &.borders {
      border-right: 1px solid transparent;
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      border-radius: 3px 0px 0px 3px;
    }
    &.no-borders {
      border-right: 1px solid transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      border-radius: 3px 0px 0px 3px;
    }
    &.name-initials {
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      color: #1f2531;
    }
  }
  &__item-summary {
    @include ellipsis-truncate;
    &.two-items {
      margin: 12px;
    }
    &.three-items {
      margin: 8px;
    }
    .type {
      @include ellipsis-truncate;
      font-family: Adobe Clean;
      margin-top: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: rgba($text-color, 0.5);
    }
    .tag {
      @include ellipsis-truncate;
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: $text-color;
    }
    .description {
      @include ellipsis-truncate;
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: $text-color;
    }
    .title {
      @include ellipsis-truncate;
      margin-bottom: 3px;
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: rgba(31, 31, 31, 0.5);
    }
    .name {
      @include ellipsis-truncate;
      font-family: Adobe Clean;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $text-color;
    }
  }
}
