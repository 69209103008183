.personal-contacts {
  @extend %content-block;
  padding: 20px;
  margin-bottom: 10px;
  width: 100%;
  header {
    display: flex;
    justify-content: space-between;
    height: 24px;
  }

  &__content {
    .photo-container {
      img {
        object-fit: scale-down;
        max-width: 115px;
        max-height: 115px;
      }

      &__initials {
        @include flex-center;
        width: 125px;
        height: 125px;
        font-size: 48px;
        line-height: 60px;
        font-weight: 700;
        color: $main-color-black;
        border: 1px solid #eaeef3;
        letter-spacing: -0.05em;
      }
    }

    .person-name {
      margin: 10px 0;
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      color: $text-color;
      span {
        color: #8e8e8e;
        font-weight: 500;
      }
      a{
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
      }
    }
  }
}
