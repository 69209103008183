@import './vars';
@import './mixins';

@import './rdw';
@import './rdw';
@import './actions-list';
@import './control-list';
@import './tag-creator';
@import './reminder-creator';

.note-creator {
  position: relative;
  flex-shrink: 0;
  z-index: 2;
  bottom: 0;

  padding-left: 10px;
  margin-left: -10px;
  margin-top: -10px;
  padding-top: 10px;
  margin-right: -10px;
  padding-right: 10px;
  transform: translate(0, 0);

  &_inside {
    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 100vh;
    overflow: hidden;
    box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 3px 3px 0 0;
  }
  &_top-part {
    position: relative;
    flex: 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 4;
  }
  &__actions-list,
  &__control-list {
    position: relative;
    left: initial;
    bottom: initial;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &_items {
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &__control-list_items {
    height: 136px;
  }
  &_bottom-part {
    flex-shrink: 0;
    background: transparent;
  }

  .error-block {
    margin-bottom: 5px;
    width: 100%;
    background: #fff9f9;
    border-radius: 3px;
    display: flex;
    align-items: flex-start;
    padding: 10px;
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      font-family: $AdobeCleanFont;
      color: rgba(#f10707, 0.8);
    }
    .errors {
      padding-left: 25px;
      .error {
        list-style-type: disc;
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: rgba(#f10707, 0.8);
      }
    }
  }
  .loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background: $main-color;
    z-index: 20;
    justify-content: center;
    align-items: center;
    .loader {
      width: 24px;
      height: 24px;
    }
  }
  .top-editor {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $main-color;
    &.hide-placeholder {
      .public-DraftEditorPlaceholder-root {
        display: none;
      }
    }
  }
  &__bottom-panel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 8px;
    background: $main-color;
  }

  &__scrollbar {
    flex: 1;
    max-height: calc(100vh - 352px) !important;
    border-bottom: 1px solid rgba(29, 29, 29, 0.15);
    height: 100% !important;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    & > div:first-child {
      height: 100%;
      max-height: 100% !important;
      //padding-top: 50px;
      padding-bottom: 0 !important;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    min-height: 52px;
    padding: 8px;
    margin-bottom: 0;

    border: none;
    position: fixed;
    top: initial;
    left: 10px;
    bottom: 0;
    z-index: 5;
    .rdw-option-wrapper {
      border-radius: 6px;
      border: 1px solid #fff;
      transition: 0.3s;
      &[title='Link'] {
        background-position: center -1px;
      }
      &[title='Image'] {
        background-position: center 0;
      }
    }
    .rdw-option-wrapper:hover {
      border: 1px solid #4265ab;
      box-shadow: none;
    }
    &--buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__input-field {
    resize: none;
    outline: none;
    border: none;
    background-color: $main-color;
    //border-bottom: 1px solid rgba(29, 29, 29, 0.15);

    padding: 10px;
    width: 100%;
    min-height: 40px;
    flex: 1;

    font-size: 14px;
    line-height: 22px;

    & > div {
      height: unset;
    }
    &.error {
      background: $error-background;
    }
  }

  &__control {
    @include editor-control-base;
  }

  &__suggestion-control {
    @include editor-control-base;

    font-size: 18px;
    line-height: 22px;
    color: $blue-color;
    padding-bottom: 3px;

    &_active {
      color: $main-color;
      background-color: $blue-color;
    }
  }

  &__button-add-note {
    padding: 6px 24px !important;
    margin-left: 10px;
    border: 1px solid $button-dark-default-color !important;
    &.update {
      padding: 6px 15px !important;
    }
  }

  &__button-cancel-edit-note {
    background: #ffffff;
    border: 1px solid #dcdedf;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 6px 9px;
    cursor: pointer;
    margin-left: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.3s;
    &:hover {
      border-color: $button-dark-default-color;
      path {
        fill: $button-dark-default-color;
      }
    }
    path {
      fill: rgba(#1f2531, 0.6);
      transition: 0.3s;
    }
  }

  &__date-picker {
    margin: 15px;
    .MuiFormControl-root.filters__date-picker input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
      color: $text-color;
    }
    .datepicker{
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
    &_top {
      background: #f1f4f9;
      padding: 15px;
      border-radius: 3px;
      margin-bottom: 10px;
      .actions-list__item-wrapper {
        padding-bottom: 0;
      }
      .filters__date-picker-container {
        margin-bottom: 15px;
      }
    }
  }
  &__description {
    margin: 10px 15px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .label {
      margin-bottom: 13px;
      font-family: $OswaldFont;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
      color: #627d7a;
    }
    .textarea {
      // reset
      border: none;
      overflow: auto;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      resize: none; /*remove the resize handle on the bottom right*/
      height: 100%;

      &::placeholder {
        color: #dde0e1;
        font-family: $AdobeCleanFont;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

.mention {
  display: inline;
  align-items: center;
  font-family: $AdobeCleanFont;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  height: 20px;
  border-radius: 2px;
  padding: 2px 5px;
  text-decoration: none;
  background: rgba($other-user-color, 0.08);
  color: $other-user-color;
  &:hover {
    background: rgba($other-user-color, 0.2);
  }
  &.selected{
    color: #7342C2;
    background: rgba(#7342C2, 0.15);
  }
}

.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0 1px 8px -3px #ababab;
  background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.headlineButtonWrapper {
  display: inline-block;
}

.headlineButton {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.headlineButton:hover,
.headlineButton:focus {
  background: #f3f3f3;
}

.ColorPicker-MuiFormControl-root,
.color-picker > div {
  width: 100% !important;
}
.color-picker button {
  order: 1;
}
.rdw-editor-main {
  background: transparent;
  overflow: hidden;
}
