@import '../../../../../assets/scss/vars';
@import '../../../../../assets/scss/mixins';

$input-underline-default-color: rgba(27, 47, 52, 0.1);
$input-underline-disabled-color: rgba(193, 193, 193, 0.1);
$input-dark-bottom-line-default-color: rgba($main-color, 0.1);

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::placeholder {
    @content;
  }
}

.container {
  min-width: 280px;
  max-width: 280px;
  position: relative;
  margin-bottom: 20px;

  @include mq-phone {
    max-width: unset;
  }

  @include mq-phone-sm {
    min-width: unset;
  }
}

.container_full {
  @extend .container;
  max-width: 100%;
}

.placeholder {
  font-size: 12px;
}

.label {
  display: block;
  color: $label-color;
  font-family: $OswaldFont;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 12px;
  text-transform: uppercase;
}

.label_dark {
  @extend .label;
  color: $text-color-light !important;
  letter-spacing: 0.04em;
}

.input-base,
.input {
  width: 100%;
  font-family: $AdobeCleanFont;
  font-size: 16px;
  line-height: 15px;
  padding: 0 0 2px 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: $text-color;
  border-bottom: 1px solid $input-underline-default-color;

  /* remove rounded borders on iOS */
  border-radius: 0;
  -webkit-appearance: none;

  &:hover,
  &:focus {
    border-color: $input-active-color;
    transition: $transition-in;

    @include placeholder {
      color: $input-active-color;
      transition: $transition-in;
    }
  }

  &:disabled {
    border-color: $input-underline-disabled-color;
    cursor: default;

    @include placeholder {
      color: $input-disabled-color;
    }
  }

  &:read-only {
    border: none;
  }

  &_error {
    border-color: $error-color;
  }
  &_wrong {
    color: $error-color;
  }

  @include placeholder {
    font-size: 12px;
    line-height: 20px;
    color: $input-default-color;
  }
}

.input_dark {
  @extend .input;

  color: $main-color;
  border-color: $input-dark-bottom-line-default-color;

  @include placeholder {
    color: rgba($input-default-color, 0.5);
  }

  &:hover,
  &:focus {
    border-color: $main-color;
    transition: $transition-in;

    @include placeholder {
      color: $input-default-color;
      transition: $transition-in;
    }
  }
}

.input_phone {
  display: flex;
  align-items: flex-end;

  /* remove rounded borders on iOS */
  border-radius: 0;
  -webkit-appearance: none;

  & > div:first-child {
    margin-right: 5px;
  }

  & > div:last-child {
    // width: 100%;
    position: relative;
    top: 1px;
  }
}

.input__search_icon {
  position: absolute;
  cursor: pointer;
  right: 0;
  bottom: 2px;
  width: 25px;
  background-color: #f1f4f9;
  text-align: center;
}

.input__search_icon_close {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input__search_icon_transparent {
  @extend .input__search_icon;
  background-color: #f1f4f900;
}

.input__search_icon_dark {
  @extend .input__search_icon;
  background-color: $main-color-black;
}

.units {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.unit {
  display: block;
  margin-top: -20px;
  width: fit-content;
  height: 20px;
  padding-left: 5px;
  padding-right: 3px;
  float: right;
  bottom: 1px;
  text-transform: lowercase;
  font-size: 14px;
  color: $text-color-gray;
  background-color: $main-color;
  @include clearfix;
}

.unit_dark {
  @extend .unit;

  color: $main-color;
  background-color: $main-color-black;
}

.required {
  color: $error-color;
  font-weight: 700;
  margin-left: 5px;
}

.info {
  margin-left: 5px;
  cursor: pointer;
}

.select {
  font-family: $AdobeCleanFont;
  font-size: 16px;
  transition: $transition-out;

  &:hover {
    /* control bottom line */
    & > div:first-child {
      box-shadow: 0 1px 0 0 $option-bg-color;
      transition: $transition-in;
    }
    .placeholder {
      color: $input-active-color;
      transition: $transition-in;
    }
  }
}

.select_search {
  @extend .input-base;
  margin-top: 0;
  cursor: pointer;

  & + .dropdown_indicator {
    border-bottom: 1px solid $input-underline-default-color;
  }

  &:hover,
  &:focus {
    & + .dropdown_indicator {
      border-color: $input-active-color;
      transition: $transition-in;
    }
  }

  &:disabled {
    & + .dropdown_indicator {
      border-color: $input-underline-disabled-color;
      svg path {
        fill: $input-disabled-color;
      }
    }
  }
}

.menu {
  width: 100%;
  position: absolute;
  z-index: 5;
  background-color: $main-color;
  box-shadow: 0 4px 10px rgba(33, 33, 33, 0.13);
}

.creation {
  color: $text-color-light;
  background: $option-bg-color;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  padding: 10px 0;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  cursor: pointer;

  span {
    color: $text-color-light;
  }

  &:hover {
    svg path {
      fill: $main-color;
    }
    svg circle {
      fill: #132226;
    }
  }

  &:active {
    background-color: $text-color;
  }

  &_disabled {
    background-color: $disabled-color;

    svg path {
      fill: $disabled-color;
    }
  }

  &_label {
    margin-left: 10px;
  }

  &_icon {
    margin-right: 10px;
  }
}

.selection {
  color: $text-color-light;
  background: #1f2531;
  font-size: 16px;
  padding: 1px 6px;
  line-height: 20px;
  border-radius: 1px;

  span {
    color: $text-color-light;

    &:first-child {
      margin-left: 5px;
    }
    &:last-child {
      @include times-icon(10px, 1px);
      padding-right: 6px;
      cursor: pointer;
      justify-content: flex-start;
    }
  }

  &_readonly {
    span:last-child {
      &::before,
      &::after {
        display: none;
      }
    }
  }
}

.selection_single {
  @extend .selection;

  span {
    @include ellipsis-truncate;

    &:not(:first-child) {
      padding-left: 10px;
      padding-right: 12px;
    }
  }

  width: 100%;
  display: flex;
  justify-content: space-between;

  &::after {
    content: '';
    position: absolute;
    top: 55px;
    width: 100%;
    height: 1px;
    background-color: $input-underline-default-color;
  }
}

.selection_multi {
  @extend .selection;

  display: inline-flex;
  margin: 0 5px 5px 0;

  &.disabled{
    opacity: .7;
    pointer-events: none;
  }

  span {
    &:first-child {
      @include ellipsis-truncate;
      max-width: 253px;
      text-transform: capitalize;
      margin-right: 10px;
      line-height: 20px;
    }

    &:last-child {
      padding-right: 11px;
    }
  }

  svg path {
    fill: $text-color-light;
  }
}

.selection_single_dark {
  @extend .selection_single;

  color: $main-color-black;
  background-color: $main-color;

  span {
    color: $main-color-black;

    &:last-child {
      @include times-icon(10px, 1px, $text-color);
    }
  }
}

.selection_multi_dark {
  @extend .selection_multi;

  color: $main-color-black;
  background-color: $main-color;

  span {
    color: $main-color-black;

    &:last-child {
      @include times-icon(10px, 1px, $text-color);
    }
  }

  svg path {
    fill: $main-color-black;
  }
}

.remove_selection_icon {
  // @include flex-center;
  // width: 22px;
  // height: 22px;

  cursor: pointer;
}

.error {
  font-size: 10px;
  line-height: 13px;
  color: $error-color;
  position: absolute;
  left: 0;
  bottom: -15px;
}

.multi_errors_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 3px;
}

.single_error {
  position: absolute;
  right: 0;
}

.multi_error {
  @extend .error;
  position: relative;
  bottom: 0;
}

.scrollthumby {
  width: 2px !important;
  bottom: 0;
  left: 4px;
  background: $disabled-color;
  border-radius: 1px;
  z-index: 1;
}
