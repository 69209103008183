.data-item__tabs {
  display: flex;
  flex-shrink: 0;
  padding: 20px 15px 0 15px;
  margin-right: 20px;
  // margin-bottom: 12px;
  background: $main-color;
  box-shadow: 0 4px $box-shadow-stretch rgba(0, 0, 0, 0.1);
  border-radius: 0 0 3px 3px;
  z-index: 3;
  &.disable-swiping {
    max-width: 1004px;
  }
  .tabs-menu__backlink {
    display: none;
    position: absolute;
    top: 10px;
    left: 15px;
    cursor: pointer;
  }
  .swiper{
    width: 100%;
  }

  @include mq-tablet {
    padding-top: 39px;
    margin-right: 0;

    .tabs-menu__backlink {
      display: block;
    }
  }

  @include mq-phone {
    &_third-screen {
      padding-top: 33px;
    }
  }

  .tabs-swipe-forwards {
    position: fixed;
    z-index: 1;
    right: -2px;
    top: 0;

    width: 38px;
    height: 65px;
    background: linear-gradient(
      265.55deg,
      $main-color 3.93%,
      rgba($main-color, 0.68) 96.79%
    );
    border-radius: 0 0 3px 0;
    display: none;
    cursor: pointer;

    @include mq-phone {
      display: block;
    }

    svg {
      position: absolute;
      bottom: 10px;
      right: 13px;
    }
  }

  .tabs-swipe-backwards {
    @extend .tabs-swipe-forwards;
    left: 0;
    top: 24px;
    height: 38px;
    background: rgba($main-color, 0.94);
    cursor: pointer;

    // fill gap under button to hide underline
    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $main-color;
      position: absolute;
      bottom: -3px;
    }

    svg {
      transform: rotate(180deg);
      left: 11px;
    }
  }

  .tab {
    display: inline-flex;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-right: 35px;
    padding-bottom: 7px;

    &:last-child {
      padding-right: 0;
    }

    font-family: $OswaldFont;
    text-decoration: none;
    color: rgba($text-color, 0.3);

    &:hover {
      color: $text-color;
      transition: $transition-in;
    }

    &__link {
      font-family: $OswaldFont;
      text-decoration: none;
      color: rgba($text-color, 0.3);
      line-height: 18px;
      cursor: pointer;

      &:hover {
        color: $text-color;
        transition: $transition-in;
      }

      .tab__underline {
        display: none;
        width: 100%;
        width: calc(100% + 10px);
        height: 1px;
        background-color: $text-color;
        position: relative;
        bottom: -7px !important;
        left: -5px;
      }
    }

    &__link_active {
      .tab__link {
        @extend .tab__link;
        color: $text-color;
      }

      .tab__underline {
        display: block;
      }
    }
  }

  .swiper-container {
    margin-left: unset;
    margin-right: unset;
    overflow: unset; // make underline appear
  }

  .slide {
    flex-shrink: 0;
  }
}

.tabs__content {
  position: relative;
  height: 100%;
}
