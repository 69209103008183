@import "./tabs/info";
@import "./tabs/involvement-history";

$box-shadow-stretch-right: $box-shadow-stretch;

.people-page {
   @extend .page-layout;

   .info-tab {
      & > div {
         width: 100%;
      }
   }

   @media screen and (max-width: 1200px) {
      .info-tab {
         flex-direction: column;
      }
      .personal-contacts {
         max-width: unset;
         margin-right: 0px;
      }
   }

   @include mq-tablet {
      .info-tab {
         flex-direction: row;
      }
      .personal-contacts {
         margin-right: 10px;
      }
      .current-involvement {
         margin-right: 0 !important;
      }
   }

   @include mq-phone {
      overflow-y: hidden !important;
      .info-tab {
         margin-bottom: 80px;
      }
   }
}